import axios from 'axios';
import { equals, isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CustomPortfolioAllocationMethods } from '../../advisory/services/constants';
import { useCustomPortfolioStore } from '../../advisory/services/customPortfolioStore';
import { patchSessionGoal } from '../../shared/api';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import {
  Goal,
  GoalData,
  useGoalsStore
} from '../../shared/services/goalsStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export function useUpdateGoal() {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const { adviceSessionId } = useParams<any>();

  const updateGoal = async (goalId: string, payload: Partial<Goal>) => {
    const goalsStore = useGoalsStore.getState();

    try {
      goalsStore.setIsGoalPending(true);
      const accessToken = await getQAuthAccessToken(auth0AccessToken);

      const oldGoal = goalsStore.goals.find(g => g.goalId === goalId);
      if (!oldGoal) return;

      const customPortfolioPayload = createCustomPortfolioPayload(
        oldGoal,
        payload
      );

      const payloadToSend: Partial<Goal> = {
        ...payload,
        data: {
          ...oldGoal.data,
          ...payload?.data,
          ...customPortfolioPayload
        }
      };

      const newGoalData: Goal = {
        ...oldGoal,
        ...payloadToSend
      };

      if (
        payload?.data?.productPlatformNamespace &&
        oldGoal.data.productPlatformNamespace !==
          payload.data.productPlatformNamespace
      ) {
        useCustomPortfolioStore.getState().setAssetClasses([]);
        useCustomPortfolioStore
          .getState()
          .setAssetClassInstrumentsStatus(PageStatuses.ready);
      }

      goalsStore.editGoal(goalId, newGoalData);

      await patchSessionGoal(
        accessToken,
        adviceSessionId,
        goalId,
        payloadToSend
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.adviceSession.purposeAndRisk.updateGoalErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      goalsStore.setIsGoalPending(false);
    }
  };

  return updateGoal;
}

const createCustomPortfolioPayload = (
  oldGoal: Goal,
  payload: Partial<Goal>
) => {
  let customPortfolioPayload: Partial<GoalData> = {};

  if (oldGoal.data.isPortfolioCustom === true) {
    if (
      payload?.data?.customPortfolio &&
      oldGoal.data.customPortfolio?.length &&
      isNil(payload.data.isCustomPortfolioDirty) &&
      !equals(payload.data.customPortfolio, oldGoal.data.customPortfolio)
    ) {
      customPortfolioPayload = {
        ...customPortfolioPayload,
        isCustomPortfolioDirty: true
      };
    }

    if (payload?.data?.isPortfolioCustom === false) {
      customPortfolioPayload = {
        ...customPortfolioPayload,
        isCustomPortfolioDirty: false,
        shouldDisplayAlertInAssetAllocation: false,
        shouldDisplayAlertInFundAllocation: false,
        riskTemplate: null,
        customPortfolio: null
      };
    }

    // If the user changes one of the savings plan amount to zero,
    // we need to reset the custom portfolio allocation method to initial value
    if (
      ('firstDeposit' in (payload?.data || {}) &&
        !payload.data?.firstDeposit &&
        oldGoal.data.customPortfolioAllocationMethod ===
          CustomPortfolioAllocationMethods.firstDeposit) ||
      ('monthlyDeposit' in (payload?.data || {}) &&
        !payload.data?.monthlyDeposit &&
        oldGoal.data.customPortfolioAllocationMethod ===
          CustomPortfolioAllocationMethods.monthlyDeposit)
    ) {
      customPortfolioPayload = {
        ...customPortfolioPayload,
        customPortfolioAllocationMethod:
          CustomPortfolioAllocationMethods.percentage
      };
    }
  }

  if (
    payload?.data?.riskScore &&
    oldGoal.data.riskScore !== payload.data.riskScore &&
    oldGoal.data.customPortfolio?.length
  ) {
    customPortfolioPayload = {
      ...customPortfolioPayload,
      shouldRefreshCustomPortfolio: true
    };
  }

  return customPortfolioPayload;
};
