import chroma from 'chroma-js';
import classNames from 'classnames';
import * as R from 'ramda';
import React from 'react';

import LoadingIndicator from '../loadingIndicator/loadingIndicator';
import { TableContext } from './context.js';
import HeaderCell from './headerCell.js';
import Pagination from './pagination.js';
import Checkbox from 'features/shared/components/checkbox/index';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import {
  useTheme,
  createUseStyles
} from 'features/sharedModules/styles/components/styles';

const indexedMap = R.addIndex(R.map);

const useStyles = createUseStyles(theme => ({
  container: {
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 10,
    backgroundColor: chroma(theme.pageBackgroundColor).alpha(0.7).css()
  },
  loadingIndicator: {
    position: 'absolute',
    top: '64px',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 11
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse'
  },
  headerRowCell: {
    fontWeight: FontWeights.normal,
    color: theme.secondaryColor,
    textAlign: 'left',
    padding: '1.6rem 1.3rem 1.6rem 1.3rem',
    backgroundColor: theme.itemBackgroundColor_1,
    wordBreak: 'break-all',

    '&:first-child': {
      paddingLeft: '2rem'
    },
    '&:last-child': {
      paddingRight: '2rem'
    }
  },
  headerRowComponentCell: {
    fontWeight: FontWeights.normal,
    textAlign: 'left',
    padding: '0.8rem 1.3rem 0.8rem 1.3rem',
    backgroundColor: theme.itemBackgroundColor_1,

    '&:first-child': {
      paddingLeft: '2rem'
    },
    '&:last-child': {
      paddingRight: '2rem'
    }
  },
  sortingIcon: {
    marginLeft: '0.5rem',
    fontSize: '2rem',
    lineHeigh: '2.4rem'
  },
  headerRowCellContent: {
    display: 'inline-flex',
    alignItems: 'center',
    userSelect: 'none',

    '&$sortable': {
      cursor: 'pointer'
    }
  },
  sortable: {},
  bodyRow: {
    '&$even $bodyRowCell': {
      backgroundColor: theme.itemBackgroundColor_2
    },
    '&$odd $bodyRowCell': {
      backgroundColor: theme.itemBackgroundColor_1
    },
    '&$cssParity:nth-of-type(even) $bodyRowCell': {
      backgroundColor: theme.itemBackgroundColor_1
    },
    '&$cssParity:nth-of-type(odd) $bodyRowCell': {
      backgroundColor: theme.itemBackgroundColor_2
    },

    '&$clickable': {
      cursor: 'pointer'
    },

    '&$hoverEffect:hover $bodyRowCell': {
      backgroundColor: theme.hoverInputStrokeColor
    },

    '&$noData': {
      color: theme.secondaryColor
    }
  },
  noData: {},
  clickable: {},
  even: {},
  odd: {},
  cssParity: {},
  hoverEffect: {},
  bodyRowCell: {
    padding: Spacing.spacing01
  },
  columnTitle: {
    wordBreak: 'break-word'
  }
}));

const Table = ({
  children,
  isSelectionShown = false,
  isPaginationShown = true,
  orderKey,
  orderDirection,
  selectedRowIds,
  allRowIds,
  header,
  extraHeader,
  hideHeader,
  noDataBodyRowCellClassName,
  isLoading = false,
  pageNumber,
  pageSize,
  totalItemsNumber,
  changeOrder,
  changePageNumber,
  changePageSize,
  changeRowSelection,
  changeAllRowsSelection,
  forwardedTableRef,
  forwardedTableContainerRef,
  externalClasses,
  toolbar,
  paginationProps = {},
  headerRowRef,
  style,
  ...restProps
} = {}) => {
  const theme = useTheme();
  const internalClasses = useStyles();
  const classes = React.useMemo(
    () =>
      R.isNil(externalClasses)
        ? internalClasses
        : R.mergeWith(
            (c1, c2) => classNames(c1, c2),
            internalClasses,
            externalClasses
          ),
    [internalClasses, externalClasses]
  );

  selectedRowIds = R.isNil(selectedRowIds) ? [] : selectedRowIds;

  const Toolbar = toolbar;

  const tableContextValue = React.useMemo(
    () => ({ classes, noDataBodyRowCellClassName, isSelectionShown }),
    [classes]
  );

  return (
    <TableContext.Provider value={tableContextValue}>
      <div data-testid={restProps['data-testid']}>
        {Toolbar && <Toolbar selectedRowIds={selectedRowIds} />}
        <div className={classes.container} ref={forwardedTableContainerRef}>
          {isLoading && <div className={classes.overlay} />}
          {isLoading && (
            <LoadingIndicator
              color={theme.accentColor}
              containerClassName={classes.loadingIndicator}
            />
          )}
          <table
            style={style}
            className={classes.table}
            ref={forwardedTableRef}
          >
            {!hideHeader && (
              <thead>
                {!R.isNil(extraHeader) && (
                  <tr>
                    {isSelectionShown && (
                      <th className={classes.headerRowCell} />
                    )}
                    {indexedMap(
                      (cell, index) => (
                        <HeaderCell
                          key={index}
                          cell={cell}
                          orderKey={orderKey}
                          orderDirection={orderDirection}
                          changeOrder={changeOrder}
                          classes={classes}
                        />
                      ),
                      extraHeader
                    )}
                  </tr>
                )}
                <tr ref={headerRowRef}>
                  {isSelectionShown && R.isEmpty(allRowIds) && (
                    <th className={classes.headerRowCell}></th>
                  )}
                  {isSelectionShown && !R.isEmpty(allRowIds) && (
                    <th className={classes.headerRowCell}>
                      <Checkbox
                        checked={R.isEmpty(
                          R.without(selectedRowIds, allRowIds)
                        )}
                        onChange={e => {
                          changeAllRowsSelection(allRowIds, e.target.checked);
                        }}
                      />
                    </th>
                  )}
                  {indexedMap(
                    (cell, index) => (
                      <HeaderCell
                        key={index}
                        cell={cell}
                        orderKey={orderKey}
                        orderDirection={orderDirection}
                        changeOrder={changeOrder}
                        classes={classes}
                      />
                    ),
                    header
                  )}
                </tr>
              </thead>
            )}
            {children}
          </table>
        </div>
        {isPaginationShown && (
          <Pagination
            pageSize={pageSize}
            pageNumber={pageNumber}
            totalItemsNumber={totalItemsNumber}
            changePageNumber={changePageNumber}
            changePageSize={changePageSize}
            {...paginationProps}
          />
        )}
      </div>
    </TableContext.Provider>
  );
};

export default Table;
