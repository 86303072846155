import axios, { CancelTokenSource } from 'axios';
import { equals, isEmpty, isNil } from 'ramda';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageStore as useAdvisoryPageStore } from '../../../advisory/services/pageStore';
import { excludeNilFields } from '../../../proposal/utils';
import { CustomPortfolioAllocationMethods } from '../../services/constants';
import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { useHandleCustomPortfolio } from './useHandleCustomPortfolio';
import { useReadAssetClassInstruments } from './useReadAssetClassInstruments';
import { useReadFundManagementFees } from './useReadFundManagementFees';
import { useReadSustainabilityData } from './useReadSustainabilityData';
import { usePageStore as useProposalPageStore } from 'features/roboAdvice/adviceSession/proposal/services/pageStore';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import {
  Category,
  useSessionStore
} from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import {
  getCategoriesAll,
  getRoboPortfolio
} from 'features/roboAdvice/adviceSession/shared/api';
import useReadCustomAttributesData from 'features/roboAdvice/adviceSession/shared/components/useReadCustomAttributesData';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  getSubAssetClassTranslation,
  groupAssetClasses
} from 'features/roboAdvice/adviceSession/shared/mapping';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { areProductAttributesEnabled } from 'features/roboAdvice/adviceSession/shared/services/selectors';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { filterNil } from 'features/shared/utils/filters';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

type UseInitCustomPortfolio = {
  selectedGoal: Goal;
};

export const useInitCustomPortfolio = ({
  selectedGoal
}: UseInitCustomPortfolio) => {
  const i18n = useI18n();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const customPortfolioStore = useCustomPortfolioStore();
  const {
    advisoryComponents: {
      customPortfolioStartWithModel,
      customPortfolioUseCustomAssetClassTable,
      customPortfolioFundSelectionAutoGenerated,
      customPortfolioAllocatingInAmountEnabled,
      customPortfolio: { fundAllocation }
    },
    roboAdvice: {
      sustainability: { enabled: isSustainabilityEnabled },
      subAssetClassNameMapping = {}
    },
    roboPortfolioPrecision
  } = useCustomerConfig();
  const updateGoal = useUpdateGoal();
  const { addCustomAssetClasses, handleChangeRiskTemplate } =
    useHandleCustomPortfolio();
  const readAssetClassInstruments = useReadAssetClassInstruments();
  const assetClassInstrumentsStatus = useCustomPortfolioStore(
    state => state.assetClassInstrumentsStatus
  );
  const readFundManagementFees = useReadFundManagementFees();
  const readSustainabilityData = useReadSustainabilityData();
  const readCustomAttributesData = useReadCustomAttributesData();

  const sessionStoreState = useSessionStore.getState();
  const proposalPageStore = useProposalPageStore.getState();

  const getInitAssetClassesForCustomPortfolio = async (riskScore?: string) => {
    try {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const suggestedRoboPortfolio = await getRoboPortfolio(
        accessToken,
        cancelTokenSource.token,
        excludeNilFields({
          risk_tolerance: riskScore ?? selectedGoal.data?.riskScore,
          optionals: selectedGoal.data?.themes,
          precision: roboPortfolioPrecision,
          namespace_id: selectedGoal.data.productPlatformNamespace
        })
      );

      const categoriesSelection =
        selectedGoal && selectedGoal.goalId
          ? useAdvisoryPageStore.getState().goalCategoriesSelection![
              selectedGoal.goalId
            ]
          : useAdvisoryPageStore.getState()?.categoriesSelection;

      const groupedSuggestedRoboPortfolio = groupAssetClasses(
        suggestedRoboPortfolio.data.Portfolio
      );

      const assetClasses = groupedSuggestedRoboPortfolio
        .map(({ AssetClass, CategoryIds, Weight }) => {
          const foundAssetClass = customPortfolioStore.assetClasses.find(
            ({ categoryIds }) => categoryIds.includes(AssetClass.CategoryId)
          );

          const availableInstruments =
            foundAssetClass?.availableInstruments ?? [];

          const assetClassCategoriesSelection = Object.entries(
            categoriesSelection
          )
            .filter(([categoryId]) =>
              CategoryIds.find(c => c.id === categoryId)
            )
            .map(([categoryId, instrumentId]) => ({
              categoryId,
              instrumentId,
              weight: CategoryIds.find(c => c.id === categoryId)?.weight ?? 0
            }));

          const instruments = assetClassCategoriesSelection
            .map(categorySelection => {
              const foundInstrument = availableInstruments.find(
                availableInstrument =>
                  availableInstrument.id === categorySelection.instrumentId
              );

              if (!foundInstrument) return null;

              return {
                ...foundInstrument,
                weight: categorySelection.weight
              };
            })
            .filter(filterNil);

          return {
            CategoryId: foundAssetClass?.categoryId ?? AssetClass.CategoryId,
            CategoryIds:
              (foundAssetClass?.categoryIds ?? []).map(
                catId =>
                  CategoryIds.find(c => c.id === catId) ?? {
                    id: catId,
                    weight: 0
                  }
              ) ?? CategoryIds,
            mainAssetClass: AssetClass.MainAssetClass,
            subAssetClass: AssetClass.SubAssetClass,
            id: AssetClass.SubAssetClass,
            title: AssetClass.SubAssetClass,
            instruments: customPortfolioFundSelectionAutoGenerated
              ? instruments
              : [],
            weight: customPortfolioStartWithModel ? Weight : undefined
          };
        })
        .filter(({ weight }) => weight);

      return assetClasses;
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.advisory.customPortfolio.initializeCustomPortfolioError'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }

    return [];
  };

  useEffect(() => {
    useCustomPortfolioStore.getState().setAssetClasses([]);
    useCustomPortfolioStore
      .getState()
      .setAssetClassInstrumentsStatus(PageStatuses.ready);

    const fetchCategories = async () => {
      try {
        if (!isNil(cancelTokenSourceRef.current)) {
          cancelTokenSourceRef.current.cancel();
        }
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSourceRef.current = cancelTokenSource;

        const accessToken = await getQAuthAccessToken(
          auth0AccessToken,
          cancelTokenSource.token
        );
        const allCategoriesResponse = await getCategoriesAll(
          accessToken,
          cancelTokenSource.token,
          {
            namespace_id: selectedGoal?.data.productPlatformNamespace
          }
        );
        const allCategories: Category[] =
          allCategoriesResponse.data?.Categories || [];

        const groupedCategories = allCategories
          .filter(category => category?.VisibleCustomPortfolio !== false)
          .reduce((prev, curr) => {
            const foundCategory = prev.find(
              category => category.SubAssetClass === curr.SubAssetClass
            );

            if (foundCategory) {
              return prev;
            }

            return [...prev, curr];
          }, [] as Category[])
          .map(category => ({
            ...category,
            SubAssetClass:
              getSubAssetClassTranslation(
                category.CategoryId,
                subAssetClassNameMapping
              ) || category.SubAssetClass
          }));

        customPortfolioStore.setAvailableCategories(
          groupedCategories.sort((a, b) =>
            a.CategoryId.localeCompare(b.CategoryId)
          )
        );
      } catch (error) {
        if (!axios.isCancel(error)) {
          throwSafeError(error);
        }
      }
    };

    fetchCategories();
  }, [selectedGoal?.data.productPlatformNamespace]);

  useEffect(() => {
    const initCustomPortfolioWithModelPortfolioAssets = async () => {
      const isPortfolioCustom = selectedGoal?.data?.isPortfolioCustom;
      const customPortfolioIsEmpty =
        (isNil(selectedGoal?.data?.customPortfolio) ||
          isEmpty(selectedGoal?.data?.customPortfolio)) &&
        !isEmpty(customPortfolioStore.assetClasses);

      if (
        isPortfolioCustom &&
        customPortfolioIsEmpty &&
        customPortfolioStartWithModel === true &&
        customPortfolioStore.isCustomPortfolioLoading === false &&
        assetClassInstrumentsStatus === PageStatuses.succeed
      ) {
        customPortfolioStore.setIsCustomPortfolioLoading(true);

        const assetClasses = await getInitAssetClassesForCustomPortfolio();

        await addCustomAssetClasses({
          goal: selectedGoal,
          assetClasses,
          additionalGoalData: {
            oldRiskScore: selectedGoal.data.riskScore,
            isCustomPortfolioDirty: false,
            shouldRefreshCustomPortfolio: false,
            shouldDisplayAlertInAssetAllocation: false,
            shouldDisplayAlertInFundAllocation: false
          }
        });

        proposalPageStore.setPageStatus(
          'readPortfolioChartData',
          PageStatuses.ready
        );

        customPortfolioStore.setIsCustomPortfolioLoading(false);
      }
    };

    initCustomPortfolioWithModelPortfolioAssets();
  }, [
    customPortfolioStartWithModel,
    selectedGoal,
    customPortfolioStore.assetClasses,
    customPortfolioStore.isCustomPortfolioLoading,
    assetClassInstrumentsStatus
  ]);

  useEffect(() => {
    const refreshCustomPortfolioWhenRiskScoreChanged = async () => {
      if (
        selectedGoal?.data?.shouldRefreshCustomPortfolio &&
        selectedGoal?.data?.isPortfolioCustom &&
        !isEmpty(customPortfolioStore.assetClasses) &&
        !isNil(selectedGoal?.data?.customPortfolio) &&
        useAdvisoryPageStore.getState().pageStatuses
          ?.readRefreshCustomPortfolio !== PageStatuses.pending
      ) {
        useAdvisoryPageStore
          .getState()
          .setPageStatus('readRefreshCustomPortfolio', PageStatuses.pending);
        if (customPortfolioUseCustomAssetClassTable === false) {
          await handleChangeRiskTemplate({
            goalId: selectedGoal.goalId,
            selectedRiskTemplate: selectedGoal.data.riskScore || null,
            additionalGoalData: {
              shouldRefreshCustomPortfolio: false,
              shouldDisplayAlertInAssetAllocation: true,
              shouldDisplayAlertInFundAllocation: true
            }
          });
        } else if (customPortfolioStartWithModel === true) {
          const oldCustomPortfolioData =
            selectedGoal.data?.customPortfolio || [];
          const oldCleanCustomPortfolioData = selectedGoal.data?.oldRiskScore
            ? await getInitAssetClassesForCustomPortfolio(
                selectedGoal.data.oldRiskScore
              )
            : [];
          const isCustomPortfolioDirty = !equals(
            oldCustomPortfolioData,
            oldCleanCustomPortfolioData
          );

          await addCustomAssetClasses({
            goal: selectedGoal,
            assetClasses: await getInitAssetClassesForCustomPortfolio(),
            additionalGoalData: {
              shouldRefreshCustomPortfolio: false,
              shouldDisplayAlertInAssetAllocation: isCustomPortfolioDirty,
              shouldDisplayAlertInFundAllocation: isCustomPortfolioDirty,
              oldRiskScore: selectedGoal.data.riskScore,
              isCustomPortfolioDirty: false
            },
            overrideAllAssets: true
          });
        } else if (customPortfolioStartWithModel === false) {
          const oldCustomPortfolioData =
            selectedGoal.data?.customPortfolio || [];
          const touchedOldAssetClassesOrAdded = oldCustomPortfolioData.filter(
            oldCustomPortfolioAssetClass =>
              oldCustomPortfolioAssetClass.weight ||
              oldCustomPortfolioAssetClass.instruments.some(
                instrument => instrument.weight
              )
          );

          const newCustomPortfolioData =
            await getInitAssetClassesForCustomPortfolio();
          const newAssetClasses = newCustomPortfolioData.filter(
            newCustomPortfolioAssetClass => {
              const foundTouchedOldCleanAssetClass =
                touchedOldAssetClassesOrAdded.find(
                  ({ CategoryId: touchedOldCategoryId }) =>
                    touchedOldCategoryId ===
                    newCustomPortfolioAssetClass.CategoryId
                );

              return !foundTouchedOldCleanAssetClass;
            }
          );

          await addCustomAssetClasses({
            goal: selectedGoal,
            assetClasses: [
              ...touchedOldAssetClassesOrAdded,
              ...newAssetClasses
            ],
            additionalGoalData: {
              shouldRefreshCustomPortfolio: false,
              shouldDisplayAlertInAssetAllocation: true,
              shouldDisplayAlertInFundAllocation: true
            },
            overrideAllAssets: true
          });
        }

        useAdvisoryPageStore
          .getState()
          .setPageStatus('readRefreshCustomPortfolio', PageStatuses.succeed);
      }
    };

    refreshCustomPortfolioWhenRiskScoreChanged();
  }, [customPortfolioStore.assetClasses, selectedGoal]);

  useEffect(() => {
    const initCustomPortfolioDropdown = async () => {
      if (
        !customPortfolioUseCustomAssetClassTable &&
        isNil(selectedGoal?.data?.riskTemplate) &&
        !isNil(selectedGoal.data?.riskScore)
      ) {
        await handleChangeRiskTemplate({
          goalId: selectedGoal.goalId,
          selectedRiskTemplate: selectedGoal.data.riskScore
        });
      }
    };

    initCustomPortfolioDropdown();
  }, [
    selectedGoal.data.riskScore,
    selectedGoal.data?.riskTemplate,
    selectedGoal.goalId
  ]);

  useEffect(() => {
    readFundManagementFees(selectedGoal);
  }, [
    selectedGoal.data.firstDeposit,
    selectedGoal.data.monthlyDeposit,
    selectedGoal.data.productPlatformNamespace
  ]);

  const categoriesSelection =
    useAdvisoryPageStore.getState()?.goalCategoriesSelection?.[
      selectedGoal.goalId
    ];

  useEffect(() => {
    if (
      assetClassInstrumentsStatus === PageStatuses.ready &&
      categoriesSelection
    ) {
      readAssetClassInstruments(selectedGoal);
    }
  }, [selectedGoal, assetClassInstrumentsStatus, categoriesSelection]);

  useEffect(() => {
    if (isSustainabilityEnabled) {
      readSustainabilityData({ selectedGoal });
    }
  }, [selectedGoal, isSustainabilityEnabled]);

  useEffect(() => {
    const namespaceId =
      selectedGoal.data.productPlatformNamespace ||
      sessionStoreState.defaultConfigNamespaceId;

    if (namespaceId && areProductAttributesEnabled(fundAllocation)) {
      readCustomAttributesData(namespaceId);
    }
  }, [
    selectedGoal.data.productPlatformNamespace,
    fundAllocation,
    readCustomAttributesData,
    sessionStoreState.defaultConfigNamespaceId
  ]);

  useEffect(() => {
    if (
      selectedGoal?.data?.isPortfolioCustom &&
      customPortfolioStore.assetClasses.length > 0 &&
      selectedGoal.data.customPortfolio &&
      selectedGoal.data.customPortfolio.length > 0
    ) {
      const allInstrumentsInCustomPortfolio = (
        selectedGoal.data.customPortfolio || []
      )
        .map(({ instruments }) => instruments)
        .flat();

      const filteredAssetClassesInstruments = customPortfolioStore.assetClasses
        .map(({ availableInstruments }) => availableInstruments)
        .flat()
        .filter(instrument =>
          allInstrumentsInCustomPortfolio.find(({ id }) => id === instrument.id)
        );

      const isLanguageMismatchInCustomPortfolioInstruments =
        allInstrumentsInCustomPortfolio.length > 0 &&
        filteredAssetClassesInstruments.length > 0 &&
        filteredAssetClassesInstruments.some(instrument => {
          const found = allInstrumentsInCustomPortfolio.find(
            ({ id }) => id === instrument.id
          );

          return found && found.title !== instrument.title;
        });

      if (isLanguageMismatchInCustomPortfolioInstruments) {
        const updatedCustomPortfolio = selectedGoal.data.customPortfolio.map(
          ({ instruments, ...rest }) => ({
            ...rest,
            instruments: instruments.map(instrument => {
              const found = filteredAssetClassesInstruments.find(
                ({ id }) => id === instrument.id
              );

              return {
                ...instrument,
                title: found?.title ?? instrument.title
              };
            })
          })
        );

        updateGoal(selectedGoal.goalId, {
          data: {
            customPortfolio: updatedCustomPortfolio
          }
        });
      }
    }
  }, [
    customPortfolioStore.assetClasses,
    selectedGoal.data.customPortfolio,
    selectedGoal.data?.isPortfolioCustom
  ]);

  useEffect(() => {
    if (
      !customPortfolioAllocatingInAmountEnabled &&
      selectedGoal.data.customPortfolioAllocationMethod !==
        CustomPortfolioAllocationMethods.percentage
    ) {
      updateGoal(selectedGoal.goalId, {
        data: {
          customPortfolioAllocationMethod:
            CustomPortfolioAllocationMethods.percentage
        }
      });
    }

    if (
      customPortfolioAllocatingInAmountEnabled &&
      !selectedGoal.data.customPortfolioAllocationMethod
    ) {
      updateGoal(selectedGoal.goalId, {
        data: {
          customPortfolioAllocationMethod:
            CustomPortfolioAllocationMethods.percentage
        }
      });
    }
  }, [selectedGoal]);
};
