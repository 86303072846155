import { useEffect, useState } from 'react';

import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { usePageStore as useAdvisoryPageStore } from '../../services/pageStore';
import ChangeAllocationMethodDropdown from './changeAllocationMethodDropdown';
import FundAllocationBaseTable from './fundAllocationBaseTable';
import ReselectConfirmation from './reselectConfirmation';
import { useFundAllocationBaseData } from './useFundAllocationBaseData';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import { CustomPortfolioTabsNames } from 'features/roboAdvice/adviceSession/shared/constants';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { FundAllocationTabs } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import Button, { ButtonType } from 'features/shared/components/button';
import Tooltip from 'features/shared/components/tooltip';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { AdvisoryErrors } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  rootButtonsContainer: {
    position: 'relative',
    display: 'flex',
    alignContent: 'center'
  },
  leftButtonsContainer: {
    display: 'flex'
  },
  rightButtonsContainer: {
    marginLeft: 'auto'
  },
  button: {
    backgroundColor: theme.accentFocusColor,
    fontSize: Typography.body1.size,
    fontWeight: FontWeights.medium,
    letterSpacing: '0'
  }
}));

type Props = {
  selectedGoal: Goal;
  fundAllocationTab: keyof typeof FundAllocationTabs;
  isSelected?: boolean;
  errors?: AdvisoryErrors;
};

const FundAllocationBase = ({
  selectedGoal,
  isSelected,
  fundAllocationTab,
  errors
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const [isReselectModalOpen, setIsReselectModalOpen] = useState(false);
  const {
    advisoryComponents: {
      customPortfolio: {
        fundAllocation: {
          advisorNotes: {
            enabled: isAdvisorNotesEnabled,
            required: isAdvisorNotesRequired
          }
        }
      },
      customPortfolioAllocatingInAmountEnabled
    }
  } = useCustomerConfig();
  const updateGoal = useUpdateGoal();
  const { assetClasses } = useCustomPortfolioStore();
  const {
    fundAllocationRows,
    additionalTableHeaders,
    additionalFirstRows,
    assetEdit,
    setAssetEdit
  } = useFundAllocationBaseData({ selectedGoal, fundAllocationTab });

  useEffect(() => {
    setAssetEdit(
      (selectedGoal.data.customPortfolio ?? []).reduce(
        (prev, { CategoryId }) => ({ ...prev, [CategoryId]: false }),
        {}
      )
    );
  }, [selectedGoal.goalId]);

  const reselectFunds = () => {
    const categoriesSelection =
      selectedGoal && selectedGoal.goalId
        ? useAdvisoryPageStore.getState().goalCategoriesSelection![
            selectedGoal.goalId
          ]
        : useAdvisoryPageStore.getState()?.categoriesSelection;

    const newCustomPortfolioData = (
      selectedGoal.data.customPortfolio || []
    ).map(asset => {
      const availableInstruments =
        assetClasses.find(({ categoryIds }) =>
          categoryIds.includes(asset.CategoryId)
        )?.availableInstruments ?? [];

      const categoryInstrumentWeight =
        asset.CategoryIds.length > 0
          ? Math.floor(((asset.weight || 0) / asset.CategoryIds.length) * 10) /
            10
          : 0;
      const lastCategoryInstrumentWeight =
        asset.CategoryIds.length > 0 && asset.weight
          ? asset.weight -
            (asset.CategoryIds.length - 1) * categoryInstrumentWeight
          : 0;

      const categoryInstruments = asset.CategoryIds.map(
        ({ id: CatId }, index) => {
          const foundAssetClass = assetClasses.find(({ categoryIds }) =>
            categoryIds.includes(CatId)
          );
          const availableInstruments =
            foundAssetClass?.availableInstruments ?? [];

          return availableInstruments
            .filter(({ id }) => id === categoriesSelection[CatId])
            .map(i => ({
              ...i,
              // Solution for uneven division like 65/3 = 21.666...
              // We round weight to one decimal place and the last one has a little bit more, but then the sum is correct.
              weight:
                index !== asset.CategoryIds.length - 1
                  ? categoryInstrumentWeight
                  : lastCategoryInstrumentWeight
            }));
        }
      ).flat();

      const newInstruments =
        categoryInstruments.length === 0 && availableInstruments.length > 0
          ? [
              {
                ...availableInstruments.sort((a, b) =>
                  a.title.localeCompare(b.title)
                )?.[0],
                weight: asset.weight
              }
            ]
          : categoryInstruments;

      return {
        ...asset,
        instruments: newInstruments
      };
    });

    updateGoal(selectedGoal.goalId, {
      data: { customPortfolio: newCustomPortfolioData }
    });
  };

  return (
    <>
      <div className={classes.rootButtonsContainer}>
        {customPortfolioAllocatingInAmountEnabled && (
          <div className={classes.leftButtonsContainer}>
            <ChangeAllocationMethodDropdown selectedGoal={selectedGoal} />
          </div>
        )}
        <div className={classes.rightButtonsContainer}>
          <Tooltip
            content={i18n(
              'roboAdvice.advisory.customPortfolio.reselectTooltip'
            )}
            infoTooltip
            trigger={
              <Button
                onClick={() => setIsReselectModalOpen(true)}
                className={classes.button}
                buttonType={ButtonType.additional}
              >
                {i18n('roboAdvice.advisory.customPortfolio.reselect')}
              </Button>
            }
          />
        </div>
      </div>

      <FundAllocationBaseTable
        selectedGoal={selectedGoal}
        assetEdit={assetEdit}
        additionalTableHeaders={additionalTableHeaders}
        additionalFirstRows={additionalFirstRows}
        setAssetEdit={setAssetEdit}
        fundAllocationRowsData={fundAllocationRows}
      />

      {isAdvisorNotesEnabled && (
        <AdvisorNotes
          header={i18n(
            'advisory.customPortfolio.fundAllocation.advisorNotes.header'
          )}
          customPlaceholder={i18n(
            'advisory.customPortfolio.fundAllocation.advisorNotes.placeholder'
          )}
          infoTooltipText={i18n(
            'advisory.customPortfolio.fundAllocation.advisorNotes.infoTooltip'
          )}
          id="custom-portfolio-fund-allocation-advisor-notes"
          name={`customPortfolio.${CustomPortfolioTabsNames.fundAllocation}.advisorNotes.${selectedGoal.goalId}`}
          required={isAdvisorNotesRequired}
          errors={errors?.fundAllocationAdvisorNotes?.filter(
            error => error.data?.advisoryGoal === selectedGoal.goalId
          )}
        />
      )}

      <ReselectConfirmation
        isOpen={isReselectModalOpen}
        setOpen={setIsReselectModalOpen}
        onConfirm={() => {
          reselectFunds();
          setIsReselectModalOpen(false);
        }}
      />
    </>
  );
};

export default FundAllocationBase;
