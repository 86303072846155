import {
  dataSourcesMapping,
  PORTFOLIO_MODES
} from '@quantfoliorepo/ui-components';
import axios from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageStore as useAdvisoryPageStore } from '../../../advisory/services/pageStore';
import { PortfolioType, usePortfolioChartStore } from '../../../portfolioChart';
import {
  getRoboPortfolio,
  getRoboSelection,
  createRiskReturn,
  createAnalyzeAssetClassAllocation
} from '../../../shared/api';
import { useGoalsStore } from '../../../shared/services/goalsStore';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { excludeNilFields } from '../../utils';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { getSubAssetClassTranslation } from 'features/roboAdvice/adviceSession/shared/mapping';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { roundNumber } from 'features/shared/utils/number';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export function useReadPortfolioChartData() {
  const i18n = useI18n();
  const theme = useTheme();
  const portfolioChartColors = theme.chartPortfolioColors;
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const {
    roboPortfolioPrecision,
    tenantSettings: { fundNameForPresentation },
    roboAdvice: { subAssetClassNameMapping = {} }
  } = useCustomerConfig();

  const cancelTokenSourceRef = React.useRef<any>();
  const readPortfolioChartData = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const { goals } = useGoalsStore.getState();
    const proposalPageStore = useProposalPageStore.getState();
    const portfolioChartStore = usePortfolioChartStore.getState();

    try {
      proposalPageStore.setPageStatus(
        'readPortfolioChartData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      portfolioChartStore.resetChartData();

      for (const goal of goals) {
        let modelPortfolio: Record<string, unknown> | null = null;
        const assetClassesSum = goal.data.customPortfolio
          ? roundNumber(
              goal.data.customPortfolio.reduce(
                (prev, { weight }) => prev + (weight || 0),
                0
              ),
              3
            )
          : 0;

        const instrumentsSum = goal.data.customPortfolio
          ? roundNumber(
              goal.data.customPortfolio.reduce(
                (prev, { instruments }) =>
                  prev +
                  instruments.reduce(
                    (iPrev, { weight }) => iPrev + (weight || 0),
                    0
                  ),
                0
              ),
              3
            )
          : 0;

        if (
          goal.data.isPortfolioCustom &&
          goal.data.customPortfolio &&
          assetClassesSum === 100 &&
          instrumentsSum === 100
        ) {
          const portfolio = dataSourcesMapping
            .getPortfolioFromAssetClasses(goal.data.customPortfolio)
            .filter(({ weight }) => weight)
            .reduce((acc, asset) => {
              const foundAssetIndex = acc.findIndex(a => a.id === asset.id);
              if (foundAssetIndex !== -1) {
                return acc.map(({ id, weight }) => ({
                  id,
                  weight: id === asset.id ? weight + asset.weight! : weight
                }));
              }

              return [...acc, { id: asset.id, weight: asset.weight ?? 0 }];
            }, [] as { id: string; weight: number }[]);

          const assetClassAllocationResponse =
            await createAnalyzeAssetClassAllocation(
              qAuthAccessToken,
              cancelTokenSource.token,
              {
                portfolio
              },
              {
                namespace_id: goal.data.productPlatformNamespace,
                language: getUserPageLanguage()
              }
            );

          const createRiskReturnResponse = await createRiskReturn(
            qAuthAccessToken,
            cancelTokenSource.token,
            {
              portfolio
            },
            {
              namespace_id: goal.data.productPlatformNamespace
            }
          );

          const assetClassAllocation = {
            ...assetClassAllocationResponse?.data,
            portfolioAllocation:
              assetClassAllocationResponse?.data.portfolioAllocation.map(
                allocation => ({
                  ...allocation,
                  name:
                    getSubAssetClassTranslation(
                      allocation.category,
                      subAssetClassNameMapping
                    ) || allocation.name
                })
              )
          };

          portfolioChartStore.addAssetClassAllocationPortfolioChartData({
            dataSources: {
              analyzeRiskReturn: createRiskReturnResponse?.data,
              assetClassAllocation
            },
            portfolioChartColors,
            fundNameForPresentation,
            goalId: goal.goalId,
            goalName: goal.name,
            goalIcon: goal.icon,
            portfolioType: PortfolioType.custom,
            i18n
          });
        } else if (!goal.data.isPortfolioCustom && goal.data?.portfolio) {
          modelPortfolio = await readStandardPortfolio({
            qAuthAccessToken,
            cancelTokenSource,
            risk_tolerance: goal.data?.portfolio,
            optionals: goal.data?.themes,
            precision: roboPortfolioPrecision,
            namespace_id: goal.data.productPlatformNamespace,
            portfolioChartColors,
            fundNameForPresentation,
            goalId: goal.goalId,
            goalName: goal.name,
            goalIcon: goal.icon,
            subAssetClassNameMapping,
            portfolioType: PortfolioType.model
          });
          portfolioChartStore.addAssetClassAllocationPortfolioChartData({
            ...modelPortfolio,
            i18n
          });
        }

        if (goal.data?.riskScore === goal.data.portfolio && modelPortfolio) {
          portfolioChartStore.addAssetClassAllocationPortfolioChartData({
            ...modelPortfolio,
            portfolioType: PortfolioType.suggested,
            i18n
          });
        } else {
          const suggestedPortfolio = await readStandardPortfolio({
            qAuthAccessToken,
            cancelTokenSource,
            risk_tolerance: goal.data?.riskScore,
            optionals: goal.data?.themes,
            precision: roboPortfolioPrecision,
            namespace_id: goal.data.productPlatformNamespace,
            portfolioChartColors,
            fundNameForPresentation,
            goalId: goal.goalId,
            goalName: goal.name,
            goalIcon: goal.icon,
            subAssetClassNameMapping,
            portfolioType: PortfolioType.suggested
          });

          portfolioChartStore.addAssetClassAllocationPortfolioChartData({
            ...suggestedPortfolio,
            i18n
          });
        }
      }

      proposalPageStore.setPageStatus(
        'readPortfolioChartData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        portfolioChartStore.resetChartData();
        proposalPageStore.setPageStatus(
          'readPortfolioChartData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readPortfolioChartData;
}

export const readStandardPortfolio = async ({
  qAuthAccessToken,
  cancelTokenSource,
  risk_tolerance,
  optionals,
  precision,
  namespace_id,
  portfolioChartColors,
  fundNameForPresentation,
  goalId,
  goalName,
  goalIcon,
  subAssetClassNameMapping,
  portfolioType
}) => {
  const [getRoboPortfolioResponse, getRoboSelectionResponse] =
    await Promise.all([
      getRoboPortfolio(
        qAuthAccessToken,
        cancelTokenSource.token,
        excludeNilFields({
          risk_tolerance,
          optionals,
          precision,
          namespace_id
        })
      ),
      getRoboSelection(qAuthAccessToken, cancelTokenSource.token, {
        namespace_id
      })
    ]);

  const roboPortfolio = getRoboPortfolioResponse?.data;
  const roboSelection = getRoboSelectionResponse?.data;

  if (
    portfolioType === PortfolioType.suggested &&
    roboPortfolio?.Portfolio?.length
  ) {
    useAdvisoryPageStore
      .getState()
      .setSuggestedPortfolio(goalId, roboPortfolio.Portfolio);
  }

  const portfolio = dataSourcesMapping.getPortfolio(PORTFOLIO_MODES.standard, {
    dataSources: {
      roboPortfolio,
      roboSelection
    }
  });

  const assetClassAllocationResponse = await createAnalyzeAssetClassAllocation(
    qAuthAccessToken,
    cancelTokenSource.token,
    {
      portfolio
    },
    {
      namespace_id,
      language: getUserPageLanguage()
    }
  );

  const createRiskReturnResponse = await createRiskReturn(
    qAuthAccessToken,
    cancelTokenSource.token,
    {
      portfolio
    },
    {
      namespace_id
    }
  );

  const assetClassAllocation = {
    ...assetClassAllocationResponse?.data,
    portfolioAllocation:
      assetClassAllocationResponse?.data.portfolioAllocation.map(
        allocation => ({
          ...allocation,
          name:
            getSubAssetClassTranslation(
              allocation.category,
              subAssetClassNameMapping
            ) || allocation.name
        })
      )
  };

  return {
    dataSources: {
      analyzeRiskReturn: createRiskReturnResponse?.data,
      assetClassAllocation
    },
    portfolioChartColors,
    fundNameForPresentation,
    goalId,
    goalName,
    goalIcon,
    portfolioType
  };
};
