import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { isNil, lensPath, lensProp, set } from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePageStore as useProposalPageStore } from '../../../proposal/services/pageStore';
import { CustomPortfolioAllocationMethods } from '../../services/constants';
import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { usePageStore as useAdvisoryPageStore } from '../../services/pageStore';
import { CustomPortfolioAllocationMethod } from '../../types';
import ChangeAllocationIcon, {
  ChangeAllocationIconType
} from './changeAllocationIcon';
import { CUSTOM_PORTFOLIO_PRECISION } from './constants';
import { useHandleCustomPortfolio } from './useHandleCustomPortfolio';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { getSubAssetClassTranslation } from 'features/roboAdvice/adviceSession/shared/mapping';
import {
  Goal,
  Instrument
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import NumberInput from 'features/shared/components/numberInput/numberInput';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import TableRowMenu from 'features/shared/components/table/tableRowMenu.js';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  areNumbersEqualWithPrecisionError,
  formatNumber,
  getNumberInputFormat,
  roundNumber
} from 'features/shared/utils/number';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableComponent: any = Table;
const AnyTableBody: any = TableBody;
const AnyTableBodyRow: any = TableBodyRow;
const AnyTableRowMenu: any = TableRowMenu;

const useStyles = createUseStyles(theme => ({
  root: {
    margin: `${Spacing.spacing01}px 0 ${Spacing.spacing04}px 0`,
    textAlign: 'left'
  },
  textCell: {
    textAlign: 'left'
  },
  numberCell: {
    textAlign: 'right'
  },
  cell: {
    verticalAlign: 'top'
  },
  actionsCell: {
    verticalAlign: 'middle',
    lineHeight: '1',
    textAlign: 'right',
    width: '80px'
  },
  weightInput: {
    textAlign: 'right',
    width: '150px'
  },
  numberNotCorrect: {
    color: theme.errorNegativeColor
  },
  totalRow: {
    backgroundColor: `${theme.inputFillFocusColor} !important`,
    fontWeight: FontWeights.medium
  },
  cellHeight: {
    height: 59,
    padding: '0 1.3rem'
  },
  customPortfolioTotal: {
    paddingRight: '3.7rem' // 1.3rem + 2.4rem
  },
  customPortfolioAllocationMethodHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.spacing00,
    justifyContent: 'flex-end',
    color: theme.secondaryColor
  },
  cellWithError: {
    paddingTop: Spacing.spacing00,
    paddingBottom: Spacing.spacing00
  }
}));

type Props = {
  selectedGoal: Goal;
};

const AssetClassAllocationTable = ({ selectedGoal }: Props) => {
  const i18n = useI18n();
  const classes = useStyles();
  const tableRef = useRef<HTMLTableElement>();
  const tableContainerRef = useRef<HTMLDivElement>();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const customPortfolioStore = useCustomPortfolioStore();
  const defaultConfirmationStoreState =
    defaultConfirmation.useConfirmationStore.getState();
  const {
    advisoryComponents: {
      customPortfolioModelPortfolioComparison,
      customPortfolioFundSelectionAutoGenerated,
      customPortfolioAllowAssetClassModification,
      customPortfolioAllocatingInAmountEnabled
    },
    roboAdvice: { subAssetClassNameMapping = {} }
  } = useCustomerConfig();
  const [customAssets, setCustomAssets] = useState<
    Record<string, Record<string, number>>
  >({});
  const updateGoal = useUpdateGoal();
  const { removeCustomAssetClasses } = useHandleCustomPortfolio();
  const proposalPageStore = useProposalPageStore.getState();
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const suggestedPortfolios = useAdvisoryPageStore(
    state => state.suggestedPortfolios
  );

  useEffect(() => {
    if (customPortfolioStore.isNewCustomPortfolioDataAvailable) {
      setCustomAssets(state =>
        set(
          lensProp(selectedGoal?.goalId),
          selectedGoal?.data?.customPortfolio?.reduce(
            (prev, curr) => ({
              ...prev,
              [curr.CategoryId]: curr.weight
            }),
            {}
          ) || {},
          state
        )
      );

      customPortfolioStore.setIsNewCustomPortfolioDataAvailable(false);
    }
  }, [customPortfolioStore.isNewCustomPortfolioDataAvailable]);

  const debouncedUpdateGoalCustomAssetValue = useCallback(
    debounce(
      (goalId: string, newValues: Record<string, Record<string, number>>) => {
        updateGoal(goalId, {
          data: {
            customPortfolio: Object.entries(newValues[goalId]).map(
              ([CategoryId, assetClassWeight]) => {
                const foundAsset = selectedGoal?.data?.customPortfolio?.find(
                  c => c.CategoryId === CategoryId
                );

                if (assetClassWeight === 0) {
                  return {
                    ...foundAsset!,
                    weight: 0,
                    instruments: []
                  };
                }

                let finalInstruments: Instrument[] =
                  foundAsset?.instruments || [];

                if (customPortfolioFundSelectionAutoGenerated) {
                  let currentInstruments =
                    foundAsset?.instruments && foundAsset.instruments.length > 0
                      ? foundAsset.instruments
                      : [];

                  if (currentInstruments.length === 0) {
                    const availableInstruments =
                      customPortfolioStore.assetClasses.find(
                        ({ categoryIds }) => categoryIds.includes(CategoryId)
                      )?.availableInstruments ?? [];

                    const categoriesSelection =
                      selectedGoal && selectedGoal.goalId
                        ? useAdvisoryPageStore.getState()
                            .goalCategoriesSelection![selectedGoal.goalId]
                        : useAdvisoryPageStore.getState()?.categoriesSelection;

                    currentInstruments = availableInstruments.filter(
                      ({ id }) => id === categoriesSelection[CategoryId]
                    );
                  }

                  const categoryInstrumentWeight =
                    currentInstruments.length > 0
                      ? Math.floor(
                          (assetClassWeight / currentInstruments.length) * 10
                        ) / 10
                      : 0;
                  const lastCategoryInstrumentWeight =
                    currentInstruments.length > 0 && assetClassWeight
                      ? Math.round(
                          (assetClassWeight -
                            (currentInstruments.length - 1) *
                              categoryInstrumentWeight) *
                            100
                        ) / 100
                      : 0;

                  finalInstruments = currentInstruments.map(
                    (instrument, index) => {
                      return {
                        ...instrument,
                        // Solution for uneven division like 65/3 = 21.666...
                        // We round weight to one decimal place and the last one has a little bit more, but then the sum is correct.
                        weight:
                          index === currentInstruments.length - 1
                            ? lastCategoryInstrumentWeight
                            : categoryInstrumentWeight
                      };
                    }
                  );
                }

                return {
                  ...foundAsset!,
                  weight: assetClassWeight,
                  instruments: finalInstruments
                };
              }
            )
          }
        });
      },
      300
    ),
    [selectedGoal?.data?.customPortfolio]
  );

  const handleChangeCustomAssetValue = useCallback(
    (
      value: number,
      goalId: string,
      CategoryId: string,
      allocationMethod: CustomPortfolioAllocationMethod
    ) => {
      let valueAdjustedToMethod = value ?? 0;

      if (
        value &&
        allocationMethod === CustomPortfolioAllocationMethods.monthlyDeposit &&
        selectedGoal.data.monthlyDeposit
      ) {
        valueAdjustedToMethod =
          (value * 100) / selectedGoal.data.monthlyDeposit;
      }

      if (
        value &&
        allocationMethod === CustomPortfolioAllocationMethods.firstDeposit &&
        selectedGoal.data.firstDeposit
      ) {
        valueAdjustedToMethod = (value * 100) / selectedGoal.data.firstDeposit;
      }

      if (
        value &&
        valueAdjustedToMethod !==
          roundNumber(valueAdjustedToMethod, CUSTOM_PORTFOLIO_PRECISION) &&
        areNumbersEqualWithPrecisionError(
          valueAdjustedToMethod,
          roundNumber(valueAdjustedToMethod, CUSTOM_PORTFOLIO_PRECISION)
        )
      ) {
        // If the value has float precision problem we round it to the precision we use in the custom portfolio
        valueAdjustedToMethod = roundNumber(
          valueAdjustedToMethod,
          CUSTOM_PORTFOLIO_PRECISION
        );
      }

      setCustomAssets(state => {
        const newValues = set(
          lensPath([goalId, CategoryId]),
          valueAdjustedToMethod,
          state
        );
        debouncedUpdateGoalCustomAssetValue(goalId, newValues);
        return newValues;
      });
    },
    [debouncedUpdateGoalCustomAssetValue]
  );

  const categories = customPortfolioStore.assetClasses
    .filter(allAssetClass =>
      (selectedGoal.data.customPortfolio || []).find(
        goalAssetClass => allAssetClass.id === goalAssetClass.id
      )
    )
    .map(customAssetClass => {
      const modelPortfolioWeight = suggestedPortfolios[selectedGoal.goalId]
        ?.filter(
          ({ AssetClass }) =>
            (getSubAssetClassTranslation(
              AssetClass.CategoryId,
              subAssetClassNameMapping
            ) || AssetClass.SubAssetClass) === customAssetClass.subAssetClass
        )
        ?.reduce((acc, curr) => acc + curr.Weight, 0);

      return {
        ...customAssetClass,
        modelPortfolioWeight,
        weight:
          customAssets?.[selectedGoal.goalId]?.[customAssetClass.categoryId]
      };
    })
    .sort((a, b) => a.categoryId.localeCompare(b.categoryId));

  useEffect(() => {
    // Determine if categories config have changed and if so, correct the custom portfolio
    if (categories.length && selectedGoal?.data?.customPortfolio) {
      let portfolioChanged = false;
      const correctedPortfolio = selectedGoal?.data?.customPortfolio?.map(
        customPortfolioItem => {
          if (
            !categories.find(
              c => c.categoryId === customPortfolioItem.CategoryId
            )
          ) {
            const matchingCategory = categories.find(
              c => c.subAssetClass === customPortfolioItem.subAssetClass
            );
            if (matchingCategory) {
              portfolioChanged = true;
              const newCategoryIds = matchingCategory.categoryIds.map(id => {
                return {
                  id,
                  weight:
                    customPortfolioItem.CategoryIds.find(c => c.id === id)
                      ?.weight || 0
                };
              });
              return {
                ...customPortfolioItem,
                CategoryId: matchingCategory.categoryId,
                CategoryIds: newCategoryIds
              };
            }
          }
          return customPortfolioItem;
        }
      );

      if (portfolioChanged) {
        updateGoal(selectedGoal.goalId, {
          data: {
            customPortfolio: correctedPortfolio
          }
        });
      }
    }
  }, [categories, selectedGoal?.data?.customPortfolio]);

  const totalSum = roundNumber(
    categories.reduce((prev, curr) => prev + (curr.weight || 0), 0),
    CUSTOM_PORTFOLIO_PRECISION
  );

  const otherAssetClassModelPortfolioWeight =
    100 -
    categories.reduce((prev, curr) => {
      if (Number.isFinite(curr.modelPortfolioWeight)) {
        return prev + curr.modelPortfolioWeight;
      }

      return prev;
    }, 0);

  return (
    <AnyTableComponent
      header={[
        {
          title: i18n('shared.assetClass')
        },
        {
          component: (
            <div className={classes.customPortfolioAllocationMethodHeader}>
              {i18n('roboAdvice.advisory.portfolio.modelPortfolio')}
              {customPortfolioAllocatingInAmountEnabled && (
                <ChangeAllocationIcon
                  type={
                    selectedGoal.data.customPortfolioAllocationMethod ===
                    CustomPortfolioAllocationMethods.percentage
                      ? ChangeAllocationIconType.percentage
                      : ChangeAllocationIconType.amount
                  }
                />
              )}
            </div>
          ),
          className: classes.numberCell,
          disabled: !customPortfolioModelPortfolioComparison
        },
        {
          component: (
            <div className={classes.customPortfolioAllocationMethodHeader}>
              {i18n('roboAdvice.advisory.portfolio.customPortfolio')}
              {customPortfolioAllocatingInAmountEnabled && (
                <ChangeAllocationIcon
                  type={
                    selectedGoal.data.customPortfolioAllocationMethod ===
                    CustomPortfolioAllocationMethods.percentage
                      ? ChangeAllocationIconType.percentage
                      : ChangeAllocationIconType.amount
                  }
                />
              )}
            </div>
          ),
          className: classes.numberCell
        },
        {
          title: '',
          disabled: !customPortfolioAllowAssetClassModification
        }
      ].filter(({ disabled }) => !disabled)}
      externalClasses={{
        container: classes.root,
        headerRowCell: classes.cellHeight
      }}
      forwardedTableRef={tableRef}
      forwardedTableContainerRef={tableContainerRef}
      isLoading={
        customPortfolioStore.isRiskTemplateLoading ||
        customPortfolioStore.assetClassInstrumentsStatus ===
          PageStatuses.pending ||
        proposalPageStore.pageStatuses['readPortfolioChartData'] ===
          PageStatuses.pending
      }
    >
      <AnyTableBody
        noDataTitle={i18n(
          'roboAdvice.advisory.customPortfolio.emptyAssetTablePlaceholder'
        )}
        cellsNumber={3 + +customPortfolioModelPortfolioComparison}
      >
        {categories.map(
          ({ categoryId, subAssetClass, modelPortfolioWeight }) => {
            const createMenuItems = ({ toggleMenu }) => {
              if (openMenuId && openMenuId !== categoryId) {
                toggleMenu(false);
              }
              return [
                {
                  title: i18n('shared.delete'),
                  iconType: '',
                  onClick: e => {
                    e.preventDefault();

                    defaultConfirmationStoreState.open(
                      i18n(
                        'roboAdvice.advisory.customPortfolio.deleteAssetClassConfirmationMessage'
                      ),
                      () => {
                        removeCustomAssetClasses({
                          goal: selectedGoal,
                          assetClassIdsToRemove: [categoryId]
                        });
                        toggleMenu(false);
                      },
                      () => toggleMenu(false),
                      i18n(
                        'roboAdvice.advisory.customPortfolio.deleteAssetClassConfirmationTitle'
                      )
                    );
                  }
                }
              ];
            };

            const customPortfolioAmountPercentage =
              customAssets?.[selectedGoal.goalId]?.[categoryId];

            const customPortfolioAmountMonthlyDeposit =
              selectedGoal.data.monthlyDeposit &&
              !isNil(customPortfolioAmountPercentage)
                ? selectedGoal.data.monthlyDeposit *
                  (customPortfolioAmountPercentage / 100)
                : undefined;

            const customPortfolioAmountFirstDeposit =
              selectedGoal.data.firstDeposit &&
              !isNil(customPortfolioAmountPercentage)
                ? selectedGoal.data.firstDeposit *
                  (customPortfolioAmountPercentage / 100)
                : undefined;

            const isInvalidStep =
              !isNil(customPortfolioAmountPercentage) &&
              roundNumber(
                customPortfolioAmountPercentage,
                CUSTOM_PORTFOLIO_PRECISION
              ) !== customPortfolioAmountPercentage;

            const monthlyDepositStep =
              (selectedGoal.data?.monthlyDeposit ?? 0) /
              (10 ** CUSTOM_PORTFOLIO_PRECISION * 100);

            const firstDepositStep =
              (selectedGoal.data?.firstDeposit ?? 0) /
              (10 ** CUSTOM_PORTFOLIO_PRECISION * 100);

            return (
              <AnyTableBodyRow key={categoryId}>
                {({ bodyRowCellClassName }) => (
                  <>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.textCell,
                        classes.cellHeight
                      )}
                    >
                      {subAssetClass}
                    </td>

                    {customPortfolioModelPortfolioComparison && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell,
                          classes.cellHeight
                        )}
                      >
                        {(!customPortfolioAllocatingInAmountEnabled ||
                          selectedGoal.data.customPortfolioAllocationMethod ===
                            CustomPortfolioAllocationMethods.percentage) &&
                          (modelPortfolioWeight
                            ? `${formatNumber(
                                cultureCode,
                                modelPortfolioWeight,
                                1,
                                3
                              )}%`
                            : '')}

                        {customPortfolioAllocatingInAmountEnabled &&
                        selectedGoal.data.monthlyDeposit &&
                        selectedGoal.data.customPortfolioAllocationMethod ===
                          CustomPortfolioAllocationMethods.monthlyDeposit &&
                        modelPortfolioWeight
                          ? formatNumber(
                              cultureCode,
                              (modelPortfolioWeight / 100) *
                                selectedGoal.data.monthlyDeposit,
                              0,
                              2
                            )
                          : ''}

                        {customPortfolioAllocatingInAmountEnabled &&
                        selectedGoal.data.firstDeposit &&
                        selectedGoal.data.customPortfolioAllocationMethod ===
                          CustomPortfolioAllocationMethods.firstDeposit &&
                        modelPortfolioWeight
                          ? formatNumber(
                              cultureCode,
                              (modelPortfolioWeight / 100) *
                                selectedGoal.data.firstDeposit,
                              0,
                              2
                            )
                          : ''}
                      </td>
                    )}

                    {(!customPortfolioAllocatingInAmountEnabled ||
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.percentage) && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.numberCell,
                          classes.cellHeight,
                          {
                            [classes.cellWithError]: isInvalidStep
                          }
                        )}
                      >
                        <NumberInput
                          value={customPortfolioAmountPercentage}
                          onChange={val =>
                            handleChangeCustomAssetValue(
                              val,
                              selectedGoal.goalId,
                              categoryId,
                              CustomPortfolioAllocationMethods.percentage
                            )
                          }
                          suffix={'%'}
                          thousandSeparator={thousandSeparator}
                          decimalSeparator={decimalSeparator}
                          allowNegative={false}
                          decimalScale={CUSTOM_PORTFOLIO_PRECISION}
                          className={classes.weightInput}
                          isAllowed={({ floatValue }) =>
                            floatValue === undefined ||
                            (floatValue >= 0 && floatValue <= 100)
                          }
                          errorText={
                            isInvalidStep
                              ? i18n(
                                  'roboAdvice.advisory.customPortfolio.changeAllocationMethod.errorInvalidIncrementalStepInPercentage'
                                ).replace(
                                  '{0}',
                                  `${1 / 10 ** CUSTOM_PORTFOLIO_PRECISION}%`
                                )
                              : undefined
                          }
                          touched
                        />
                      </td>
                    )}

                    {customPortfolioAllocatingInAmountEnabled && (
                      <>
                        {selectedGoal.data.monthlyDeposit &&
                          selectedGoal.data.customPortfolioAllocationMethod ===
                            CustomPortfolioAllocationMethods.monthlyDeposit && (
                            <td
                              className={classNames(
                                bodyRowCellClassName,
                                classes.numberCell,
                                classes.cellHeight,
                                {
                                  [classes.cellWithError]: isInvalidStep
                                }
                              )}
                            >
                              <NumberInput
                                value={customPortfolioAmountMonthlyDeposit}
                                onChange={val =>
                                  handleChangeCustomAssetValue(
                                    val,
                                    selectedGoal.goalId,
                                    categoryId,
                                    CustomPortfolioAllocationMethods.monthlyDeposit
                                  )
                                }
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                allowNegative={false}
                                decimalScale={2}
                                className={classes.weightInput}
                                isAllowed={({ floatValue }) =>
                                  floatValue === undefined ||
                                  (floatValue >= 0 &&
                                    floatValue <=
                                      (selectedGoal.data.monthlyDeposit || 0))
                                }
                                errorText={
                                  isInvalidStep
                                    ? i18n(
                                        'roboAdvice.advisory.customPortfolio.changeAllocationMethod.errorInvalidIncrementalStep'
                                      ).replace('{0}', `${monthlyDepositStep}`)
                                    : undefined
                                }
                                touched
                              />
                            </td>
                          )}

                        {selectedGoal.data.firstDeposit &&
                          selectedGoal.data.customPortfolioAllocationMethod ===
                            CustomPortfolioAllocationMethods.firstDeposit && (
                            <td
                              className={classNames(
                                bodyRowCellClassName,
                                classes.numberCell,
                                classes.cellHeight,
                                {
                                  [classes.cellWithError]: isInvalidStep
                                }
                              )}
                            >
                              <NumberInput
                                value={customPortfolioAmountFirstDeposit}
                                onChange={val =>
                                  handleChangeCustomAssetValue(
                                    val,
                                    selectedGoal.goalId,
                                    categoryId,
                                    CustomPortfolioAllocationMethods.firstDeposit
                                  )
                                }
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={decimalSeparator}
                                allowNegative={false}
                                decimalScale={2}
                                className={classes.weightInput}
                                isAllowed={({ floatValue, value }) =>
                                  floatValue === undefined ||
                                  (floatValue >= 0 &&
                                    floatValue <=
                                      (selectedGoal.data.firstDeposit || 0))
                                }
                                errorText={
                                  isInvalidStep
                                    ? i18n(
                                        'roboAdvice.advisory.customPortfolio.changeAllocationMethod.errorInvalidIncrementalStep'
                                      ).replace('{0}', `${firstDepositStep}`)
                                    : undefined
                                }
                                touched
                              />
                            </td>
                          )}
                      </>
                    )}

                    {customPortfolioAllowAssetClassModification && (
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.cell,
                          classes.actionsCell,
                          classes.cellHeight
                        )}
                      >
                        <AnyTableRowMenu
                          tableRef={tableRef}
                          tableContainerRef={tableContainerRef}
                          createMenuItems={({ toggleMenu }) =>
                            createMenuItems({ toggleMenu })
                          }
                          setIdOpenModal={setOpenMenuId}
                          currentId={categoryId}
                        />
                      </td>
                    )}
                  </>
                )}
              </AnyTableBodyRow>
            );
          }
        )}
        {customPortfolioModelPortfolioComparison &&
          otherAssetClassModelPortfolioWeight > 0 &&
          !!categories?.length && (
            <AnyTableBodyRow>
              {({ bodyRowCellClassName }) => (
                <>
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.textCell,
                      classes.cellHeight
                    )}
                  >
                    {i18n('roboAdvice.advisory.portfolio.otherAssetClasses')}
                  </td>

                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.numberCell,
                      classes.cellHeight
                    )}
                  >
                    {(!customPortfolioAllocatingInAmountEnabled ||
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.percentage) &&
                      `${formatNumber(
                        cultureCode,
                        otherAssetClassModelPortfolioWeight,
                        1,
                        3
                      )}%`}

                    {customPortfolioAllocatingInAmountEnabled &&
                      selectedGoal.data.monthlyDeposit &&
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.monthlyDeposit &&
                      formatNumber(
                        cultureCode,
                        (otherAssetClassModelPortfolioWeight / 100) *
                          selectedGoal.data.monthlyDeposit,
                        0,
                        2
                      )}

                    {customPortfolioAllocatingInAmountEnabled &&
                      selectedGoal.data.firstDeposit &&
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.firstDeposit &&
                      formatNumber(
                        cultureCode,
                        (otherAssetClassModelPortfolioWeight / 100) *
                          selectedGoal.data.firstDeposit,
                        0,
                        2
                      )}
                  </td>

                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.numberCell,
                      classes.cellHeight
                    )}
                  ></td>

                  {customPortfolioAllowAssetClassModification && (
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.cell,
                        classes.actionsCell,
                        classes.cellHeight
                      )}
                    ></td>
                  )}
                </>
              )}
            </AnyTableBodyRow>
          )}
        {(customPortfolioModelPortfolioComparison || !!categories?.length) && (
          <AnyTableBodyRow>
            {({ bodyRowCellClassName }) => (
              <>
                <td
                  className={classNames(
                    bodyRowCellClassName,
                    classes.textCell,
                    classes.totalRow,
                    classes.cellHeight
                  )}
                >
                  {i18n('shared.total')}
                </td>

                {customPortfolioModelPortfolioComparison && (
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.totalRow,
                      classes.cellHeight,
                      classes.numberCell
                    )}
                  >
                    {(!customPortfolioAllocatingInAmountEnabled ||
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.percentage) &&
                      '100%'}

                    {customPortfolioAllocatingInAmountEnabled &&
                      selectedGoal.data.monthlyDeposit &&
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.monthlyDeposit &&
                      formatNumber(
                        cultureCode,
                        selectedGoal.data.monthlyDeposit,
                        0,
                        CUSTOM_PORTFOLIO_PRECISION
                      )}

                    {customPortfolioAllocatingInAmountEnabled &&
                      selectedGoal.data.firstDeposit &&
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.firstDeposit &&
                      formatNumber(
                        cultureCode,
                        selectedGoal.data.firstDeposit,
                        0,
                        CUSTOM_PORTFOLIO_PRECISION
                      )}
                  </td>
                )}

                {(!customPortfolioAllocatingInAmountEnabled ||
                  selectedGoal.data.customPortfolioAllocationMethod ===
                    CustomPortfolioAllocationMethods.percentage) && (
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.numberCell,
                      classes.totalRow,
                      classes.cellHeight,
                      {
                        [classes.numberNotCorrect]: totalSum !== 100
                      }
                    )}
                  >
                    {formatNumber(
                      cultureCode,
                      totalSum,
                      0,
                      CUSTOM_PORTFOLIO_PRECISION
                    )}
                    %
                  </td>
                )}

                {customPortfolioAllocatingInAmountEnabled && (
                  <>
                    {selectedGoal.data.monthlyDeposit &&
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.monthlyDeposit && (
                        <td
                          className={classNames(
                            bodyRowCellClassName,
                            classes.numberCell,
                            classes.totalRow,
                            classes.cellHeight,
                            {
                              [classes.numberNotCorrect]: totalSum !== 100
                            }
                          )}
                        >
                          {totalSum !== 100
                            ? `${formatNumber(
                                cultureCode,
                                selectedGoal.data.monthlyDeposit *
                                  (totalSum / 100),
                                0,
                                CUSTOM_PORTFOLIO_PRECISION
                              )} / `
                            : null}
                          {formatNumber(
                            cultureCode,
                            selectedGoal.data.monthlyDeposit,
                            0,
                            CUSTOM_PORTFOLIO_PRECISION
                          )}
                        </td>
                      )}

                    {selectedGoal.data.firstDeposit &&
                      selectedGoal.data.customPortfolioAllocationMethod ===
                        CustomPortfolioAllocationMethods.firstDeposit && (
                        <td
                          className={classNames(
                            bodyRowCellClassName,
                            classes.numberCell,
                            classes.totalRow,
                            classes.cellHeight,
                            {
                              [classes.numberNotCorrect]: totalSum !== 100
                            }
                          )}
                        >
                          {totalSum !== 100
                            ? `${formatNumber(
                                cultureCode,
                                selectedGoal.data.firstDeposit *
                                  (totalSum / 100),
                                0,
                                CUSTOM_PORTFOLIO_PRECISION
                              )} / `
                            : null}
                          {formatNumber(
                            cultureCode,
                            selectedGoal.data.firstDeposit,
                            0,
                            CUSTOM_PORTFOLIO_PRECISION
                          )}
                        </td>
                      )}
                  </>
                )}

                {customPortfolioAllowAssetClassModification && (
                  <td
                    className={classNames(
                      bodyRowCellClassName,
                      classes.cell,
                      classes.actionsCell,
                      classes.totalRow,
                      classes.cellHeight
                    )}
                  ></td>
                )}
              </>
            )}
          </AnyTableBodyRow>
        )}
      </AnyTableBody>
    </AnyTableComponent>
  );
};

export default AssetClassAllocationTable;
