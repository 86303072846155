import { isNil } from 'ramda';
import { useCallback } from 'react';

import { usePageStore as useProposalPageStore } from '../../proposal/services/pageStore';
import { PageStatuses } from '../../shared/components/useReadDataListener';
import {
  useGetExistingPortfolioChartData,
  useGetModelPortfolioChartData
} from '../../shared/portfolioChartSelectors';
import { Goal } from '../../shared/services/goalsStore';
import { PortfolioIds } from '../services/constants';
import { usePageStore as useAdvisoryPageStore } from '../services/pageStore';
import HistoricalReturn from './historicalReturn';
import AssetClassAllocation from './modelPortfolio/assetClassAllocation';
import FundAllocation from './modelPortfolio/fundAllocation';
import SustainabilityTab from './modelPortfolio/sustainabilityTab';
import { AdvisoryComponents } from 'defaults/defaultAdvisoryComponents';
import { carouselIds } from 'features/shared/components/carousel/carouselStore';
import {
  Carousel,
  CarouselBody,
  CarouselItem,
  CarouselTab,
  CarouselTabs
} from 'features/shared/components/carousel/verticalNavigation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  carouselRoot: {
    padding: 0
  },
  tabs: {
    paddingLeft: 0
  }
});

type Props = {
  selectedGoal: Goal;
  advisoryComponents: AdvisoryComponents;
};

const ModelPortfolio = ({ selectedGoal, advisoryComponents }: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const advisoryPageStore = useAdvisoryPageStore();
  const proposalPageStore = useProposalPageStore();
  const {
    advisoryComponents: {
      existingPortfolioComparison,
      historicalReturnEnabled,
      historicalReturnCompareWithBenchmark,
      modelPortfolioSustainabilityTab: {
        enabled: modelPortfolioSustainabilityTabEnabled
      }
    }
  } = useCustomerConfig();
  const existingPortfolioChartData = useGetExistingPortfolioChartData();
  const modelPortfolioChartData = useGetModelPortfolioChartData({
    goal: selectedGoal
  });

  const carouselElements = [
    {
      tab: 'roboAdvice.advisory.customPortfolio.tabs.assetClassAllocation',
      item: (
        <AssetClassAllocation
          advisoryComponents={advisoryComponents}
          portfolioCharts={[
            existingPortfolioChartData,
            modelPortfolioChartData
          ]}
          selectedGoal={selectedGoal}
        />
      ),
      key: 'asset-class-allocation',
      statusKeys: ['readPortfolioChartData'],
      display: true
    },
    {
      tab: 'roboAdvice.advisory.customPortfolio.tabs.fundAllocation',
      item: <FundAllocation portfolioChartData={modelPortfolioChartData} />,
      key: 'fund-allocation',
      statusKeys: ['readPortfolioChartData'],
      display: true
    },
    {
      tab: 'roboAdvice.advisory.customPortfolio.tabs.historicalReturn',
      item: (
        <HistoricalReturn
          selectedGoal={selectedGoal}
          portfolioIds={
            [
              existingPortfolioComparison && PortfolioIds.existingPortfolio,
              PortfolioIds.modelPortfolio,
              historicalReturnCompareWithBenchmark &&
                PortfolioIds.benchmarkPortfolio
            ].filter(
              Boolean
            ) as typeof PortfolioIds[keyof typeof PortfolioIds][]
          }
        />
      ),
      key: 'historical-return',
      statusKeys: [
        'readHistoricalReturn3MChartData',
        'readHistoricalReturnYTDChartData',
        'readHistoricalReturn1YChartData',
        'readHistoricalReturn3YChartData',
        'readHistoricalReturn5YChartData',
        'readHistoricalReturn10YChartData'
      ],
      display: historicalReturnEnabled
    },
    {
      tab: 'roboAdvice.advisory.customPortfolio.tabs.sustainability',
      item: <SustainabilityTab goal={selectedGoal} />,
      key: 'sustainability',
      statusKeys: ['readSustainabilityModelPortfolioData'],
      display: modelPortfolioSustainabilityTabEnabled
    }
  ].filter(({ display }) => display);

  const getIsCarouselPending = useCallback(
    selectedItemIndex => {
      const statusKeys = carouselElements[selectedItemIndex]?.statusKeys;

      return isNil(statusKeys)
        ? false
        : statusKeys.some(
            statusKey =>
              advisoryPageStore.pageStatuses[statusKey] ===
                PageStatuses.pending ||
              proposalPageStore.pageStatuses[statusKey] === PageStatuses.pending
          );
    },
    [advisoryPageStore.pageStatuses, proposalPageStore.pageStatuses]
  );

  return (
    <Carousel
      getIsPending={getIsCarouselPending}
      className={classes.carouselRoot}
      carouselId={carouselIds.modelPortfolio}
    >
      <CarouselTabs
        className={classes.tabs}
        carouselId={carouselIds.modelPortfolio}
      >
        {carouselElements.map(({ tab, key }) => (
          <CarouselTab key={key}>{i18n(tab)}</CarouselTab>
        ))}
      </CarouselTabs>
      <CarouselBody carouselId={carouselIds.modelPortfolio}>
        {carouselElements.map(({ item, key }) => (
          <CarouselItem key={key}>{item}</CarouselItem>
        ))}
      </CarouselBody>
    </Carousel>
  );
};

export default ModelPortfolio;
