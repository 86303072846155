import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { SessionStatuses } from '../../../shared/constants';
import { AdviceSessionFormValues, useForm } from '../../form/services/form';
import { costForm } from '../../proposal/services/costForm';
import { useSessionStore } from '../../session/services/sessionStore';
import { SessionPatchTypes, SessionNamespaces } from '../../shared/constants';
import { useQueuedPatchAdviceSession } from './useQueuedPatchAdviceSession';
import { useLazyEffect } from 'features/shared/hooks/useLazyEffect.js';
import { omitNilProps } from 'features/shared/utils/omitNilProps';

const createPayload = (values: AdviceSessionFormValues) => {
  if (values.orderInformation) {
    return {
      ...values,
      orderInformation: omitNilProps(values.orderInformation)
    };
  }

  return Object.entries(values).reduce((acc, [key, value]) => {
    if (
      [
        'adviceInformation',
        'personFinancialSituation',
        'companyFinancialSituation'
      ].includes(key)
    ) {
      return { ...acc, [key]: omitNilProps(value) };
    }
    return { ...acc, [key]: value };
  }, {});
};

export function usePatchAdviceSessionListener() {
  const { adviceSessionId } = useParams<any>();
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const { isSessionInitialized, status } = useSessionStore();
  const { values } = useForm();
  const { isAdvisoryPageInitialized, isBasicDataInitialized } =
    useSessionStore();

  useLazyEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isSessionInitialized || status === SessionStatuses.complete) {
        return;
      }

      queuedPatchAdviceSession({
        id: v4(),
        type: SessionPatchTypes.saveSessionData,
        adviceSessionId,
        namespace: SessionNamespaces.advisorInput,
        payload: createPayload(values)
      });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [values]);

  useLazyEffect(() => {
    if (!isSessionInitialized || status === SessionStatuses.complete) {
      return;
    }
    const { values } = costForm.getState();

    queuedPatchAdviceSession({
      id: v4(),
      type: SessionPatchTypes.saveSessionData,
      adviceSessionId,
      namespace: SessionNamespaces.sessionData,
      payload: {
        customPortfolios: [],
        isAdvisoryPageInitialized,
        isBasicDataInitialized,
        ...values
      }
    });
  }, [isAdvisoryPageInitialized, isBasicDataInitialized]);
}
