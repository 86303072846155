import { AdviceSession } from '../../main/services/clientForm';

export type ChannelName = typeof channelNames[keyof typeof channelNames];

export const channelNames = {
  advisoryChannel: 'Advisory channel',
  selfService: 'Self service'
} as const;

export const mapClientAdviceSessionToServer = (
  currentUser,
  clientId: string,
  adviceSession: AdviceSession
) => {
  return {
    name: adviceSession.name ?? '',
    session_id: adviceSession.id,
    advisor_id: currentUser.auth0Id,
    advice_type: adviceSession.adviceType ?? '',
    investor_id: clientId
  };
};

export const mapServerAdviceSessionToClient = adviceSession => {
  return {
    id: adviceSession.session_id,
    name: adviceSession.name,
    created: adviceSession.created_date,
    lastActivityDate: adviceSession.last_activity_date,
    channel: adviceSession.channel,
    status: adviceSession.status,
    adviceType: adviceSession.advice_type,
    advisor: adviceSession.advisor
  };
};

export const mapChannelNameToTranslationKey = (channel?: ChannelName) => {
  switch (channel) {
    case channelNames.advisoryChannel:
      return 'roboAdvice.client.adviceSessionChannels.advisoryChannel';
    case channelNames.selfService:
      return 'roboAdvice.client.adviceSessionChannels.selfService';
    default:
      return '';
  }
};

export const mapNewGoalIdsForCustomPortfolioAdvisorNotes = (
  sessionPayloadData,
  newGoalIdsMap
) => {
  if (
    Object.keys(newGoalIdsMap).length === 0 ||
    !sessionPayloadData.customPortfolio
  ) {
    return sessionPayloadData;
  }

  const customPortfolio = sessionPayloadData.customPortfolio as {
    [key: string]: { advisorNotes: string };
  };
  Object.entries(customPortfolio).forEach(([field, { advisorNotes }]) => {
    customPortfolio[field].advisorNotes = Object.fromEntries(
      Object.entries(advisorNotes).map(([goalId, advisorNote]) => [
        newGoalIdsMap[goalId],
        advisorNote
      ])
    );
  });
  sessionPayloadData.customPortfolio = customPortfolio;

  return sessionPayloadData;
};
