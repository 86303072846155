import classNames from 'classnames';
import { isNil } from 'ramda';
import {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect
} from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';

import { usePageStore as useProposalPageStore } from '../../../proposal/services/pageStore';
import { CustomPortfolioAllocationMethods } from '../../services/constants';
import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import {
  fundAllocationForm,
  useFundAllocationForm
} from '../../services/fundAllocationForm';
import {
  CustomPortfolioAllocationMethod,
  FundAllocationRow
} from '../../types';
import ChangeAllocationIcon, {
  ChangeAllocationIconType
} from './changeAllocationIcon';
import { CUSTOM_PORTFOLIO_PRECISION } from './constants';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  CustomPortfolioItem,
  Goal
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import Button, {
  ButtonType,
  TertiaryButtonSize
} from 'features/shared/components/button';
import NumberInput from 'features/shared/components/numberInput/numberInput';
import Scrollbars from 'features/shared/components/scrollbars';
import Table from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import {
  areNumbersEqualWithPrecisionError,
  formatNumber,
  getNumberInputFormat,
  roundNumber
} from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const AnyTableComponent: any = Table;
const AnyTableBody: any = TableBody;
const AnyTableBodyRow: any = TableBodyRow;

const useStyles = createUseStyles(theme => ({
  root: {
    margin: `${Spacing.spacing01}px 0 ${Spacing.spacing04}px 0`,
    textAlign: 'left'
  },
  textCell: {
    textAlign: 'left',
    fontWeight: FontWeights.medium
  },
  numberCell: {
    textAlign: 'right'
  },
  greyCell: {
    color: theme.tertiaryTextColor
  },
  wrongNumberCell: {
    color: theme.errorNegativeColor
  },
  instrumentNameCell: {
    '&$textCell': {
      paddingLeft: Spacing.spacing03
    }
  },
  cell: {
    verticalAlign: 'top'
  },
  allocationCell: {
    minWidth: '100px',

    '&$allocationCell': {
      textAlign: 'right'
    }
  },
  actionsCell: {
    verticalAlign: 'middle',
    lineHeight: '1',
    textAlign: 'right',
    width: '80px'
  },
  weightInput: {
    textAlign: 'right',
    width: '150px'
  },
  mainRowEdit: {
    borderTop: `1px solid ${theme.accentColor}`
  },
  lastEditRow: {
    borderBottom: `1px solid ${theme.accentColor}`
  },
  cellEdit: {
    // Hack to replace !important
    '&$cellEdit': {
      '&:first-child': {
        borderLeft: `1px solid ${theme.accentColor}`
      },
      '&:last-child': {
        borderRight: `1px solid ${theme.accentColor}`
      },
      '&$textCell': {
        backgroundColor: theme.inputFillFocusColor
      },
      '&$numberCell': {
        backgroundColor: theme.inputFillFocusColor
      },
      '&$cell': {
        backgroundColor: theme.inputFillFocusColor
      }
    }
  },
  customAttributeCell: {
    wordBreak: 'break-word'
  },
  fundSelectorCell: {
    minWidth: '280px',
    [`@media (max-width: ${LayoutBreakpoints.desktopMedium}px)`]: {
      minWidth: '200px'
    }
  },
  additionalHeaderCell: {
    minWidth: '125px'
  },
  scrollbarHorizontal: {
    bottom: Spacing.spacing02
  },
  customPortfolioAllocationMethodHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.spacing00,
    justifyContent: 'flex-end',
    color: theme.secondaryColor
  }
}));

type Props = {
  selectedGoal: Goal;
  fundAllocationRowsData: FundAllocationRow[];
  additionalTableHeaders: {
    title?: string;
    component?: ReactNode;
    className?: string;
  }[];
  additionalFirstRows?: ReactNode;
  assetEdit: Record<string, boolean>;
  setAssetEdit: Dispatch<SetStateAction<Record<string, boolean>>>;
};

const FundAllocationBaseTable = ({
  selectedGoal,
  fundAllocationRowsData,
  additionalTableHeaders,
  additionalFirstRows,
  setAssetEdit,
  assetEdit
}: Props) => {
  const i18n = useI18n();
  const classes = useStyles();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const customPortfolioStore = useCustomPortfolioStore();
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const { values: fundAllocationFormValues } = useFundAllocationForm();
  const updateGoal = useUpdateGoal();
  const proposalPageStore = useProposalPageStore.getState();
  const {
    advisoryComponents: {
      customPortfolioAllocatingInAmountEnabled,
      customPortfolio: {
        fundAllocation: { showSumAllocation }
      }
    }
  } = useCustomerConfig();

  useEffect(() => {
    const assets = selectedGoal.data.customPortfolio || [];

    const fundAllocation = assets.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.CategoryId]: curr.instruments.reduce(
          (iPrev, iCurr) => ({
            ...iPrev,
            [iCurr.id]: iCurr.weight || 0
          }),
          {}
        )
      }),
      {}
    );

    fundAllocationForm.initialize({
      fundAllocation
    });
  }, [selectedGoal.data.customPortfolio]);

  const handleSaveInstruments = () => {
    const oldCustomPortfolio = selectedGoal.data.customPortfolio || [];

    const newData: CustomPortfolioItem[] = oldCustomPortfolio.map(oldAsset => {
      const newFundsObj = fundAllocationFormValues.fundAllocation
        ? fundAllocationFormValues.fundAllocation[oldAsset.CategoryId]
        : null;
      const newInstruments = isNil(newFundsObj)
        ? []
        : Object.entries(newFundsObj).map(([id, weight]) => ({ id, weight }));

      return {
        ...oldAsset,
        instruments: newInstruments
          .map(i => {
            let oldInstrument = oldAsset.instruments.find(n => n.id === i.id);

            if (oldInstrument === undefined) {
              const availableInstruments =
                customPortfolioStore.assetClasses.find(
                  ({ categoryId }) => categoryId === oldAsset.CategoryId
                )?.availableInstruments ?? [];
              oldInstrument = availableInstruments?.find(n => n.id === i.id);
            }

            return {
              ...oldInstrument!,
              weight: i.weight
            };
          })
          .filter(({ weight }) => weight)
      };
    });

    updateGoal(selectedGoal.goalId, {
      data: {
        customPortfolio: newData
      }
    });
  };

  const handleChangeFundValue = (
    value: number,
    name: string,
    allocationMethod: CustomPortfolioAllocationMethod
  ) => {
    let valueAdjustedToMethod = value;
    if (
      value &&
      allocationMethod === CustomPortfolioAllocationMethods.monthlyDeposit &&
      selectedGoal.data.monthlyDeposit
    ) {
      valueAdjustedToMethod = (value / selectedGoal.data.monthlyDeposit) * 100;
    }

    if (
      value &&
      allocationMethod === CustomPortfolioAllocationMethods.firstDeposit &&
      selectedGoal.data.firstDeposit
    ) {
      valueAdjustedToMethod = (value / selectedGoal.data.firstDeposit) * 100;
    }

    if (
      value &&
      valueAdjustedToMethod !==
        roundNumber(valueAdjustedToMethod, CUSTOM_PORTFOLIO_PRECISION) &&
      areNumbersEqualWithPrecisionError(
        valueAdjustedToMethod,
        roundNumber(valueAdjustedToMethod, CUSTOM_PORTFOLIO_PRECISION)
      )
    ) {
      // If the value has float precision problem we round it to the precision we use in the custom portfolio
      valueAdjustedToMethod = roundNumber(
        valueAdjustedToMethod,
        CUSTOM_PORTFOLIO_PRECISION
      );
    }

    fundAllocationForm.change(name as any, valueAdjustedToMethod);
  };

  return (
    <Form form={fundAllocationForm} onSubmit={() => {}}>
      {() => (
        <FormSpy subscription={{ values: true }}>
          {formSpyData => (
            <Scrollbars
              doRenderThumbVertical={false}
              autoHeightMax={'none'}
              scrollbarHorizontalClassName={classes.scrollbarHorizontal}
            >
              <AnyTableComponent
                header={[
                  {
                    title: i18n(
                      'roboAdvice.advisory.customPortfolio.fundSelector'
                    ),
                    className: classes.fundSelectorCell
                  },
                  {
                    title: i18n(
                      'roboAdvice.advisory.customPortfolio.allocation'
                    ),
                    component: (
                      <div
                        className={
                          classes.customPortfolioAllocationMethodHeader
                        }
                      >
                        {i18n('roboAdvice.advisory.customPortfolio.allocation')}
                        {customPortfolioAllocatingInAmountEnabled && (
                          <ChangeAllocationIcon
                            type={
                              selectedGoal.data
                                .customPortfolioAllocationMethod ===
                              CustomPortfolioAllocationMethods.percentage
                                ? ChangeAllocationIconType.percentage
                                : ChangeAllocationIconType.amount
                            }
                          />
                        )}
                      </div>
                    ),
                    className: classes.allocationCell
                  },
                  ...additionalTableHeaders.map(
                    ({ title, component, className }) => ({
                      title,
                      component,
                      className: classNames(
                        className,
                        classes.additionalHeaderCell
                      )
                    })
                  ),
                  {
                    title: ''
                  }
                ]}
                externalClasses={{
                  container: classes.root
                }}
                isLoading={
                  customPortfolioStore.isRiskTemplateLoading ||
                  customPortfolioStore.assetClassInstrumentsStatus ===
                    PageStatuses.pending ||
                  proposalPageStore.pageStatuses['readPortfolioChartData'] ===
                    PageStatuses.pending
                }
              >
                <AnyTableBody
                  noDataTitle={i18n(
                    'roboAdvice.advisory.customPortfolio.emptyFundAllocationTablePlaceholder'
                  )}
                  cellsNumber={3 + additionalTableHeaders.length}
                >
                  {additionalFirstRows}
                  {fundAllocationRowsData.map(
                    ({
                      subAssetClass,
                      weight = 0,
                      instruments,
                      index,
                      categoryId,
                      isEditing
                    }) => {
                      let assetInstrumentsSum = roundNumber(
                        instruments.reduce(
                          (prev, curr) => prev + (curr.instrumentWeight || 0),
                          0
                        ),
                        CUSTOM_PORTFOLIO_PRECISION
                      );
                      if (isEditing) {
                        const editingInstruments =
                          Object.entries(
                            fundAllocationFormValues.fundAllocation || {}
                          ).find(
                            ([rowCategoryId]) => categoryId === rowCategoryId
                          )?.[1] || {};

                        assetInstrumentsSum = roundNumber(
                          Object.values(editingInstruments).reduce(
                            (prev, curr) => prev + (curr + 0),
                            0
                          ),
                          CUSTOM_PORTFOLIO_PRECISION
                        );
                      }

                      return (
                        <Fragment key={categoryId}>
                          <AnyTableBodyRow
                            index={index}
                            className={classNames({
                              [classes.mainRowEdit]: isEditing
                            })}
                          >
                            {({ bodyRowCellClassName }) => (
                              <>
                                <td
                                  className={classNames(
                                    bodyRowCellClassName,
                                    classes.textCell,
                                    classes.greyCell,
                                    { [classes.cellEdit]: isEditing }
                                  )}
                                >
                                  {subAssetClass}
                                </td>

                                <td
                                  className={classNames(
                                    bodyRowCellClassName,
                                    classes.numberCell,
                                    classes.greyCell,
                                    {
                                      [classes.cellEdit]: isEditing,
                                      [classes.wrongNumberCell]:
                                        weight !== assetInstrumentsSum
                                    }
                                  )}
                                >
                                  {(!customPortfolioAllocatingInAmountEnabled ||
                                    selectedGoal.data
                                      .customPortfolioAllocationMethod ===
                                      CustomPortfolioAllocationMethods.percentage) &&
                                    (weight !== assetInstrumentsSum &&
                                    showSumAllocation
                                      ? `${formatNumber(
                                          cultureCode,
                                          assetInstrumentsSum,
                                          0,
                                          CUSTOM_PORTFOLIO_PRECISION
                                        )} / ${formatNumber(
                                          cultureCode,
                                          weight,
                                          0,
                                          CUSTOM_PORTFOLIO_PRECISION
                                        )}%`
                                      : `${formatNumber(
                                          cultureCode,
                                          weight,
                                          0,
                                          CUSTOM_PORTFOLIO_PRECISION
                                        )}%`)}

                                  {customPortfolioAllocatingInAmountEnabled &&
                                    showSumAllocation &&
                                    selectedGoal.data.monthlyDeposit &&
                                    selectedGoal.data
                                      .customPortfolioAllocationMethod ===
                                      CustomPortfolioAllocationMethods.monthlyDeposit && (
                                      <>
                                        {assetInstrumentsSum !== weight
                                          ? `${formatNumber(
                                              cultureCode,
                                              selectedGoal.data.monthlyDeposit *
                                                (assetInstrumentsSum / 100),
                                              0,
                                              CUSTOM_PORTFOLIO_PRECISION
                                            )} / `
                                          : null}
                                        {formatNumber(
                                          cultureCode,
                                          selectedGoal.data.monthlyDeposit *
                                            (weight / 100),
                                          0,
                                          CUSTOM_PORTFOLIO_PRECISION
                                        )}
                                      </>
                                    )}

                                  {customPortfolioAllocatingInAmountEnabled &&
                                    showSumAllocation &&
                                    selectedGoal.data.firstDeposit &&
                                    selectedGoal.data
                                      .customPortfolioAllocationMethod ===
                                      CustomPortfolioAllocationMethods.firstDeposit && (
                                      <>
                                        {assetInstrumentsSum !== weight
                                          ? `${formatNumber(
                                              cultureCode,
                                              selectedGoal.data.firstDeposit *
                                                (assetInstrumentsSum / 100),
                                              0,
                                              CUSTOM_PORTFOLIO_PRECISION
                                            )} / `
                                          : null}
                                        {formatNumber(
                                          cultureCode,
                                          selectedGoal.data.firstDeposit *
                                            (weight / 100),
                                          0,
                                          CUSTOM_PORTFOLIO_PRECISION
                                        )}
                                      </>
                                    )}
                                </td>

                                {additionalTableHeaders.map(
                                  (_, additionalTableHeaderIndex) => (
                                    <td
                                      key={additionalTableHeaderIndex}
                                      className={classNames(
                                        bodyRowCellClassName,
                                        classes.textCell,
                                        {
                                          [classes.cellEdit]: isEditing
                                        }
                                      )}
                                    ></td>
                                  )
                                )}

                                <td
                                  className={classNames(
                                    bodyRowCellClassName,
                                    classes.cell,
                                    classes.textCell,
                                    classes.actionsCell,
                                    { [classes.cellEdit]: isEditing }
                                  )}
                                >
                                  {!isNil(weight) ? (
                                    assetEdit[categoryId] ? (
                                      <Button
                                        buttonType={ButtonType.tertiary}
                                        buttonSize={TertiaryButtonSize.small}
                                        onClick={() => {
                                          handleSaveInstruments();
                                          setAssetEdit(state => ({
                                            ...state,
                                            [categoryId]: false
                                          }));
                                        }}
                                        disabled={Object.values(
                                          formSpyData.values.fundAllocation?.[
                                            categoryId
                                          ] || ({} as any)
                                        ).some(
                                          weight =>
                                            roundNumber(
                                              (weight ?? 0) as number,
                                              CUSTOM_PORTFOLIO_PRECISION
                                            ) !== weight ?? 0
                                        )} // If there are any invalid steps, the button is disabled
                                      >
                                        {i18n('shared.done')}
                                      </Button>
                                    ) : (
                                      <Button
                                        buttonType={ButtonType.tertiary}
                                        buttonSize={TertiaryButtonSize.small}
                                        onClick={() =>
                                          setAssetEdit(state => ({
                                            ...state,
                                            [categoryId]: true
                                          }))
                                        }
                                      >
                                        {i18n('shared.edit')}
                                      </Button>
                                    )
                                  ) : null}
                                </td>
                              </>
                            )}
                          </AnyTableBodyRow>
                          {instruments.map(
                            (
                              {
                                name,
                                instrumentWeight,
                                createAdditionalData,
                                id: instrumentId
                              },
                              instrumentIndex,
                              array
                            ) => {
                              const currentInstrumentWeight =
                                fundAllocationFormValues.fundAllocation?.[
                                  categoryId
                                ]?.[instrumentId] || 0;
                              const fundAmountMonthlyDeposit = selectedGoal.data
                                .monthlyDeposit
                                ? (selectedGoal.data.monthlyDeposit *
                                    (currentInstrumentWeight || 0)) /
                                  100
                                : 0;

                              const fundAmountFirstDeposit = selectedGoal.data
                                .firstDeposit
                                ? (selectedGoal.data.firstDeposit *
                                    (currentInstrumentWeight || 0)) /
                                  100
                                : 0;

                              const isInvalidStep =
                                roundNumber(
                                  currentInstrumentWeight ?? 0,
                                  CUSTOM_PORTFOLIO_PRECISION
                                ) !== currentInstrumentWeight ?? 0;

                              return (
                                <AnyTableBodyRow
                                  key={instrumentId}
                                  index={index}
                                  className={classNames({
                                    [classes.lastEditRow]:
                                      instrumentIndex === array.length - 1 &&
                                      isEditing
                                  })}
                                >
                                  {({ bodyRowCellClassName }) => (
                                    <>
                                      <td
                                        className={classNames(
                                          bodyRowCellClassName,
                                          classes.textCell,
                                          classes.instrumentNameCell,
                                          { [classes.cellEdit]: isEditing }
                                        )}
                                      >
                                        {name}
                                      </td>

                                      <td
                                        className={classNames(
                                          bodyRowCellClassName,
                                          classes.numberCell,
                                          classes.allocationCell,
                                          { [classes.cellEdit]: isEditing }
                                        )}
                                      >
                                        {(!customPortfolioAllocatingInAmountEnabled ||
                                          selectedGoal.data
                                            .customPortfolioAllocationMethod ===
                                            CustomPortfolioAllocationMethods.percentage) && (
                                          <>
                                            {isEditing ? (
                                              <NumberInput
                                                value={
                                                  fundAllocationFormValues
                                                    ?.fundAllocation?.[
                                                    categoryId
                                                  ]?.[instrumentId]
                                                }
                                                onChange={val =>
                                                  handleChangeFundValue(
                                                    val,
                                                    `fundAllocation.${categoryId}.${instrumentId}`,
                                                    CustomPortfolioAllocationMethods.percentage
                                                  )
                                                }
                                                suffix={'%'}
                                                thousandSeparator={
                                                  thousandSeparator
                                                }
                                                decimalSeparator={
                                                  decimalSeparator
                                                }
                                                decimalScale={
                                                  CUSTOM_PORTFOLIO_PRECISION
                                                }
                                                isAllowed={({ floatValue }) =>
                                                  floatValue === undefined ||
                                                  (floatValue >= 0 &&
                                                    floatValue <= 100)
                                                }
                                                allowNegative={false}
                                                className={classes.weightInput}
                                              />
                                            ) : !isNil(instrumentWeight) ? (
                                              `${formatNumber(
                                                cultureCode,
                                                instrumentWeight,
                                                0,
                                                CUSTOM_PORTFOLIO_PRECISION
                                              )}%`
                                            ) : null}
                                          </>
                                        )}

                                        {customPortfolioAllocatingInAmountEnabled &&
                                          selectedGoal.data
                                            .customPortfolioAllocationMethod ===
                                            CustomPortfolioAllocationMethods.monthlyDeposit && (
                                            <>
                                              {isEditing ? (
                                                <NumberInput
                                                  value={
                                                    fundAmountMonthlyDeposit
                                                  }
                                                  onChange={val =>
                                                    handleChangeFundValue(
                                                      val,
                                                      `fundAllocation.${categoryId}.${instrumentId}`,
                                                      CustomPortfolioAllocationMethods.monthlyDeposit
                                                    )
                                                  }
                                                  thousandSeparator={
                                                    thousandSeparator
                                                  }
                                                  decimalSeparator={
                                                    decimalSeparator
                                                  }
                                                  decimalScale={2}
                                                  allowNegative={false}
                                                  className={
                                                    classes.weightInput
                                                  }
                                                  isAllowed={({ floatValue }) =>
                                                    floatValue === undefined ||
                                                    (floatValue >= 0 &&
                                                      floatValue <=
                                                        (selectedGoal.data
                                                          .monthlyDeposit ?? 0))
                                                  }
                                                  errorText={
                                                    isInvalidStep
                                                      ? i18n(
                                                          'roboAdvice.advisory.customPortfolio.changeAllocationMethod.errorInvalidIncrementalStep'
                                                        ).replace(
                                                          '{0}',
                                                          `${
                                                            (selectedGoal.data
                                                              ?.monthlyDeposit ??
                                                              0) /
                                                            (10 **
                                                              CUSTOM_PORTFOLIO_PRECISION *
                                                              100)
                                                          }`
                                                        )
                                                      : undefined
                                                  }
                                                  touched
                                                />
                                              ) : !isNil(instrumentWeight) ? (
                                                formatNumber(
                                                  cultureCode,
                                                  fundAmountMonthlyDeposit,
                                                  0,
                                                  CUSTOM_PORTFOLIO_PRECISION
                                                )
                                              ) : null}
                                            </>
                                          )}

                                        {customPortfolioAllocatingInAmountEnabled &&
                                          selectedGoal.data
                                            .customPortfolioAllocationMethod ===
                                            CustomPortfolioAllocationMethods.firstDeposit && (
                                            <>
                                              {isEditing ? (
                                                <NumberInput
                                                  value={fundAmountFirstDeposit}
                                                  onChange={val =>
                                                    handleChangeFundValue(
                                                      val,
                                                      `fundAllocation.${categoryId}.${instrumentId}`,
                                                      CustomPortfolioAllocationMethods.firstDeposit
                                                    )
                                                  }
                                                  thousandSeparator={
                                                    thousandSeparator
                                                  }
                                                  decimalSeparator={
                                                    decimalSeparator
                                                  }
                                                  decimalScale={2}
                                                  allowNegative={false}
                                                  className={
                                                    classes.weightInput
                                                  }
                                                  isAllowed={({ floatValue }) =>
                                                    floatValue === undefined ||
                                                    (floatValue >= 0 &&
                                                      floatValue <=
                                                        (selectedGoal.data
                                                          .firstDeposit ?? 0))
                                                  }
                                                  errorText={
                                                    isInvalidStep
                                                      ? i18n(
                                                          'roboAdvice.advisory.customPortfolio.changeAllocationMethod.errorInvalidIncrementalStep'
                                                        ).replace(
                                                          '{0}',
                                                          `${
                                                            (selectedGoal.data
                                                              ?.firstDeposit ??
                                                              0) /
                                                            (10 **
                                                              CUSTOM_PORTFOLIO_PRECISION *
                                                              100)
                                                          }`
                                                        )
                                                      : undefined
                                                  }
                                                  touched
                                                />
                                              ) : !isNil(instrumentWeight) ? (
                                                formatNumber(
                                                  cultureCode,
                                                  fundAmountFirstDeposit,
                                                  0,
                                                  CUSTOM_PORTFOLIO_PRECISION
                                                )
                                              ) : null}
                                            </>
                                          )}
                                      </td>

                                      {createAdditionalData &&
                                        createAdditionalData({
                                          className: classNames(
                                            bodyRowCellClassName,
                                            classes.numberCell,
                                            classes.customAttributeCell,
                                            { [classes.cellEdit]: isEditing }
                                          )
                                        })}

                                      <td
                                        className={classNames(
                                          bodyRowCellClassName,
                                          classes.cell,
                                          { [classes.cellEdit]: isEditing }
                                        )}
                                      ></td>
                                    </>
                                  )}
                                </AnyTableBodyRow>
                              );
                            }
                          )}
                        </Fragment>
                      );
                    }
                  )}
                </AnyTableBody>
              </AnyTableComponent>
            </Scrollbars>
          )}
        </FormSpy>
      )}
    </Form>
  );
};

export default FundAllocationBaseTable;
