import classNames from 'classnames';
import { isNil } from 'ramda';
import React from 'react';

import { useI18n } from '../../customerConfig/components/useI18n';
import { useErrorsStore } from '../services/errorsStore';
import { ErrorMessage } from '../types';
import { getNumberOfErrors } from '../utils';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import {
  carouselIdsValues,
  CarouselStoreData,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import Icon from 'features/shared/components/icon';
import { Spacing } from 'features/shared/constants/spacing';
import { FontWeights, Typography } from 'features/shared/constants/typography';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  errorMessage: {
    width: '454px',
    position: 'relative',
    padding: `${Spacing.spacing01}px ${Spacing.spacing00}px`,
    marginTop: '0.8rem',
    borderRadius: '10px',
    fontSize: Typography.body1.size,
    lineHeight: Typography.body1.lineHeight,
    fontWeight: FontWeights.medium,
    background: theme.itemBackgroundColor_1,
    border: `1px solid ${theme.defaultInputStrokeColor}`,
    marginBottom: `${Spacing.spacing02}px`
  },
  infoIcon: {
    fill: theme.errorNegativeColor,
    position: 'absolute'
  },
  closeIcon: {
    position: 'absolute',
    right: `${Spacing.spacing00}px`,
    top: `${Spacing.spacing01}px`,
    cursor: 'pointer',
    color: theme.secondaryColor
  },
  errorsSection: {
    padding: '0 36px',
    '& > ul': {
      paddingInlineStart: '20px'
    }
  },
  header: {
    color: theme.primaryColor,
    fontSize: Typography.body1.size,
    fontWeight: FontWeights.medium
  },
  error: {
    color: theme.accentColor,
    cursor: 'pointer'
  }
}));

type Props = {
  errors: {
    [key: string]: ErrorMessage[];
  };
  pageName: string;
  className?: string;
  setCurrentAdvisoryTab?: (goalId: string) => void;
  setActiveProductPlatformTab?: (tabId: number) => void;
};

const ErrorsList = ({
  errors,
  pageName,
  className = '',
  setCurrentAdvisoryTab,
  setActiveProductPlatformTab
}: Props) => {
  const i18n = useI18n();
  const classes = useStyles();
  const errorsStore = useErrorsStore();
  const carouselStore = useCarouselStore();

  const scrollToError = (error: ErrorMessage) => {
    if (error.elementId) {
      if (error?.data?.advisoryGoal && setCurrentAdvisoryTab) {
        setCurrentAdvisoryTab(error.data.advisoryGoal);
      }

      if (error?.data?.productPlatformTab && setActiveProductPlatformTab) {
        setActiveProductPlatformTab(error.data.productPlatformTab);
      }

      if (error?.data?.carouselState) {
        const state = error?.data?.carouselState as CarouselStoreData;
        Object.keys(state).forEach(key => {
          if (!isNil(state[key])) {
            carouselStore.setSelectedItemIndex(
              key as carouselIdsValues,
              state[key].selectedItemIndex
            );
          }
        });
      }

      // This solves the issue of the carousel not being scrolled to the correct position
      // when the error is in a carousel item
      // "scrollIntoView" is not working properly in this case, it affects y-axis scrolling
      const errorElement = document.getElementById(error.elementId);
      const bodyRect = document.body.getBoundingClientRect();
      const errorRect = errorElement?.getBoundingClientRect();
      const topOffset = (errorRect?.top ?? 0) - bodyRect.top;
      const errorHeight = errorRect?.height ?? 0;
      window?.scroll({
        left: 0,
        top: topOffset - window.innerHeight / 2 + errorHeight / 2,
        behavior: 'smooth'
      });
    }
  };

  if (getNumberOfErrors(errors) === 0 || !errorsStore.isErrorModeEnabled) {
    return null;
  }

  return (
    <div className={classNames(classes.errorMessage, className)}>
      <WarningIcon className={classes.infoIcon} />
      <Icon
        className={classes.closeIcon}
        type={'close'}
        onClick={() => {
          errorsStore.disableErrorMode();
        }}
      />
      <div className={classes.errorsSection}>
        <div className={classes.header}>
          {i18n('roboAdvice.errors.errorsListHeader')
            .replace('{0}', pageName)
            .replace('{1}', getNumberOfErrors(errors)?.toString())}
        </div>
        <ul>
          {Object.values(errors).map(errorsList =>
            errorsList.map(error => (
              <li
                key={error.listMessage}
                className={classes.error}
                onClick={() => scrollToError(error)}
              >
                {error.listMessage}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default ErrorsList;
