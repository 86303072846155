import axios, { CancelTokenSource } from 'axios';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { usePageLanguage } from '../../../../../pageLanguage/main/components/usePageLanguage';
import { usePageStore as useAdvisoryPageStore } from '../../../advisory/services/pageStore';
import { useCostChartStore } from '../../../costChart';
import { useForm as useRoboAdviceForm } from '../../../form/services/form';
import { useQueuedPatchAdviceSession } from '../../../main/components/useQueuedPatchAdviceSession';
import { usePortfolioChartStore } from '../../../portfolioChart';
import {
  SessionNamespaces,
  SessionPatchTypes
} from '../../../shared/constants';
import { WithdrawalPlansClassToTitle } from '../../../shared/constants';
import {
  FlatAssetClass,
  getProductAttributesPDF,
  groupAssetClasses
} from '../../../shared/mapping';
import { createPdfReport } from '../../api';
import { mapAttachmentsData } from '../../services/attachmentsMapping';
import { costForm } from '../../services/costForm';
import { generateReportForm } from '../../services/generateReportForm';
import {
  getAutomaticClientClassification,
  useMapKycData
} from '../../services/kycMapping';
import { mapClientUserDataToReport } from '../../services/mapping';
import { populateGoalsWithAdvancedSuitabilityStatusData } from '../../services/mappingSelectors';
import { HistoricalReturn } from '../../services/mappingTypes';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { getPdfReportHistoricalReturn } from '../../services/selectors';
import { getMaxRiskClass } from '../../services/selectors.js';
import { saveFile } from '../../utils.js';
import { useReadCashflowData } from '../cashflowChart/useReadCashflowData';
import { getCashflowWithdrawal, getCashflowYear } from '../cashflowTable.js';
import { useReadCostChartData } from '../costChart/useReadCostChartData';
import { useReadExpectedPathChartData } from '../expectedPathChart/useReadExpectedPathChartData';
import { useReadExpectedValueChartData } from '../expectedValueChart/useReadExpectedValueChartData';
import { useReadHistoricalReturnChartData } from '../historicalReturnChart/useReadHistoricalReturnChartData';
import useReadOrderSummary from '../orderSummary/useReadOrderSummary';
import { useReadPortfolioChartData } from '../portfolioChart/useReadPortfolioChartData';
import { useReadSustainability } from '../sustainability/useReadSustainability';
import { MissingDocuments } from './types';
import { useReadProductChooserRecommendation } from 'features/roboAdvice/adviceSession/advisory/components/useReadProductChooserRecommendation';
import { NamespaceConfig } from 'features/roboAdvice/adviceSession/advisory/types';
import { useCashflowChartStore } from 'features/roboAdvice/adviceSession/cashflowChart';
import { useExpectedPathChartStore } from 'features/roboAdvice/adviceSession/expectedPathChart';
import { useExpectedValueChartStore } from 'features/roboAdvice/adviceSession/expectedValueChart';
import { useKnowledgeAndExperienceStore } from 'features/roboAdvice/adviceSession/knowledgeAndExperience/services/knowledgeAndExperienceStore';
import {
  BuyOrder,
  useOrderSummaryStore
} from 'features/roboAdvice/adviceSession/orderSummary';
import {
  getFundsList,
  getRoboPortfolio,
  readAnalyzeUniverseDocuments
} from 'features/roboAdvice/adviceSession/shared/api';
import { UniverseDocumentFormat } from 'features/roboAdvice/adviceSession/shared/api/types';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import {
  useGoalsStore,
  selectors
} from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { useSustainabilityStore } from 'features/roboAdvice/adviceSession/sustainability';
import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { useReadReportDocuments } from 'features/roboAdvice/shared/components/useReadReportDocuments';
import { prepareCostChartData } from 'features/shared/analyticsComponents/costChart/mapping';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { filterNil } from 'features/shared/utils/filters';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useTheme } from 'features/sharedModules/styles/components/styles';

export function useDownloadPdfReport() {
  const { adviceSessionId, clientId } = useParams<AdviceSessionParams>();
  const i18n = useI18n();
  const customerId = useSelector(sessionSelectors.getCurrentUserCustomerCode);
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const { loadPageLanguage } = usePageLanguage();
  const userName: string = useSelector(sessionSelectors.getCurrentUserName);
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const {
    advisoryComponents: {
      customPortfolio: {
        assetClassAllocation: {
          advisorNotes: {
            enabled: isCustomPortfolioAssetClassAllocationAdvisorNotesEnabled
          }
        },
        fundAllocation: {
          advisorNotes: {
            enabled: isCustomPortfolioFundAllocationAdvisorNotesEnabled
          }
        }
      }
    },
    roboPortfolioPrecision,
    roboAdviceForm: {
      knowledgeAndExperienceQuestions,
      financialSituation,
      adviceInformation: { fields: adviceInformationFields },
      proposal: {
        sustainability: { tableType: sustainabilityTableType },
        orderSummary
      },
      knowledgeAndExperience: { advancedSuitability: advancedSuitabilityConfig }
    },
    roboAdvice,
    analyticsComponents,
    timeHorizonConfig,
    supportedLanguages
  } = useCustomerConfig();
  const theme = useTheme();
  const { goals } = useGoalsStore.getState();
  const selectedGoalsIds = useGoalsStore(selectors.getSelectedGoalsIds);
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const readReportDocuments = useReadReportDocuments();
  const readPortfolioChartData = useReadPortfolioChartData();
  const readCostChartData = useReadCostChartData();
  const readCashflowData = useReadCashflowData();
  const readExpectedPathChartData = useReadExpectedPathChartData();
  const readExpectedValueChartData = useReadExpectedValueChartData();
  const readOrderSummary = useReadOrderSummary();
  const readSustainability = useReadSustainability();
  const { isContactListEnabled, isSignatureRightsEnabled, isUBOEnabled } =
    roboAdvice.clientInformation.contacts;
  const {
    advancedSuitabilityStatus,
    advancedSuitabilityConfig: advancedSuitabilityStoreConfig
  } = useKnowledgeAndExperienceStore();

  const readHistoricalReturn3MChartData = useReadHistoricalReturnChartData(
    'readHistoricalReturn3MChartData',
    '3M'
  );
  const readHistoricalReturnYTDChartData = useReadHistoricalReturnChartData(
    'readHistoricalReturnYTDChartData',
    'YTD'
  );
  const readHistoricalReturn1YChartData = useReadHistoricalReturnChartData(
    'readHistoricalReturn1YChartData',
    '1Y'
  );
  const readHistoricalReturn3YChartData = useReadHistoricalReturnChartData(
    'readHistoricalReturn3YChartData',
    '3Y'
  );
  const readHistoricalReturn5YChartData = useReadHistoricalReturnChartData(
    'readHistoricalReturn5YChartData',
    '5Y'
  );
  const readHistoricalReturn10YChartData = useReadHistoricalReturnChartData(
    'readHistoricalReturn10YChartData',
    '10Y'
  );
  const readProductChooserRecommendation =
    useReadProductChooserRecommendation();

  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const language = loadPageLanguage()
    ? loadPageLanguage()
    : supportedLanguages.default;
  const { kyc, clientInformation } = clientForm.getState().values;
  const roboAdviceForm = useRoboAdviceForm.getState();
  const clientType = roboAdviceForm.values?.clientInformation?.clientType;
  const kycData = useMapKycData({
    kyc,
    kycFieldsConfig: roboAdvice.kyc,
    language: language ?? '',
    clientType,
    i18n
  });
  const automaticClientClassification = getAutomaticClientClassification({
    kyc,
    automaticClientClassificationConfig:
      roboAdvice.clientInformation?.automaticClientClassification,
    kycConfig: roboAdvice.kyc,
    clientType,
    i18n
  });

  const downloadPdfReport = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    let proposalPageStore = useProposalPageStore.getState();
    try {
      const generateReportFromValues = R.prop(
        'values',
        generateReportForm.getState()
      );

      proposalPageStore.setIsGenerateReportPopupOpen(false);

      proposalPageStore.setIsReadPdfReportPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      if (!generateReportFromValues.report.includeReport) {
        const portfolioChartStoreState = usePortfolioChartStore.getState();

        const maxRiskScore = getMaxRiskClass(useAdvisoryPageStore);

        const pdfData = mapAttachmentsData({
          i18n,
          userName,
          roboAdviceForm,
          generateReportFromValues,
          maxRiskScore,
          portfolioChartStoreState,
          goals,
          roboAdvice,
          kycData,
          contacts: clientInformation?.contacts,
          isContactListEnabled,
          isSignatureRightsEnabled,
          isUBOEnabled,
          language: language ?? ''
        });

        const response = await createPdfReport(
          accessToken,
          cancelTokenSource.token,
          customerId,
          {
            shouldGenerateReport: false,
            ...pdfData
          },
          adviceSessionId,
          clientId
        );
        saveFile(
          new Blob([response.data], { type: 'application/pdf; charset=utf-8' }),
          'Report.pdf'
        );
      } else {
        await Promise.all([
          proposalPageStore.pageStatuses.readPortfolioChartData !==
          PageStatuses.succeed
            ? readPortfolioChartData()
            : null,
          proposalPageStore.pageStatuses.readCostChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isCostHidden &&
          generateReportFromValues.analyticsComponents.costValue
            ? readCostChartData()
            : null,
          proposalPageStore.pageStatuses.readHistoricalReturn3MChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isHistoricalReturnHidden &&
          generateReportFromValues.analyticsComponents.historicalReturn
            ? readHistoricalReturn3MChartData()
            : null,
          proposalPageStore.pageStatuses.readHistoricalReturnYTDChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isHistoricalReturnHidden &&
          generateReportFromValues.analyticsComponents.historicalReturn
            ? readHistoricalReturnYTDChartData()
            : null,
          proposalPageStore.pageStatuses.readHistoricalReturn1YChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isHistoricalReturnHidden &&
          generateReportFromValues.analyticsComponents.historicalReturn
            ? readHistoricalReturn1YChartData()
            : null,
          proposalPageStore.pageStatuses.readHistoricalReturn3YChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isHistoricalReturnHidden &&
          generateReportFromValues.analyticsComponents.historicalReturn
            ? readHistoricalReturn3YChartData()
            : null,
          proposalPageStore.pageStatuses.readHistoricalReturn5YChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isHistoricalReturnHidden &&
          generateReportFromValues.analyticsComponents.historicalReturn
            ? readHistoricalReturn5YChartData()
            : null,
          proposalPageStore.pageStatuses.readHistoricalReturn10YChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isHistoricalReturnHidden &&
          generateReportFromValues.analyticsComponents.historicalReturn
            ? readHistoricalReturn10YChartData()
            : null,
          proposalPageStore.pageStatuses.readOrderSummary !==
          PageStatuses.succeed
            ? readOrderSummary()
            : null,
          proposalPageStore.pageStatuses.readSustainability !==
            PageStatuses.succeed &&
          !analyticsComponents.isSustainabilityHidden &&
          generateReportFromValues.analyticsComponents.sustainability
            ? readSustainability()
            : null,
          proposalPageStore.pageStatuses.readCashflowData !==
            PageStatuses.succeed &&
          !analyticsComponents.isCashflowHidden &&
          generateReportFromValues.analyticsComponents.cashflow
            ? readCashflowData()
            : null,
          proposalPageStore.pageStatuses.readExpectedPathChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isExpectedPathHidden &&
          generateReportFromValues.analyticsComponents.expectedPath
            ? readExpectedPathChartData()
            : null,
          proposalPageStore.pageStatuses.readExpectedValueChartData !==
            PageStatuses.succeed &&
          !analyticsComponents.isExpectedValueHidden &&
          generateReportFromValues.analyticsComponents.expectedValue
            ? readExpectedValueChartData()
            : null
        ]);

        let instrumentsOrderList: BuyOrder[];
        if (
          roboAdvice.investmentAdvice.enableProductDocuments &&
          generateReportFromValues.productAttachments
        ) {
          const { orderSummaryData } = useOrderSummaryStore.getState();

          // Array.from(new Set()) is used to remove duplicates from the array
          instrumentsOrderList = Array.from(
            new Set(
              orderSummaryData.reduce((acc, currentGoal) => {
                return acc
                  .concat(currentGoal.data.monthlyOrders.buy)
                  .concat(currentGoal.data.oneTimeOrders.buy);
              }, [] as BuyOrder[])
            )
          );
        }

        proposalPageStore = useProposalPageStore.getState();
        if (
          Object.values(proposalPageStore.pageStatuses).some(
            pageStatus => pageStatus === PageStatuses.failed
          )
        ) {
          proposalPageStore.setIsReadPdfReportPending(false);
          return;
        }
        const portfolioChartStoreState = usePortfolioChartStore.getState();
        const costGoalsData = useCostChartStore.getState().goalsData;
        const {
          summary,
          chartData: costChartData,
          firstYearMerged,
          lastYearMerged,
          fundDetails
        } = prepareCostChartData({ selectedGoalsData: costGoalsData, i18n });
        const costTableData = {
          firstYear: firstYearMerged,
          fundDetails,
          lastYear: lastYearMerged,
          summary
        };
        const { sustainabilityData } = useSustainabilityStore.getState();
        const { orderSummaryData } = useOrderSummaryStore.getState();
        const orderSummaryProductAttributes =
          getProductAttributesPDF(orderSummary);
        const { values: costFormValues } = costForm.getState();
        useCashflowChartStore.getState().setTableData();
        const cashflowChartStore = useCashflowChartStore.getState();
        useExpectedPathChartStore.getState().setChartData();
        const { chartData: expectedPathChartData } =
          useExpectedPathChartStore.getState();
        useExpectedValueChartStore.getState().setChartData();
        const { chartData: expectedValueChartData } =
          useExpectedValueChartStore.getState();
        const historicalReturn = getPdfReportHistoricalReturn({
          i18n
        }) as HistoricalReturn;
        const maxRiskScore = getMaxRiskClass(useAdvisoryPageStore);
        const { categoriesSelection: globalCategoriesSelection } =
          useAdvisoryPageStore.getState();
        let modelPortfolios: Record<string, FlatAssetClass[]> = {};

        let universeDocuments: UniverseDocumentFormat[] = [];

        for (const goal of goals) {
          if (
            roboAdvice.investmentAdvice.enableProductDocuments &&
            generateReportFromValues.productAttachments
          ) {
            const { data } = await readAnalyzeUniverseDocuments(
              accessToken,
              cancelTokenSource.token,
              { namespace_id: goal.data.productPlatformNamespace }
            );

            const goalUniverseDocuments = data
              .filter(document =>
                instrumentsOrderList.some(
                  ({ id, isin }) =>
                    (isin && document.isin === isin) ||
                    (id && document.ticker === id)
                )
              )
              .map(({ documents }) => documents[language ?? ''])
              .filter(Boolean);

            universeDocuments.push(...goalUniverseDocuments);
          }

          const goalCategoriesSelection =
            useAdvisoryPageStore.getState().goalCategoriesSelection?.[
              goal.goalId
            ];
          const categoriesSelection =
            goalCategoriesSelection || globalCategoriesSelection;
          if (!goal.data.isPortfolioCustom) {
            const modelRoboPortfolio = await getRoboPortfolio(
              accessToken,
              cancelTokenSource.token,
              {
                risk_tolerance: goal.data.portfolio,
                precision: roboPortfolioPrecision,
                namespace_id: goal.data.productPlatformNamespace
              }
            );
            const fundsList = await getFundsList(
              accessToken,
              cancelTokenSource.token,
              {
                namespace_id: goal.data.productPlatformNamespace,
                language
              }
            );
            const availableInstruments = fundsList.data.funds.map(f => ({
              id: f.Ticker,
              isin: f.ISIN,
              title: f.Name
            }));
            const groupedModelRoboPortfolio = groupAssetClasses(
              modelRoboPortfolio.data.Portfolio
            );

            const assetClasses = groupedModelRoboPortfolio.map(
              ({ AssetClass, CategoryIds, Weight }) => {
                const assetClassCategoriesSelection = Object.entries(
                  categoriesSelection
                )
                  .filter(([categoryId]) =>
                    CategoryIds.find(c => c.id === categoryId)
                  )
                  .map(([categoryId, instrumentId]) => ({
                    categoryId,
                    instrumentId,
                    weight:
                      CategoryIds.find(c => c.id === categoryId)?.weight ?? 0
                  }));

                const instruments = assetClassCategoriesSelection
                  .map(categorySelection => {
                    const foundInstrument = availableInstruments.find(
                      availableInstrument =>
                        availableInstrument.id ===
                        categorySelection.instrumentId
                    );

                    if (!foundInstrument) return null;

                    return {
                      ...foundInstrument,
                      weight: categorySelection.weight
                    };
                  })
                  .filter(filterNil);

                return {
                  CategoryId: AssetClass.CategoryId,
                  CategoryIds,
                  mainAssetClass: AssetClass.MainAssetClass,
                  subAssetClass: AssetClass.SubAssetClass,
                  id: AssetClass.SubAssetClass,
                  title: AssetClass.SubAssetClass,
                  instruments,
                  weight: Weight || 0
                };
              }
            );

            modelPortfolios[goal.goalId] = assetClasses;
          }
        }

        //remove duplicate documents before sending to pdf
        universeDocuments = Array.from(new Set(universeDocuments));

        const cashflowGoals = goals.map(({ goalId, name, data }) => ({
          goalId,
          name,
          frequency: i18n(WithdrawalPlansClassToTitle[data.withdrawalPlan!]),
          withdrawal: getCashflowWithdrawal(data, cultureCode),
          year: getCashflowYear(data, timeHorizonConfig.type),
          capitalNeed: cashflowChartStore.goalsData.find(
            g => g.goalId === goalId
          )?.capitalNeed
        }));
        const cashflowGoalChartsData =
          cashflowChartStore.cashflowGoalChartsData;
        const cashflowForecastChartData =
          cashflowChartStore.cashflowForecastChartData;
        const cashflowTableData = cashflowChartStore.tableData;
        const productPlatformNamespaces = Object.values<NamespaceConfig>(
          roboAdvice.productChooserRecommandation.namespaceConfig!
        );
        const productPlatformConfig = goals.map(({ goalId }) => ({
          ...readProductChooserRecommendation({ goalId }),
          goalId
        }));

        const sourceParams = mapClientUserDataToReport({
          i18n,
          adviceInformationFields,
          cultureCode,
          userName,
          roboAdviceForm,
          roboAdvice,
          generateReportFromValues,
          maxRiskScore,
          goals,
          timeHorizonConfig,
          knowledgeAndExperienceQuestions,
          financialSituation,
          theme,
          cashflowGoals,
          cashflowGoalChartsData,
          cashflowForecastChartData,
          cashflowTableData,
          cost: {
            chartData: costChartData,
            tableData: costTableData,
            overrideFundOngoingFeesAdvisorNotes:
              costFormValues.overrideFundOngoingFeesAdvisorNotes,
            overrideFundOneTimeFeesAdvisorNotes:
              costFormValues.overrideFundOneTimeFeesAdvisorNotes,
            overrideSummaryAnnualOngoingFeesAdvisorNotes:
              costFormValues.overrideSummaryAnnualOngoingFeesAdvisorNotes,
            overrideSummaryCustodyFeesAdvisorNotes:
              costFormValues.overrideSummaryCustodyFeesAdvisorNotes,
            overrideSummaryOneTimeFeesAdvisorNotes:
              costFormValues.overrideSummaryOneTimeFeesAdvisorNotes
          },
          expectedPathChartData,
          expectedValueChartData,
          portfolioChartStoreState,
          historicalReturn,
          orderSummaryData,
          orderSummaryProductAttributes,
          sustainabilityData,
          sustainabilityTableType,
          kycData,
          universeDocuments,
          automaticClientClassification,
          contacts: clientInformation?.contacts,
          language,
          productPlatformNamespaces,
          isContactListEnabled,
          isSignatureRightsEnabled,
          isUBOEnabled,
          isCustomPortfolioAssetClassAllocationAdvisorNotesEnabled,
          isCustomPortfolioFundAllocationAdvisorNotesEnabled,
          advancedSuitabilityConfig,
          productPlatformConfig,
          advancedSuitabilityStoreConfig,
          expectedValueConfig: analyticsComponents.expectedValue
        });

        const response = await createPdfReport(
          accessToken,
          cancelTokenSource.token,
          customerId,
          sourceParams,
          adviceSessionId,
          clientId
        );

        saveFile(
          new Blob([response.data], { type: 'application/pdf; charset=utf-8' }),
          'Report.pdf'
        );

        const missingDocuments: MissingDocuments =
          response.headers['missing-docs'] !== 'None'
            ? JSON.parse(response.headers['missing-docs'])
            : [];

        if (missingDocuments.length) {
          missingDocuments.forEach(({ isin, document_name }) =>
            dispatch(
              notificationActionCreators.showNotification({
                message: i18n(
                  'roboAdvice.proposal.generateReport.missingDocumentsErrorMessage'
                )
                  .replace('{0}', document_name)
                  .replace('{1}', isin),
                type: NotificationTypes.error
              })
            )
          );
        }

        cashflowChartStore.setTableData(selectedGoalsIds);
        await readReportDocuments(adviceSessionId, true);

        const payload = {
          roboAdviceForm: roboAdviceForm.values,
          goals: populateGoalsWithAdvancedSuitabilityStatusData(
            goals,
            advancedSuitabilityStatus,
            modelPortfolios
          )
        };
        await queuedPatchAdviceSession({
          id: v4(),
          type: SessionPatchTypes.saveFinishedSessionData,
          adviceSessionId,
          payload,
          namespace: SessionNamespaces.finishedSessionData
        });

        proposalPageStore.setFinishedPortfolioData(payload);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.proposal.generateReport.getPdfReportErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      proposalPageStore.setIsReadPdfReportPending(false);
    }
  };

  return downloadPdfReport;
}
