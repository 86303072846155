import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  usePageLanguage,
  getUserPageLanguage
} from '../../../../pageLanguage/main/components/usePageLanguage';
import { useForm } from '../../form/services/form';
import { getRiskHorizonValue } from '../../proposal/services/selectors';
import {
  EducationWorkSettingsClassToTitle,
  ExperienceSettingsClassToTitle
} from '../../shared/constants';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { riskScoreToNumber } from './shared.js';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { globalizeSelectors } from 'framework/globalize/utils/index.js';

const selectors = globalizeSelectors(['roboAdvice_state', 'riskScore_state'], {
  getRiskScore: state =>
    !R.isNil(state.riskScore) ? riskScoreToNumber(state.riskScore) : null,
  getRiskHorizon: R.path(['purposeAndRiskScoreSettings', 'riskHorizon']),
  getDropInStd: R.path(['knowledgeAndExperienceScoreSettings', 'dropInStd']),
  getDropInPercent: R.path([
    'knowledgeAndExperienceScoreSettings',
    'dropInPercent'
  ]),
  getExperience: R.path(['knowledgeAndExperienceScoreSettings', 'experience']),
  getRiskScoreSettings: R.prop('riskScoreSettings'),
  getOriginalRiskScore: state => state.riskScore
});

export default selectors;

export const useHorizonRiskScoreColumns = () => {
  const riskHorizon = useSelector(selectors.getRiskHorizon);
  const riskHorizonItems = R.path([0, 'items'], riskHorizon);
  const { timeHorizonConfig } = useCustomerConfig();
  const language = getUserPageLanguage();

  if (R.isNil(riskHorizonItems)) {
    return [];
  }

  return riskHorizonItems.map(
    i =>
      timeHorizonConfig.riskLevelLabels[`risk${i.riskClass}`]?.label[language]
  );
};

export const useHorizonRiskScoreItems = () => {
  const riskHorizon = useSelector(selectors.getRiskHorizon);
  const adviceSessionFormState = useForm();
  const { timeHorizonConfig } = useCustomerConfig();
  const { goals } = useGoalsStore();

  return getHorizonRiskScoreItemsResult({
    riskHorizon,
    adviceSessionFormState,
    timeHorizonConfig,
    goals
  });
};

export const getHorizonRiskScoreItemsResult = ({
  riskHorizon,
  adviceSessionFormState,
  timeHorizonConfig,
  goals
}) => {
  const expectationOfRisk = R.prop(
    'expectationOfRisk',
    adviceSessionFormState.values
  );
  const riskStrategy = R.prop('riskStrategy', adviceSessionFormState.values);
  const language = getUserPageLanguage();

  return !R.isNil(riskHorizon)
    ? R.map(
        riskHorizonItem => ({
          title: timeHorizonConfig.items.find(
            ({ riskHorizonValue }) =>
              riskHorizonValue === riskHorizonItem.timeHorizonClass
          )?.label[language],
          values: R.map(
            i => ({
              value: i.score,
              selectedGoalsIndexes: goals.reduce((acc, current, index) => {
                return riskHorizonItem.timeHorizonClass ===
                  getRiskHorizonValue(
                    current.data.timeHorizon,
                    timeHorizonConfig.type,
                    timeHorizonConfig.items
                  ) &&
                  (expectationOfRisk === i.riskClass ||
                    riskStrategy === i.riskClass)
                  ? [...acc, index]
                  : acc;
              }, [])
            }),
            riskHorizonItem.items
          )
        }),
        riskHorizon
      )
    : [];
};

export const getReactionIfMarketFailsScoreColumns = createSelector(
  state => selectors.getDropInStd(state),
  dropInStd => {
    const dropInStdItems = R.path([0, 'items'], dropInStd);

    if (R.isNil(dropInStdItems)) {
      return [];
    }

    return R.map(R.prop('riskClass'), dropInStdItems);
  }
);

export const useReactionIfMarketFailsScoreItems = () => {
  const dropInStd = useSelector(selectors.getDropInStd);
  const { goals } = useGoalsStore();
  const adviceSessionFormState = useForm();
  const {
    roboAdviceForm: {
      knowledgeAndExperienceQuestions: { questions }
    }
  } = useCustomerConfig();
  const i18n = useI18n();
  const { supportedLanguages } = useCustomerConfig();
  const { loadPageLanguage } = usePageLanguage();

  const dropInStdOptions =
    questions.find(({ name }) => name === 'dropInStd')?.options || [];

  return getReactionIfMarketFailsScoreItemsResult({
    dropInStd,
    goals,
    adviceSessionFormState,
    dropInStdOptions,
    i18n,
    language: loadPageLanguage()
      ? loadPageLanguage()
      : supportedLanguages.default
  });
};

export const getReactionIfMarketFailsScoreItemsResult = ({
  dropInStd,
  goals,
  adviceSessionFormState,
  dropInStdOptions,
  i18n,
  language
}) => {
  const dropInStdValue = R.prop('dropInStd', adviceSessionFormState.values);

  return R.isNil(dropInStd)
    ? []
    : R.map(
        dropInStdItem => ({
          title: getTranslationText(
            dropInStdOptions.find(({ id }) => id === dropInStdItem.dropClass),
            i18n,
            language
          ),
          values: R.map(
            i => ({
              value: i.score,
              selectedGoalsIndexes: goals.reduce((acc, current, index) => {
                return dropInStdItem.dropClass === dropInStdValue &&
                  riskScoreToNumber(current.data.purposeAndRiskScore) ===
                    i.riskClass
                  ? [...acc, index]
                  : acc;
              }, [])
            }),
            dropInStdItem.items
          )
        }),
        dropInStd
      );
};

export const getAbilityToBearLossesScoreColumns = createSelector(
  state => selectors.getDropInPercent(state),
  dropInPercent => {
    const dropInPercentItems = R.path([0, 'items'], dropInPercent);

    if (R.isNil(dropInPercentItems)) {
      return [];
    }

    return R.map(R.prop('riskClass'), dropInPercentItems);
  }
);

export const useAbilityToBearLossesScoreItems = () => {
  const dropInPercent = useSelector(selectors.getDropInPercent);
  const { goals } = useGoalsStore();
  const adviceSessionFormState = useForm();
  const {
    roboAdviceForm: {
      knowledgeAndExperienceQuestions: { questions }
    }
  } = useCustomerConfig();
  const i18n = useI18n();
  const { supportedLanguages } = useCustomerConfig();
  const { loadPageLanguage } = usePageLanguage();

  const dropInPercentOptions =
    questions.find(({ name }) => name === 'dropInPercent')?.options || [];

  return getAbilityToBearLossesScoreItemsResult({
    dropInPercent,
    goals,
    adviceSessionFormState,
    dropInPercentOptions,
    i18n,
    language: loadPageLanguage()
      ? loadPageLanguage()
      : supportedLanguages.default
  });
};

export const getAbilityToBearLossesScoreItemsResult = ({
  dropInPercent,
  goals,
  adviceSessionFormState,
  dropInPercentOptions,
  i18n,
  language
}) => {
  const dropInPercentValue = R.prop(
    'dropInPercent',
    adviceSessionFormState.values
  );

  return R.isNil(dropInPercent)
    ? []
    : R.map(
        dropInPercentItem => ({
          title: getTranslationText(
            dropInPercentOptions.find(
              ({ id }) => id === dropInPercentItem.dropClass
            ),
            i18n,
            language
          ),
          values: R.map(
            i => ({
              value: i.score,
              selectedGoalsIndexes: goals.reduce((acc, current, index) => {
                return dropInPercentItem.dropClass === dropInPercentValue &&
                  riskScoreToNumber(current.data.purposeAndRiskScore) ===
                    i.riskClass
                  ? [...acc, index]
                  : acc;
              }, [])
            }),
            dropInPercentItem.items
          )
        }),
        dropInPercent
      );
};

export const getExperienceScoreColumns = createSelector(
  (state, { i18n }) => i18n,
  state => selectors.getExperience(state),
  (i18n, experience) => {
    const experienceItems = R.path([0, 'items'], experience);

    if (R.isNil(experienceItems)) {
      return [];
    }

    return R.map(
      i => i18n(EducationWorkSettingsClassToTitle[i.educationClass]),
      experienceItems
    );
  }
);

export const useExperienceScoreItems = () => {
  const i18n = useI18n();

  const experience = useSelector(selectors.getExperience);
  const adviceSessionFormState = useForm();
  const { goals } = useGoalsStore();

  return getExperienceScoreItemsResult({
    i18n,
    experience,
    adviceSessionFormState,
    goals
  });
};

export const getExperienceScoreItemsResult = ({
  i18n,
  experience,
  adviceSessionFormState,
  goals
}) => {
  const experienceValue = R.prop('experience', adviceSessionFormState.values);
  const educationWorkValue = R.prop(
    'educationWork',
    adviceSessionFormState.values
  );

  return R.isNil(experience)
    ? []
    : R.map(
        experienceItem => ({
          title: i18n(
            ExperienceSettingsClassToTitle[experienceItem.experienceClass]
          ),
          values: R.map(
            i => ({
              value: i.score,
              selectedGoalsIndexes:
                experienceItem.experienceClass === experienceValue &&
                i.educationClass === educationWorkValue
                  ? goals.map((_, index) => index)
                  : []
            }),
            experienceItem.items
          )
        }),
        experience
      );
};

export const getRiskClasses = createSelector(
  state => selectors.getRiskScoreSettings(state),
  riskScoreSettings => {
    const riskClasses = R.path(
      ['assetClasses', 0, 'riskClasses'],
      riskScoreSettings
    );

    if (R.isNil(riskClasses)) {
      return [];
    }

    return R.map(R.prop('riskClass'), riskClasses);
  }
);

export const useRiskScoreItems = () => {
  const riskScoreSettings = useSelector(selectors.getRiskScoreSettings);
  const { goals } = useGoalsStore();

  return getRiskScoreItems({ goals, riskScoreSettings });
};

export const getRiskScoreItems = ({ goals, riskScoreSettings }) => {
  const assetClassGroups = R.prop('assetClassGroups', riskScoreSettings);
  const assetClasses = R.prop('assetClasses', riskScoreSettings);

  if (R.isNil(assetClassGroups) || R.isNil(assetClasses)) {
    return [];
  }

  return R.map(
    g => ({
      id: g.id,
      title: g.title,
      assetClasses: R.pipe(
        R.filter(ac => ac.assetClassGroupId === g.id),
        R.map(ac => ({
          id: ac.id,
          title: ac.title,
          category: ac.category,
          values: R.map(
            rc => ({
              value: rc.weight,
              selectedGoalsIndexes: goals.reduce((acc, current, index) => {
                return riskScoreToNumber(current.riskScore) === rc.riskClass
                  ? [...acc, index]
                  : acc;
              }, [])
            }),
            ac.riskClasses
          )
        }))
      )(assetClasses)
    }),
    assetClassGroups
  );
};

export const getIsReadRiskScoreRequired = field => {
  return R.includes(field, [
    'expectationOfRisk',
    'riskStrategy',
    'timeHorizon',
    'dropInStd',
    'dropInPercent',
    'educationWork',
    'experience'
  ]);
};

const getTranslationText = (option, i18n, language) => {
  if (option?.translationKey) {
    return i18n(option.translationKey);
  } else if (option?.translations) {
    return option.translations[language];
  }
  return '';
};
