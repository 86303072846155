import { sub, startOfYear } from 'date-fns';
import * as R from 'ramda';

import {
  useAmountForAdviceAssetsWithValue,
  useFinancialSituationValues
} from '../../financialSituation/services/selectors';
import { useForm as useRoboAdviceForm } from '../../form/services/form';
import { useHistoricalReturnChartStore } from '../../historicalReturnChart';
import { TimeHorizonTypes } from '../../purposeAndRisk/constants';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { getColor } from 'features/shared/utils/colors';
import { roundNumber } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useTheme } from 'features/sharedModules/styles/components/styles';

const getExistingPortfolioData = (
  rawExistingPortfolioData,
  theme,
  isPercentValue
) => {
  const totalValueToAdvisory = rawExistingPortfolioData.reduce(
    (acc, curr) =>
      isPercentValue
        ? acc + (curr.toAdvisory / 100) * curr.value
        : acc + curr.toAdvisory,
    0
  );

  const equity = rawExistingPortfolioData.reduce(
    (acc, curr) =>
      isPercentValue
        ? acc +
          (curr.assetClass.EquityShare * curr.toAdvisory * curr.value) / 100
        : acc + curr.assetClass.EquityShare * curr.toAdvisory,
    0
  );

  const equityWeight = (equity / totalValueToAdvisory) * 100;

  const assetAllocationChartData = rawExistingPortfolioData
    .reduce((acc, curr) => {
      const index = acc.findIndex(
        ({ id }) => id === curr.assetClass.SubAssetClass
      );
      return index === -1
        ? [
            ...acc,
            {
              id: curr.assetClass.SubAssetClass,
              name: curr.assetClass.SubAssetClass,
              weight: isPercentValue
                ? (((curr.toAdvisory / 100) * curr.value) /
                    totalValueToAdvisory) *
                  100
                : (curr.toAdvisory / totalValueToAdvisory) * 100
            }
          ]
        : acc.map((item, i) =>
            i === index
              ? {
                  ...item,
                  weight:
                    item.weight +
                    (isPercentValue
                      ? (((curr.toAdvisory / 100) * curr.value) /
                          totalValueToAdvisory) *
                        100
                      : (curr.toAdvisory / totalValueToAdvisory) * 100)
                }
              : item
          );
    }, [])
    .filter(({ weight }) => weight)
    .map((item, index, arr) => ({
      ...item,
      color: getColor(theme.chartPortfolioColors, index, arr.length),
      weight: roundNumber(item.weight, 3)
    }));

  return [
    [
      {
        color: theme.chartPortfolioColors[0],
        id: 'fixedIncome',
        name: 'Fixed Income',
        weight: 100 - equityWeight
      },
      {
        color: theme.chartPortfolioColors[1],
        id: 'equity',
        name: 'Equity',
        weight: equityWeight
      }
    ].filter(({ weight }) => weight),
    assetAllocationChartData
  ];
};

export const useExistingPortfolio = () => {
  const amountForAdviceAssetsWithValue = useAmountForAdviceAssetsWithValue();
  const financialSituationValues = useFinancialSituationValues();
  const theme = useTheme();
  const {
    advisoryComponents: { existingPortfolioComparison }
  } = useCustomerConfig();

  if (!existingPortfolioComparison) {
    return null;
  }

  const rawExistingPortfolioData = amountForAdviceAssetsWithValue
    .map(({ id }) => financialSituationValues[id] || [])
    .map(values =>
      values.filter(({ assetClass, value }) => assetClass && value)
    )
    .flat();

  if (!rawExistingPortfolioData.length) {
    return null;
  }

  return getExistingPortfolioData(
    rawExistingPortfolioData,
    theme,
    financialSituationValues.amountForAdviceSwitch
  );
};

export const useIsFormComplete = (hasSavingsPlanDependency = true) => {
  const roboAdviceFormState = useRoboAdviceForm();

  return getIsFormCompleteResult({
    roboAdviceFormState,
    hasSavingsPlanDependency
  });
};

export const getIsFormComplete = (hasSavingsPlanDependency = true) => {
  const roboAdviceFormState = useRoboAdviceForm.getState();

  return getIsFormCompleteResult({
    roboAdviceFormState,
    hasSavingsPlanDependency
  });
};

export const getIsFormCompleteResult = ({
  roboAdviceFormState,
  hasSavingsPlanDependency
}) => {
  const { errors } = roboAdviceFormState;

  return !hasSavingsPlanDependency || !errors.savingsPlan;
};

export const getMaxRiskClass = useAdvisoryPageStore => {
  return getMaxRiskClassInternal(useAdvisoryPageStore.getState());
};

// TODO: cover by tests
export const getMaxRiskClassInternal = advisoryPageStoreState => {
  const { portfolios } = advisoryPageStoreState;

  return R.pipe(R.last, R.prop('riskScore'))(portfolios);
};

// TODO: cover by tests
export const getHorizonInYears = ({ customerConfig, timeHorizon }) => {
  return customerConfig.timeHorizonConfig.type === TimeHorizonTypes.slider
    ? timeHorizon
    : customerConfig.timeHorizonConfig.items.find(
        c => c.riskHorizonValue === timeHorizon
      )?.analysisProjectionYearsValue;
};

// TODO: cover by tests
export const getHorizonYearsDisplayValue = ({
  timeHorizonConfig,
  timeHorizon,
  i18n
}) => {
  return timeHorizonConfig.type === TimeHorizonTypes.slider
    ? i18n('shared.yearsTemplate').replace('{0}', timeHorizon)
    : timeHorizonConfig.items.find(
        ({ riskHorizonValue }) => riskHorizonValue === timeHorizon
      )?.label[getUserPageLanguage()];
};

export const getRiskHorizonValue = (timeHorizon, type, items) => {
  if (type === TimeHorizonTypes.radio) {
    return timeHorizon;
  }

  const sortedItems = [...items];
  sortedItems.sort((a, b) => a.yearIntervalStop - b.yearIntervalStop);

  return sortedItems.find(
    ({ yearIntervalStop }) => timeHorizon <= yearIntervalStop
  )?.riskHorizonValue;
};

export const getHorizonAnalysisProjectionYearsValue = ({
  timeHorizonConfig,
  timeHorizon
}) => {
  if (timeHorizonConfig.type === TimeHorizonTypes.slider) {
    const sortedItems = [...timeHorizonConfig.items];
    sortedItems.sort((a, b) => a.yearIntervalStop - b.yearIntervalStop);

    return sortedItems.find(
      ({ yearIntervalStop }) => timeHorizon <= yearIntervalStop
    )?.analysisProjectionYearsValue;
  }

  return timeHorizonConfig.items.find(
    ({ riskHorizonValue }) => riskHorizonValue === timeHorizon
  )?.analysisProjectionYearsValue;
};

export const getPdfReportHistoricalReturn = ({ i18n }) => {
  const currentDate = new Date();
  const historicalReturnChartStore = useHistoricalReturnChartStore.getState();

  return getPdfReportHistoricalReturnResult({
    i18n,
    currentDate,
    historicalReturnChartStore
  });
};

export const getPdfReportHistoricalReturnResult = ({
  i18n,
  currentDate,
  historicalReturnChartStore
}) => {
  const timeRangeChartDataKeys = R.keys(historicalReturnChartStore.chartData);
  const sortedTimeRangeChartDataKeys = R.pipe(
    R.map(key => [key, getLookbackStartDate(key, currentDate)]),
    R.sort(R.ascend(i => i[1])),
    R.map(i => i[0])
  )(timeRangeChartDataKeys);
  const selectedTimeRangeChartDataKey = R.find(
    key => !R.isNil(historicalReturnChartStore.chartData[key]),
    sortedTimeRangeChartDataKeys
  );
  const keyStatistics = Object.entries(
    historicalReturnChartStore.chartData
  ).reduce((prev, [key, value]) => {
    prev[key] = value
      ? value.map(v => ({
          goalId: v.goalId,
          growth: v.statistics.growth
        }))
      : [];

    return prev;
  }, {});

  return R.isNil(selectedTimeRangeChartDataKey)
    ? {
        chartData: undefined,
        chartDescription: undefined,
        keyStatistics: undefined
      }
    : {
        chartData:
          historicalReturnChartStore.chartData[selectedTimeRangeChartDataKey],
        chartDescription: getChartDescription(
          selectedTimeRangeChartDataKey,
          i18n
        ),
        keyStatistics
      };
};

const getChartDescription = (timeRangeChartDataKey, i18n) => {
  switch (timeRangeChartDataKey) {
    case '3M':
      return i18n('historicalReturn.graphDescription').replace(
        '{0}',
        i18n('historicalReturn.graphDescription.overLastMonths').replace(
          '{0}',
          Number(timeRangeChartDataKey.replace('M', ''))
        )
      );
    case 'YTD':
      return i18n('historicalReturn.graphDescription').replace(
        '{0}',
        i18n('historicalReturn.graphDescription.yearToDate')
      );
    case '1Y':
    case '3Y':
    case '5Y':
    case '10Y':
      return i18n('historicalReturn.graphDescription').replace(
        '{0}',
        i18n('historicalReturn.graphDescription.overLastYears').replace(
          '{0}',
          Number(timeRangeChartDataKey.replace('Y', ''))
        )
      );
    default:
      throw Error(
        `Time range chart data key ${String(
          timeRangeChartDataKey
        )} is not supported.`
      );
  }
};

export const getLookbackStartDate = (timeRangeChartDataKey, currentDate) => {
  switch (timeRangeChartDataKey) {
    case '3M':
      return sub(currentDate, { months: 3 });
    case 'YTD':
      return startOfYear(currentDate);
    case '1Y':
      return sub(currentDate, { years: 1 });
    case '3Y':
      return sub(currentDate, { years: 3 });
    case '5Y':
      return sub(currentDate, { years: 5 });
    case '10Y':
      return sub(currentDate, { years: 10 });
    default:
      throw Error(
        `Time range chart data key ${String(
          timeRangeChartDataKey
        )} is not supported.`
      );
  }
};
