import { usePageStore as useProposalPageStore } from '../../../proposal/services/pageStore';
import RiskModel from '../riskModel';
import Themes from '../themes';
import { AdvisoryComponents } from 'defaults/defaultAdvisoryComponents';
import { useUpdateGoal } from 'features/roboAdvice/adviceSession/purposeAndRisk/components/useUpdateGoal';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import PortfolioChartCards, {
  PortfolioChartData,
  PortfolioChartsTabs
} from 'features/shared/analyticsComponents/portfolioChartCards/visual';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  section: {
    textAlign: 'left',

    '&:not(:last-child)': {
      marginBottom: Spacing.spacing04
    }
  },
  sectionHeader: {
    fontSize: Typography.heading1.size,
    fontWeight: FontWeights.medium,
    lineHeight: Typography.heading1.lineHeight,
    marginBottom: Spacing.spacing01
  },
  sectionSubheader: {
    color: theme.secondaryColor,
    lineHeight: Typography.body1.lineHeight,
    marginBottom: Spacing.spacing02
  },
  portfolioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

type Props = {
  advisoryComponents: AdvisoryComponents;
  portfolioCharts: PortfolioChartData[];
  selectedGoal: Goal;
};

const AssetClassAllocation = ({
  advisoryComponents,
  portfolioCharts,
  selectedGoal
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const proposalPageStore = useProposalPageStore();
  const updateGoal = useUpdateGoal();

  return (
    <>
      <div className={classes.section}>
        <div className={classes.sectionHeader}>
          {i18n('roboAdvice.advisory.modelPortfolio.riskModel')}
        </div>

        <RiskModel
          goalId={selectedGoal.goalId}
          onChange={v =>
            updateGoal(selectedGoal.goalId, { data: { portfolio: v } })
          }
          value={selectedGoal.data.portfolio}
        />
      </div>

      {!advisoryComponents.isThemesHidden && (
        <div className={classes.section}>
          <Themes
            goalId={selectedGoal.goalId}
            themes={selectedGoal.data.themes}
            sectionHeaderClass={classes.sectionHeader}
            sectionSubheaderClass={classes.sectionSubheader}
          />
        </div>
      )}

      {!advisoryComponents.isPortfolioPreviewHidden && (
        <div className={classes.section}>
          <div className={classes.portfolioContainer}>
            <PortfolioChartCards
              tabs={[
                PortfolioChartsTabs.broadAssetAllocation,
                PortfolioChartsTabs.assetAllocation
              ]}
              portfolioCharts={portfolioCharts}
              isLoading={
                proposalPageStore.pageStatuses.readPortfolioChartData ===
                PageStatuses.pending
              }
              isVertical
              alignTitleBottom={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AssetClassAllocation;
