import classNames from 'classnames';
import { isNil } from 'ramda';
import { useState, useMemo } from 'react';

import { useIsSessionReadOnly } from '../../main/services/selectors';
import ActionButtons from '../../shared/components/actionButtons';
import { useGoalsStore } from '../../shared/services/goalsStore';
import AllocatedAmount from './allocatedAmount';
import PortfolioInterface from './portfolioInterface';
import ProductPlatform from './productPlatform';
import SavingsPlan from './savingsPlan.js';
import { useReadProductChooserRecommendation } from './useReadProductChooserRecommendation';
import { RoboAdviceAdviceSessionPages } from 'features/roboAdvice/shared/constants';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import {
  carouselIds,
  useCarouselStore
} from 'features/shared/components/carousel/carouselStore';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import GoalTableIcon from 'features/shared/components/icon/goalTableIcon';
import { Tabs, Tab } from 'features/shared/components/tabs';
import { LayoutBreakpoints } from 'features/shared/constants/layoutBreakpoints';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography, FontWeights } from 'features/shared/constants/typography';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import ErrorsList from 'features/sharedModules/errors/components/errorsList';
import InputErrorMessage from 'features/sharedModules/errors/components/inputErrorMessage';
import NextButtonError from 'features/sharedModules/errors/components/nextButtonError';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import { useGetAllAdviceSessionErrors } from 'features/sharedModules/errors/services/useGetAllAdviceSessionErrors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  row: {
    display: 'flex',
    marginBottom: Spacing.spacing04,
    gap: Spacing.spacing02
  },
  boxSection: {
    backgroundColor: theme.boxBackgroundColor_1,
    border: `1px solid ${theme.boxBackgroundColor_2}`,
    padding: Spacing.spacing02,
    borderRadius: '10px'
  },
  smallBox: {
    width: 'min-content'
  },
  sectionHeader: {
    fontSize: Typography.heading1.size,
    lineHeight: Typography.heading1.lineHeight,
    fontWeight: FontWeights.medium,
    marginBottom: Spacing.spacing01,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tabs: {
    marginBottom: Spacing.spacing04
  },
  tab: {
    margin: '0 12px',
    padding: '10px 4px',
    fontSize: Typography.heading2.size,
    lineHeight: Typography.heading2.lineHeight,
    fill: theme.accentColor,
    minWidth: 'min-content'
  },
  tabLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: Spacing.spacing00
  },
  advisorNotes: {
    marginTop: Spacing.spacing04
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`
  },
  [`@media (max-width: ${LayoutBreakpoints.desktopMin}px)`]: {
    row: {
      flexDirection: 'column',
      gap: Spacing.spacing04
    }
  }
}));

const Page = () => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    roboAdvice: {
      productChooserRecommandation: {
        enabled: isProductChooserRecommandationEnabled,
        namespaceConfig
      }
    },
    roboAdviceForm: {
      financialSituation: { isAmountForAdviceEnabled },
      purposeAndRisk: { showGoalIcons }
    }
  } = useCustomerConfig();
  const isSessionReadOnly = useIsSessionReadOnly();
  const { goals } = useGoalsStore();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);

  const [currentTab, setCurrentTab] = useState(goals[0].goalId);
  const [activeProductPlatformTab, setActiveProductPlatformTab] = useState(1);
  const { setSelectedItemIndex } = useCarouselStore();
  const selectedGoal = useMemo(() => {
    return goals.find(g => g.goalId === currentTab);
  }, [currentTab, goals]);
  const readProductChooserRecommendation =
    useReadProductChooserRecommendation();
  const { questions } = readProductChooserRecommendation({
    goalId: selectedGoal.goalId
  });

  const allErrors = useGetAllAdviceSessionErrors();
  const errors = allErrors[RoboAdviceAdviceSessionPages.advisory];

  const namespaces = useMemo(() => {
    return Object.values(namespaceConfig);
  }, [namespaceConfig]);

  const availableProductPlatformQuestions = useMemo(() => {
    return Object.entries(
      selectedGoal.data.productPlatformQuestions || {}
    ).reduce((acc, [answeredQuestionName, answeredQuestionValue]) => {
      const foundConfigQuestion = questions.find(
        configQuestion =>
          configQuestion.name === answeredQuestionName && configQuestion.enabled
      );

      if (foundConfigQuestion) {
        acc[answeredQuestionName] = answeredQuestionValue;
      }

      return acc;
    }, {});
  }, [questions, selectedGoal.data.productPlatformQuestions]);

  const areAllProductPlatformQuestionsAnswered =
    Object.values(availableProductPlatformQuestions).length ===
      questions.length && !isNil(selectedGoal.data.productPlatformNamespace);

  const areSavingsPlanValuesSet =
    +selectedGoal.data.firstDeposit > 0 ||
    +selectedGoal.data.monthlyDeposit > 0;

  const isPortfolioInterfaceVisible =
    (!isProductChooserRecommandationEnabled ||
      areAllProductPlatformQuestionsAnswered) &&
    areSavingsPlanValuesSet;

  const handleChangeCurrentTab = goalId => {
    setCurrentTab(goalId);
    setSelectedItemIndex(carouselIds.modelPortfolio, 0);
  };

  return (
    <div
      role="tabpanel"
      aria-labelledby={`tab-id-${RoboAdviceAdviceSessionPages.advisory}`}
      id={`panel-id-${RoboAdviceAdviceSessionPages.advisory}`}
    >
      <ErrorsList
        pageName={i18n('roboAdvice.tabs.advisory')}
        errors={errors}
        setCurrentAdvisoryTab={handleChangeCurrentTab}
        setActiveProductPlatformTab={setActiveProductPlatformTab}
      />
      {currentTab && (
        <>
          <Tabs
            rootClassName={classes.tabs}
            value={currentTab}
            onChange={(_, newValue) => handleChangeCurrentTab(newValue)}
          >
            {goals.map(goal => (
              <Tab
                rootClassName={classes.tab}
                key={goal.goalId}
                value={goal.goalId}
                label={
                  <div className={classes.tabLabel}>
                    {showGoalIcons && (
                      <GoalTableIcon icon={goal.icon} name={goal.name} />
                    )}
                    {` ${goal.name}`}
                  </div>
                }
              />
            ))}
          </Tabs>
          {selectedGoal && (
            <>
              {errors?.savingsPlan.map(error => (
                <InputErrorMessage message={error.alertMessage} />
              ))}
              <div className={classes.row}>
                <div
                  className={classNames(classes.boxSection, classes.smallBox, {
                    [classes.hasError]:
                      errors?.savingsPlan?.length && isErrorModeEnabled
                  })}
                  id={'savings-plan'}
                >
                  <SavingsPlan
                    goalId={selectedGoal.goalId}
                    goals={goals}
                    firstDeposit={selectedGoal.data.firstDeposit}
                    monthlyDeposit={selectedGoal.data.monthlyDeposit}
                    internalHolding={selectedGoal.data.internalHolding}
                    sectionHeaderClassName={classes.sectionHeader}
                  />
                </div>
                {isAmountForAdviceEnabled && <AllocatedAmount />}
              </div>

              {isProductChooserRecommandationEnabled && namespaces.length > 1 && (
                <>
                  {errors?.productPlatformQuestions.map(error => (
                    <InputErrorMessage message={error.alertMessage} />
                  ))}
                  {errors?.productPlatform.map(error => (
                    <InputErrorMessage message={error.alertMessage} />
                  ))}
                  <div className={classes.row}>
                    <div
                      className={classNames(
                        classes.boxSection,
                        classes.smallBox,
                        {
                          [classes.hasError]:
                            errors?.productPlatformQuestions?.length &&
                            isErrorModeEnabled
                        }
                      )}
                      id={'product-chooser'}
                    >
                      <ProductPlatform
                        riskScore={selectedGoal.data?.riskScore}
                        goalId={selectedGoal.goalId}
                        namespace={selectedGoal.data.productPlatformNamespace}
                        reasoningForSelection={
                          selectedGoal.data.productPlatformReasoning
                        }
                        preferences={selectedGoal.data.productPlatformQuestions}
                        errors={errors}
                        activeProductPlatformTab={activeProductPlatformTab}
                        setActiveProductPlatformTab={
                          setActiveProductPlatformTab
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              {isPortfolioInterfaceVisible && (
                <>
                  {errors?.allocation
                    .filter(
                      error =>
                        error.data?.advisoryGoal === selectedGoal.goalId ||
                        isNil(error.data?.advisoryGoal)
                    )
                    .map(error => (
                      <InputErrorMessage message={error.alertMessage} />
                    ))}
                  <PortfolioInterface
                    selectedGoal={selectedGoal}
                    errors={errors}
                  />
                </>
              )}
            </>
          )}
        </>
      )}

      <AdvisorNotes
        className={classes.advisorNotes}
        name="advisoryAdvisorNotes"
        header={
          <ConfigurableLabel
            required
            label={i18n('roboAdvice.advisory.reasoningForAdvice')}
          />
        }
        subheader={i18n('roboAdvice.advisory.minimumCharacter')}
        customPlaceholder={i18n(
          'roboAdvice.advisory.reasoningForAdvice.placeholder'
        )}
        infoTooltipText={i18n(
          'roboAdvice.advisory.reasoningForAdvice.infoTooltip'
        )}
        disabled={isSessionReadOnly}
        elementId={'advisory-advisor-notes'}
        errors={errors?.advisorNotes}
      />
      <NextButtonError allErrors={allErrors} />
      <ActionButtons pageId={RoboAdviceAdviceSessionPages.advisory} />
    </div>
  );
};

export default Page;
