import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageStore as useAdvisoryPageStore } from '../../services/pageStore';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { createSustainability } from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import {
  SustainabilityData,
  useSustainabilityStore
} from 'features/roboAdvice/adviceSession/sustainability';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useReadSustainability = ({ goal }: { goal: Goal }) => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const portfolioChartStore = usePortfolioChartStore();

  const readSustainability = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const advisoryPageStore = useAdvisoryPageStore.getState();
    const sustainabilityStore = useSustainabilityStore.getState();

    const { sustainability: { alignmentCriteria, preferenceCriteria } = {} } =
      useRoboAdviceForm.getState().values;

    try {
      advisoryPageStore.setPageStatus(
        'readSustainabilityModelPortfolioData',
        PageStatuses.pending
      );

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      let sustainabilityData: SustainabilityData[] = [];

      const portfolio = portfolioChartStore.getChartData(
        goal.goalId,
        goal.data.isPortfolioCustom
      );

      if (!isNil(alignmentCriteria)) {
        const instruments = portfolio.categorySelectionPortfolio.map(
          ({ id, weight }) => ({ id, weight })
        );

        const payload = {
          portfolio: instruments,
          alignmentCriteria,
          preferenceCriteria: preferenceCriteria
            ? preferenceCriteria.reduce(
                (acc, current) => ({ ...acc, [current]: true }),
                {}
              )
            : {}
        };

        const sustainabilityResponse = await createSustainability(
          qAuthAccessToken,
          cancelTokenSource.token,
          payload,
          {
            namespace_id: goal.data.productPlatformNamespace,
            language: getUserPageLanguage()
          }
        );

        sustainabilityData = [
          {
            goalId: goal.goalId,
            data: sustainabilityResponse.data
          }
        ];
      } else {
        const dataWithoutSustainability = {
          fundAnalysis: portfolio.categorySelectionPortfolio.map(
            ({ id, isin, name }) => ({
              alignmentCriteria: {
                alignmentCriteria1: null,
                alignmentCriteria2: null,
                alignmentCriteria3: null,
                alignmentCriteria4: null,
                alignmentCriteria5: null
              },
              alignmentCriteriaStatus: {
                alignmentCriteria1: null,
                alignmentCriteria2: null,
                alignmentCriteria3: null,
                alignmentCriteria4: null,
                alignmentCriteria5: null
              },
              fundAlignmentStatus: 0,
              fundStandardName: name,
              fundWeightedSustainabilityScore: null,
              isin: isin,
              name: name,
              preferenceStatus: {},
              preferenceValues: {},
              ticker: id
            })
          ),
          investorSustainabilityScore: 0,
          portfolioAnalysis: {
            alignmentStatus: {
              alignmentCriteria1: null,
              alignmentCriteria2: null,
              alignmentCriteria3: null,
              alignmentCriteria4: null,
              alignmentCriteria5: null
            },
            alignmentValuesWeighted: {
              alignmentCriteria1: null,
              alignmentCriteria2: null,
              alignmentCriteria3: null,
              alignmentCriteria4: null,
              alignmentCriteria5: null
            },
            portfolioWeightedStatus: false,
            portfolioWeightedSustainabilityScore: 0
          }
        };

        sustainabilityData = [
          {
            goalId: goal.goalId,
            data: dataWithoutSustainability
          }
        ];
      }

      sustainabilityStore.addSustainabilityData(sustainabilityData);

      advisoryPageStore.setPageStatus(
        'readSustainabilityModelPortfolioData',
        PageStatuses.succeed
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        sustainabilityStore.reset();
        advisoryPageStore.setPageStatus(
          'readSustainabilityModelPortfolioData',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readSustainability;
};
