import axios, { CancelTokenSource } from 'axios';
import { isEmpty, isNil, propOr } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePortfolioChartStore } from '../portfolioChart';
import { useExistingPortfolio } from '../proposal/services/selectors';
import { createAnalyzeAssetClassAllocation } from './api';
import { getSubAssetClassTranslation } from './mapping';
import { Goal } from './services/goalsStore';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { Asset } from 'features/shared/analyticsComponents/portfolioChartCards/visual';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getColor } from 'features/shared/utils/colors';
import { roundNumber } from 'features/shared/utils/number';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { useTheme } from 'features/sharedModules/styles/components/styles';

type GetModelPortfolioChartData = {
  goal: Goal;
};
export const useGetModelPortfolioChartData = ({
  goal
}: GetModelPortfolioChartData) => {
  const i18n = useI18n();
  const portfolioChartStore = usePortfolioChartStore();

  return {
    broadAssetAllocation: propOr(
      [],
      'categoryMainAssetClassPortfolio',
      portfolioChartStore.getChartData(goal.goalId, goal.data.isPortfolioCustom)
    ) as Asset[],
    assetAllocation: propOr(
      [],
      'categorySubAssetClassPortfolio',
      portfolioChartStore.getChartData(goal.goalId, goal.data.isPortfolioCustom)
    ) as Asset[],
    fundAllocation: propOr(
      [],
      'categorySelectionPortfolio',
      portfolioChartStore.getChartData(goal.goalId, goal.data.isPortfolioCustom)
    ) as Asset[],
    id: goal.goalId,
    title: i18n('roboAdvice.advisory.portfolio.modelPortfolio'),
    icon: goal.icon
  };
};

// Suggested = Aligned with session risk score
type GetSuggestedPortfolioChartData = {
  goal: Goal;
};
export const useGetSuggestedPortfolioChartData = ({
  goal
}: GetSuggestedPortfolioChartData) => {
  const i18n = useI18n();
  const portfolioChartStore = usePortfolioChartStore();

  return {
    broadAssetAllocation: propOr(
      [],
      'categoryMainAssetClassPortfolio',
      portfolioChartStore.getSuggestedChartData(goal.goalId)
    ) as Asset[],
    assetAllocation: propOr(
      [],
      'categorySubAssetClassPortfolio',
      portfolioChartStore.getSuggestedChartData(goal.goalId)
    ) as Asset[],
    fundAllocation: propOr(
      [],
      'categorySelectionPortfolio',
      portfolioChartStore.getSuggestedChartData(goal.goalId)
    ) as Asset[],
    id: goal.goalId,
    title: i18n('roboAdvice.advisory.portfolio.riskClassPortfolio'),
    icon: goal.icon
  };
};

export const useGetExistingPortfolioChartData = () => {
  const i18n = useI18n();
  const existingPortfolioData = useExistingPortfolio();

  return {
    broadAssetAllocation:
      existingPortfolioData && existingPortfolioData.length > 0
        ? (existingPortfolioData[0] as Asset[])
        : [],
    assetAllocation:
      existingPortfolioData && existingPortfolioData.length > 1
        ? (existingPortfolioData[1] as Asset[])
        : [],
    fundAllocation: [],
    id: 'existingPortfolio',
    title: i18n('roboAdvice.advisory.portfolio.existingPortfolio')
  };
};

type PortfolioAllocation = { category: string; name: string; weight: number };
type AssetClassAllocation = {
  equityShare: number;
  fixedIncomeShare: number;
  instrumentAllocation: {
    Category: string;
    FundStandardName: string;
    ISIN: string;
    Name: string;
    Ticker: string;
    categoryDistribution: Record<string, number>;
    equityShare: number;
    fixedIncomeShare: number;
    weight: number;
  }[];
  portfolioAllocation: PortfolioAllocation[];
};
type GetCustomPortfolioChartData = {
  goal: Goal;
};
export const useGetCustomPortfolioChartData = ({
  goal
}: GetCustomPortfolioChartData) => {
  const i18n = useI18n();
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const [customPortfolioAnalyze, setCustomPortfolioAnalyze] =
    useState<AssetClassAllocation | null>(null);
  const {
    roboAdvice: { subAssetClassNameMapping = {} }
  } = useCustomerConfig();

  const getCustomPortfolioAnalyze = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const portfolio = (goal.data.customPortfolio ?? [])
        .map(({ instruments }) => instruments)
        .flat()
        .filter(({ weight }) => weight)
        .map(({ id, weight }) => ({ id, weight }))
        .reduce((acc, asset) => {
          const foundAssetIndex = acc.findIndex(a => a.id === asset.id);
          if (foundAssetIndex !== -1) {
            return acc.map(({ id, weight }) => ({
              id,
              weight: id === asset.id ? weight + asset.weight! : weight
            }));
          }

          return [...acc, { id: asset.id, weight: asset.weight ?? 0 }];
        }, [] as { id: string; weight: number }[]);

      const portfolioSum = roundNumber(
        portfolio.reduce((prev, { weight }) => prev + (weight ?? 0), 0),
        3
      );

      if (portfolioSum !== 100) return;

      const assetClassAllocationResponse =
        await createAnalyzeAssetClassAllocation(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            portfolio
          },
          {
            namespace_id: goal.data.productPlatformNamespace,
            language: getUserPageLanguage()
          }
        );

      const assetClassAllocationData = {
        ...assetClassAllocationResponse.data,
        portfolioAllocation:
          assetClassAllocationResponse.data.portfolioAllocation.map(
            allocation => ({
              ...allocation,
              name:
                getSubAssetClassTranslation(
                  allocation.category,
                  subAssetClassNameMapping
                ) || allocation.name
            })
          )
      };

      setCustomPortfolioAnalyze(assetClassAllocationData);
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.advisory.customPortfolio.fetchingCustomPortfolioAssetsError'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  useEffect(() => {
    if (
      !isNil(goal.data.customPortfolio) &&
      !isEmpty(goal.data.customPortfolio)
    ) {
      getCustomPortfolioAnalyze();
    } else {
      setCustomPortfolioAnalyze(null);
    }
  }, [goal.data.customPortfolio]);

  if (!customPortfolioAnalyze) {
    return {
      broadAssetAllocation: [
        {
          color: theme.chartEmptyColor,
          weight: 100,
          name: '',
          id: 'noToolbar'
        },
        {
          color: theme.chartPortfolioColors[0],
          id: 'emptyFixedIncome',
          name: i18n('portfolioChart.fixedIncome'),
          weight: 0
        },
        {
          color: theme.chartPortfolioColors[1],
          id: 'emptyEquity',
          name: i18n('portfolioChart.equity'),
          weight: 0
        }
      ],
      assetAllocation: [
        {
          color: theme.chartEmptyColor,
          weight: 100,
          name: '',
          id: 'noToolbar'
        }
      ],
      fundAllocation: [],
      id: 'customPortfolio',
      title: i18n('roboAdvice.advisory.portfolio.customPortfolio')
    };
  }

  const equity = {
    color: theme.chartPortfolioColors[1],
    id: 'equity',
    name: i18n('portfolioChart.equity'),
    weight: customPortfolioAnalyze.equityShare * 100
  };

  const fixedIncome = {
    color: theme.chartPortfolioColors[0],
    id: 'fixedIncome',
    name: i18n('portfolioChart.fixedIncome'),
    weight: customPortfolioAnalyze.fixedIncomeShare * 100
  };

  const broadAssetAllocation: Asset[] = [fixedIncome, equity];

  const groupedPortfolioAllocation =
    customPortfolioAnalyze.portfolioAllocation.reduce((prev, curr) => {
      const foundAssetIndex = prev.findIndex(p => p.name === curr.name);

      if (foundAssetIndex !== -1) {
        return prev.map((portfolioAllocation, index) => {
          if (foundAssetIndex === index) {
            return {
              ...portfolioAllocation,
              weight: portfolioAllocation.weight + curr.weight
            };
          }

          return portfolioAllocation;
        });
      }

      return [...prev, curr];
    }, [] as PortfolioAllocation[]);

  const assetAllocation: Asset[] = groupedPortfolioAllocation.map(
    ({ name, category, weight }, index) => ({
      id: category,
      name,
      weight: weight * 100,
      color: getColor(
        theme.chartPortfolioColors,
        index,
        customPortfolioAnalyze.portfolioAllocation.length
      ),
      CategoryId: category
    })
  );

  return {
    broadAssetAllocation,
    assetAllocation,
    fundAllocation: [],
    id: 'customPortfolio',
    title: i18n('roboAdvice.advisory.portfolio.customPortfolio')
  };
};
