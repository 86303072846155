import { useSelector } from 'react-redux';

import { useHolisticViewStore } from '../../../services/holisticViewStore';
import { useInitPage } from './useInitPage';
import { useReadCostChartData } from './useReadCostChartData';
import { useCostChartStore } from 'features/roboAdvice/adviceSession/costChart';
import {
  useReadDataListener,
  PageStatuses
} from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import CostChartViewVisual from 'features/shared/analyticsComponents/costChart/visual';
import sessionSelectors from 'features/shared/services/session/selectors';

const CostChartView = ({ isSelected = false }) => {
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const chartStoreData = useCostChartStore();
  const { proposalSectionStatuses } = useHolisticViewStore();

  useReadDataListener(
    proposalSectionStatuses.readCostChartData,
    isSelected,
    useReadCostChartData()
  );
  useInitPage();

  return (
    <CostChartViewVisual
      chartStoreChartData={chartStoreData.chartData}
      chartStoreTableData={chartStoreData.tableData}
      cultureCode={cultureCode}
      isLoading={
        useHolisticViewStore.getState().proposalSectionStatuses
          .readCostChartData === PageStatuses.pending
      }
    />
  );
};

export default CostChartView;
