import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFinancialSituationValues } from '../../../financialSituation/services/selectors';
import { usePortfolioChartStore } from '../../../portfolioChart';
import {
  createTransactionList,
  getInvestorAccountsData
} from '../../../shared/api';
import { PageStatuses } from '../../../shared/components/useReadDataListener';
import { useGoalsStore } from '../../../shared/services/goalsStore';
import {
  Transaction,
  useTransactionListStore
} from '../../../transactionListStore';
import { usePageStore as useProposalPageStore } from '../../services/pageStore';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions';
import sessionSelectors from 'features/shared/services/session/selectors';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

const useReadTransactionList = () => {
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const cancelTokenSourceRef = React.useRef<CancelTokenSource>();
  const dispatch = useDispatch();
  const i18n = useI18n();
  const { clientId } = useParams<AdviceSessionParams>();
  const transactionListStore = useTransactionListStore();
  const {
    roboAdviceForm: {
      financialSituation: {
        accounts: {
          sync: {
            mapping: {
              company: accountsSyncCompanyMapping,
              person: accountsSyncPersonMapping
            }
          }
        }
      }
    }
  } = useCustomerConfig();
  const { clientType } = useParams<AdviceSessionParams>();
  const financialSituationValues = useFinancialSituationValues();

  const readTransactionList = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const { goals } = useGoalsStore.getState();
    const proposalPageStore = useProposalPageStore.getState();
    const portfolioChartStore = usePortfolioChartStore.getState();

    try {
      proposalPageStore.setPageStatus(
        'readTransactionList',
        PageStatuses.pending
      );
      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const { data: investorAccountData } = await getInvestorAccountsData(
        qAuthAccessToken,
        cancelTokenSource.token,
        clientId
      );
      const accountsSyncMapping =
        clientType === ClientTypes.company
          ? accountsSyncCompanyMapping
          : accountsSyncPersonMapping;

      const positionsFieldId = accountsSyncMapping.find(
        map => map.accountValueTarget === 'positions'
      )?.financialSituationFieldId;

      const positionToPercentageMap: Map<string, number> = new Map();

      if (positionsFieldId && financialSituationValues[positionsFieldId]) {
        financialSituationValues[positionsFieldId]?.forEach(field => {
          positionToPercentageMap.set(
            field.title,
            financialSituationValues.amountForAdviceSwitch
              ? field.toAdvisory || 0
              : ((field.toAdvisory || 0) / (field.value || 0)) * 100
          );
        });
      }

      const existingPortfolio = {
        instruments: investorAccountData.reduce((acc, account) => {
          return acc.concat(
            account.positions.map(position => ({
              isin: position.isin,
              name: position.name,
              value: Number(position.amount) || 0,
              toAdvisoryPercentage:
                positionToPercentageMap.get(position.name) || 0
            }))
          );
        }, [] as { isin: string; value: number; toAdvisoryPercentage: number }[])
      };

      const goalsData = goals.map(goal => {
        const goalPortfolio = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          ?.categorySelectionPortfolio.map(({ isin, weight }) => ({
            isin,
            weight
          }));

        return {
          goalPortfolio,
          goalValueOfExistingPortfolio: goal.data.internalHolding || 0,
          oneTimeDeposit: goal.data.firstDeposit || 0,
          withdrawal: 0
        };
      });
      const allPortfolioExists = goalsData.every(
        ({ goalPortfolio }) => goalPortfolio && goalPortfolio.length > 0
      );
      const payload = {
        goals: goalsData,
        existingPortfolio
      };
      if (allPortfolioExists) {
        const { data: transactionList } = (await createTransactionList(
          qAuthAccessToken,
          cancelTokenSource.token,
          payload
        )) as { data: { data: { transactions: Transaction[] } } };

        transactionListStore.setTransactions(
          transactionList.data.transactions.map(transaction => {
            const portfolioItem = existingPortfolio.instruments.find(
              ({ isin }) => isin === transaction.isin
            );
            return {
              ...transaction,
              existingPortfolioValue: portfolioItem?.value || 0,
              toAdvisory:
                ((portfolioItem?.toAdvisoryPercentage || 0) *
                  (portfolioItem?.value || 0)) /
                100
            };
          })
        );

        proposalPageStore.setPageStatus(
          'readTransactionList',
          PageStatuses.succeed
        );
      } else {
        throw new Error('Portfolio data is missing');
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        proposalPageStore.setPageStatus(
          'readTransactionList',
          PageStatuses.failed
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readTransactionList;
};

export default useReadTransactionList;
