import { v4 } from 'uuid';

import { Category } from '../../session/services/sessionStore';
import { AccountData, ReadFundLookupResponse } from '../../shared/api/types';
import { getSubAssetClassTranslation } from '../../shared/mapping';
import {
  AccountValueTarget,
  AccountsSyncMapping
} from 'defaults/defaultRoboAdviceForm';

export type Detail = {
  id: string;
  title: string;
  accountNumber?: string;
  toAdvisory: number;
  value: number;
};

export const mapDetailsToISINs = (
  accountsData: AccountData[],
  accountsSyncMapping: AccountsSyncMapping
): string[] => {
  const result: string[] = [];
  accountsData.forEach(accountData => {
    const { type, positions } = accountData;

    const mappings = accountsSyncMapping.filter(
      ({ accountType }) => accountType === type
    );

    mappings.forEach(({ accountValueTarget }) => {
      if (accountValueTarget === AccountValueTarget.positions) {
        positions.forEach(({ isin }) => {
          if (isin) {
            result.push(isin);
          }
        });
      }
    });
  });

  return result;
};

export const mapAccountsDataToFinancialSituationAssets = (
  accountsData: AccountData[],
  financialSituationValues: Record<string, any>,
  accountsSyncMapping: AccountsSyncMapping,
  fundLookupData?: ReadFundLookupResponse,
  assetClassOptions?: {
    label: string;
    mainCategory: Category;
    categories: string[];
  }[],
  subAssetClassNameMapping?: Record<string, Record<string, string>>
): Record<string, Detail[]> => {
  const amountForAdviceSwitch = financialSituationValues?.amountForAdviceSwitch;

  const result: Record<string, Detail[]> = {};
  accountsData.forEach(accountData => {
    const { accountNumber, cashAmount, type, positions } = accountData;

    const mappings = accountsSyncMapping.filter(
      ({ accountType }) => accountType === type
    );

    mappings.forEach(({ accountValueTarget, financialSituationFieldId }) => {
      let newData: Detail[] | null = null;

      if (accountValueTarget === AccountValueTarget.cashAmount) {
        newData = [
          {
            id: v4(),
            title: accountNumber || '',
            accountNumber: accountNumber || '',
            toAdvisory: amountForAdviceSwitch
              ? 100
              : Math.floor(+cashAmount) || 0,
            value: Math.floor(+cashAmount) || 0
          }
        ];
      } else if (accountValueTarget === AccountValueTarget.positions) {
        newData = positions.map(({ name, amount, isin }) => {
          const additionalData: {
            assetClass?: Category;
            isTaggedAutomatically?: boolean;
          } = {};

          if (fundLookupData && assetClassOptions) {
            const lookUpNamespaces =
              fundLookupData.funds.find(
                ({ isin: fundIsin }) => fundIsin === isin
              )?.namespaces ?? [];
            const lookUpCategory = lookUpNamespaces.find(
              ({ isDefault }) => isDefault
            )?.categories?.[0];

            const assetClassOption = assetClassOptions.find(({ categories }) =>
              categories.some(
                category => category === lookUpCategory?.categoryCode
              )
            );

            if (assetClassOption && lookUpCategory) {
              const mappedSubAssetClass = getSubAssetClassTranslation(
                lookUpCategory.categoryCode,
                subAssetClassNameMapping
              );

              additionalData.assetClass = {
                ...assetClassOption.mainCategory,
                SubAssetClass:
                  mappedSubAssetClass || lookUpCategory.subCategoryName
              };
              additionalData.isTaggedAutomatically = true;
            }
          }

          return {
            id: v4(),
            title: name,
            accountNumber: accountNumber || '',
            toAdvisory: amountForAdviceSwitch ? 100 : Math.floor(+amount) || 0,
            value: Math.floor(+amount) || 0,
            ...additionalData
          };
        });
      }

      if (newData) {
        if (
          result[financialSituationFieldId] &&
          Array.isArray(result[financialSituationFieldId])
        ) {
          result[financialSituationFieldId].push(...newData);
        } else {
          result[financialSituationFieldId] = newData;
        }
      }
    });
  });

  return result;
};
