import { isNil } from 'ramda';
import { useCallback } from 'react';

import { usePageStore as useProposalPageStore } from '../../../proposal/services/pageStore';
import { Goal } from '../../../shared/services/goalsStore';
import { PortfolioIds } from '../../services/constants';
import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { usePageStore as useAdvisoryPageStore } from '../../services/pageStore';
import HistoricalReturn from '../historicalReturn';
import AssetClassAllocation from './assetClassAllocation';
import FundAllocation from './fundAllocation';
import { useInitCustomPortfolio } from './useInitCustomPortfolio';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { carouselIds } from 'features/shared/components/carousel/carouselStore';
import {
  Carousel,
  CarouselBody,
  CarouselItem,
  CarouselTab,
  CarouselTabs
} from 'features/shared/components/carousel/verticalNavigation';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { AdvisoryErrors } from 'features/sharedModules/errors/types';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  carouselRoot: {
    padding: 0
  },
  tabs: {
    paddingLeft: 0
  }
}));

type Props = {
  selectedGoal: Goal;
  isCarouselSwipeable?: boolean;
  errors?: AdvisoryErrors;
};

type CustomPortfolioId =
  | typeof PortfolioIds.existingPortfolio
  | typeof PortfolioIds.suggestedPortfolio
  | typeof PortfolioIds.customPortfolio
  | typeof PortfolioIds.benchmarkPortfolio;

const CustomPortfolio = ({
  selectedGoal,
  isCarouselSwipeable,
  errors
}: Props) => {
  const classes = useStyles();
  const i18n = useI18n();
  const {
    advisoryComponents: {
      customPortfolioModelPortfolioComparison,
      existingPortfolioComparison,
      customPortfolioEnableHistoricalReturn,
      historicalReturnCompareWithBenchmark
    }
  } = useCustomerConfig();
  const proposalPageStore = useProposalPageStore();
  const advisoryPageStore = useAdvisoryPageStore();
  const customPortfolioStore = useCustomPortfolioStore();

  const portfolioIds = [
    existingPortfolioComparison && PortfolioIds.existingPortfolio,
    customPortfolioModelPortfolioComparison && PortfolioIds.suggestedPortfolio,
    PortfolioIds.customPortfolio,
    historicalReturnCompareWithBenchmark && PortfolioIds.benchmarkPortfolio
  ].filter((portfolio): portfolio is CustomPortfolioId => !!portfolio);

  useInitCustomPortfolio({ selectedGoal });

  const carouselElements = [
    {
      tab: 'roboAdvice.advisory.customPortfolio.tabs.assetClassAllocation',
      item: (
        <AssetClassAllocation selectedGoal={selectedGoal} errors={errors} />
      ),
      key: 'asset-class-allocation',
      display: true
    },
    {
      tab: 'roboAdvice.advisory.customPortfolio.tabs.fundAllocation',
      item: <FundAllocation selectedGoal={selectedGoal} errors={errors} />,
      key: 'fund-allocation',
      display: true
    },
    {
      tab: 'roboAdvice.advisory.customPortfolio.tabs.historicalReturn',
      item: (
        <HistoricalReturn
          portfolioIds={portfolioIds}
          selectedGoal={selectedGoal}
        />
      ),
      key: 'historical-return',
      display: customPortfolioEnableHistoricalReturn,
      statusKeys: [
        'readHistoricalReturn3MChartData',
        'readHistoricalReturnYTDChartData',
        'readHistoricalReturn1YChartData',
        'readHistoricalReturn3YChartData',
        'readHistoricalReturn5YChartData',
        'readHistoricalReturn10YChartData'
      ]
    }
  ].filter(({ display }) => display);

  const getIsCarouselPending = useCallback(
    selectedItemIndex => {
      const statusKeys = carouselElements[selectedItemIndex]?.statusKeys;

      return isNil(statusKeys)
        ? proposalPageStore.pageStatuses['readPortfolioChartData'] ===
            PageStatuses.pending ||
            customPortfolioStore.assetClassInstrumentsStatus ===
              PageStatuses.pending
        : statusKeys.some(
            statusKey =>
              advisoryPageStore.pageStatuses[statusKey] === PageStatuses.pending
          );
    },
    [
      advisoryPageStore.pageStatuses,
      carouselElements,
      customPortfolioStore.assetClassInstrumentsStatus,
      proposalPageStore.pageStatuses
    ]
  );

  return (
    <Carousel
      getIsPending={getIsCarouselPending}
      className={classes.carouselRoot}
      carouselId={carouselIds.customPortfolio}
    >
      <CarouselTabs
        className={classes.tabs}
        carouselId={carouselIds.customPortfolio}
      >
        {carouselElements.map(({ tab, key }) => (
          <CarouselTab key={key}>{i18n(tab)}</CarouselTab>
        ))}
      </CarouselTabs>
      <CarouselBody
        isSwipeable={isCarouselSwipeable}
        carouselId={carouselIds.customPortfolio}
      >
        {carouselElements.map(({ item, key }) => (
          <CarouselItem key={key}>{item}</CarouselItem>
        ))}
      </CarouselBody>
    </Carousel>
  );
};

export default CustomPortfolio;
