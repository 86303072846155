import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFinancialSituationValues } from '../../financialSituation/services/selectors';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { calculateInternalPortfolioTotalValue } from '../services/mapping';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import TextInput from 'features/shared/components/textInput/index';
import { Colors } from 'features/shared/constants/colors.js';
import { Spacing } from 'features/shared/constants/spacing';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getNumberInputFormat } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  section: {
    width: 436,

    '& + &': {
      marginTop: Spacing.spacing01
    }
  },
  label: {
    color: Colors.gray50,
    flex: '0 0 auto',
    width: '15rem'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& + &': {
      marginTop: '3rem'
    }
  },
  warningIcon: {
    color: Colors.yellow50,
    fontSize: '26.4px',
    transform: 'rotate(0.03deg)'
  }
});

const SavingsPlan = ({
  goalId,
  goals,
  firstDeposit: firstDepositProp,
  monthlyDeposit: monthlyDepositProp,
  internalHolding: internalHoldingProp,
  sectionHeaderClassName
}) => {
  const classes = useStyles();
  const i18n = useI18n();
  const cultureCode = useSelector(sessionSelectors.getCurrentUserCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const { clientType } = useParams();
  const isSessionReadOnly = useIsSessionReadOnly();
  const {
    roboAdviceForm: {
      financialSituation: {
        accounts: {
          sync: {
            enabled: isAccountsSyncEnabled,
            mapping: {
              company: accountsSyncCompanyMapping,
              person: accountsSyncPersonMapping
            }
          }
        }
      }
    },
    analyticsComponents: { isTransactionListHidden }
  } = useCustomerConfig();
  const updateGoal = useUpdateGoal();
  const financialSituationValues = useFinancialSituationValues();

  const [internalHolding, setInternalHolding] = useState(
    internalHoldingProp || ''
  );
  const [monthlyDeposit, setMonthlyDeposit] = useState(
    monthlyDepositProp || ''
  );
  const [firstDeposit, setFirstDeposit] = useState(firstDepositProp || '');

  useEffect(() => {
    setMonthlyDeposit(monthlyDepositProp || '');
    setFirstDeposit(firstDepositProp || '');
    if (!isTransactionListHidden) {
      setInternalHolding(internalHoldingProp || '');
    }
  }, [monthlyDepositProp, firstDepositProp, internalHoldingProp]);

  const handleValueChange = useCallback(
    (name, values) => {
      if (
        (name === 'monthlyDeposit' &&
          values.floatValue !== monthlyDepositProp) ||
        (name === 'firstDeposit' && values.floatValue !== firstDepositProp) ||
        (name === 'internalHolding' &&
          values.floatValue !== internalHoldingProp)
      ) {
        updateGoal(goalId, { data: { [name]: values.floatValue } });
      }
    },
    [goalId, monthlyDepositProp, firstDepositProp, internalHoldingProp]
  );

  const debouncedHandleValueChange = useCallback(
    debounce((name, values) => handleValueChange(name, values), 300),
    [goalId, goals]
  );

  useEffect(() => {
    if (
      goals &&
      goals.length === 1 &&
      isAccountsSyncEnabled &&
      financialSituationValues &&
      !isTransactionListHidden
    ) {
      setInternalHolding(
        calculateInternalPortfolioTotalValue({
          financialSituationValues,
          clientType,
          accountsSyncPersonMapping,
          accountsSyncCompanyMapping
        }) || 0
      );
    }
  }, [goals, financialSituationValues]);

  return (
    <>
      <div className={sectionHeaderClassName}>
        <ConfigurableLabel
          required
          label={i18n('roboAdvice.advisory.savingsPlan')}
        />
      </div>
      <div>
        <div data-testid={'savings-plan-form'}>
          {!isTransactionListHidden && (
            <div className={classes.section}>
              <div className={classes.inputContainer}>
                <div className={classes.label}>
                  {i18n('roboAdvice.savingsPlan.internalHolding')}
                </div>
                <div className={classes.control}>
                  <NumberFormat
                    customInput={TextInput}
                    value={internalHolding}
                    onValueChange={values => {
                      setInternalHolding(values.floatValue);
                      debouncedHandleValueChange('internalHolding', values);
                    }}
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    allowedDecimalSeparators={[',', '.']}
                    allowNegative={false}
                    decimalScale={0}
                    disabled={isSessionReadOnly || goals.length < 2}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={classes.section}>
            <div className={classes.inputContainer}>
              <div className={classes.label}>
                {i18n('roboAdvice.savingsPlan.monthlyDeposit')}
              </div>
              <div className={classes.control}>
                <NumberFormat
                  customInput={TextInput}
                  value={monthlyDeposit}
                  onValueChange={values => {
                    setMonthlyDeposit(values.floatValue);
                    debouncedHandleValueChange('monthlyDeposit', values);
                  }}
                  thousandSeparator={thousandSeparator}
                  decimalSeparator={decimalSeparator}
                  allowedDecimalSeparators={[',', '.']}
                  allowNegative={false}
                  decimalScale={0}
                  disabled={isSessionReadOnly}
                />
              </div>
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.inputContainer}>
              <div className={classes.label}>
                {i18n('roboAdvice.savingsPlan.firstDeposit')}
              </div>
              <div className={classes.control}>
                <NumberFormat
                  customInput={TextInput}
                  value={firstDeposit}
                  onValueChange={values => {
                    setFirstDeposit(values.floatValue);
                    debouncedHandleValueChange('firstDeposit', values);
                  }}
                  thousandSeparator={thousandSeparator}
                  decimalSeparator={decimalSeparator}
                  allowedDecimalSeparators={[',', '.']}
                  allowNegative={false}
                  decimalScale={0}
                  disabled={isSessionReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavingsPlan;
