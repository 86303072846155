import axios from 'axios';
import { isNil } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHolisticViewStore } from '../../../services/holisticViewStore';
import { TenantSettings } from 'defaults/defaultTenantSettings';
import { HorizonConfig } from 'defaults/defaultTimeHorizonConfig';
import { getUserPageLanguage } from 'features/pageLanguage/main/components/usePageLanguage';
import { useCostChartStore } from 'features/roboAdvice/adviceSession/costChart';
import { usePortfolioChartStore } from 'features/roboAdvice/adviceSession/portfolioChart';
import { getHorizonAnalysisProjectionYearsValue } from 'features/roboAdvice/adviceSession/proposal/services/selectors';
import { createAnalyzeCost } from 'features/roboAdvice/adviceSession/shared/api';
import { PageStatuses } from 'features/roboAdvice/adviceSession/shared/components/useReadDataListener';
import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatDateForAPI } from 'features/shared/utils/dateTime.js';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadCostChartData() {
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const dispatch = useDispatch();
  const {
    timeHorizonConfig,
    tenantSettings: { fundNameForPresentation }
  }: { timeHorizonConfig: HorizonConfig; tenantSettings: TenantSettings } =
    useCustomerConfig();
  const portfolioChartStore = usePortfolioChartStore();

  const cancelTokenSourceRef = React.useRef<any>();
  const readCostChartData = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    const costChartStore = useCostChartStore.getState();

    try {
      useHolisticViewStore
        .getState()
        .setProposalSectionStatus('readCostChartData', PageStatuses.pending);

      const {
        values: { clientInformation }
      } = clientForm.getState();

      const qAuthAccessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const goals = useHolisticViewStore
        .getState()
        .availableClients.filter(client => client.isSelected)
        .map(client => client.goals || [])
        .flat();

      let goalsData: any[] = [];

      for (const goal of goals) {
        const portfolio = portfolioChartStore
          .getChartData(goal.goalId, goal.data.isPortfolioCustom)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        const savingsplan = {
          starting_capital: goal.data.firstDeposit || 0,
          monthly_savings: goal.data.monthlyDeposit || 0
        };

        const dateOfBirth = clientInformation?.additionalData?.dateOfBirth
          ? formatDateForAPI(clientInformation.additionalData.dateOfBirth)
          : undefined;

        const payload = {
          portfolio,
          savingsplan,
          horizon: getHorizonAnalysisProjectionYearsValue({
            timeHorizonConfig,
            timeHorizon: goal.data.timeHorizon
          }),
          dateOfBirth
        };

        const analyzeCostResponse = await createAnalyzeCost(
          qAuthAccessToken,
          cancelTokenSource.token,
          payload,
          {
            namespace_id: goal.data.productPlatformNamespace,
            language: getUserPageLanguage()
          }
        );

        const data = {
          portfolio,
          startingCapital:
            savingsplan.starting_capital > 0
              ? savingsplan.starting_capital
              : savingsplan.monthly_savings,
          data: analyzeCostResponse.data,
          goalId: goal.goalId,
          i18n
        };

        goalsData = [...goalsData, data];
      }

      costChartStore.addGoalsData(goalsData, fundNameForPresentation);
      useHolisticViewStore
        .getState()
        .setProposalSectionStatus('readCostChartData', PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        costChartStore.resetCostData();
        useHolisticViewStore
          .getState()
          .setProposalSectionStatus('readCostChartData', PageStatuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.proposal.readDataErrorMessage'),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readCostChartData;
}
