import * as R from 'ramda';
import create from 'zustand';

import { AdviceSessionFormValues } from '../../form/services/form';
import {
  PageStatus,
  PageStatuses
} from '../../shared/components/useReadDataListener';
import { Goal } from '../../shared/services/goalsStore';

export const pagesToUpdate = [
  'readCashflowData',
  'readExpectedPathChartData',
  'readExpectedValueChartData',
  'readCostChartData',
  'readOrderSummary'
];

export type ProposalPageStatuses = {
  readProposalPageData?: PageStatus;
  readPortfolioChartData?: PageStatus;
  readCashflowData?: PageStatus;
  readExpectedPathChartData?: PageStatus;
  readExpectedValueChartData?: PageStatus;
  readCostChartData?: PageStatus;
  readEfficientFrontierChartData?: PageStatus;
  readHistoricalReturn3MChartData?: PageStatus;
  readHistoricalReturnYTDChartData?: PageStatus;
  readHistoricalReturn1YChartData?: PageStatus;
  readHistoricalReturn3YChartData?: PageStatus;
  readHistoricalReturn5YChartData?: PageStatus;
  readHistoricalReturn10YChartData?: PageStatus;
  readOrderSummary?: PageStatus;
  readSustainability?: PageStatus;
  readTransactionList?: PageStatus;
};

export type AssetClass = {
  mainAssetClass: string;
  subAssetClass: string;
  instruments: {
    id: string;
    isin: string;
    title: string;
    weight: number;
  }[];
  weight: number;
};

export type FinishedPortfolioData = {
  goals: Goal[];
  roboAdviceForm: AdviceSessionFormValues;
};

type GoalsToUpdate = {
  readCashflowData: Goal[];
  readExpectedValueChartData: Goal[];
  readExpectedPathChartData: Goal[];
  readCostChartData: Goal[];
  readProposalPageData: Goal[];
  readOrderSummary: Goal[];
};

export type ProposalPageStoreData = {
  pageStatuses: ProposalPageStatuses;
  isGenerateReportPopupOpen: boolean;
  isGenerateNewPdfReportPopupOpen: boolean;
  isReadPdfReportPending: boolean;
  isReadNewPdfReportPending: boolean;
  finishedPortfolioData?: FinishedPortfolioData;
  goalsToUpdate: GoalsToUpdate;
  cachePDFStatus?: PageStatus;
};

export type ProposalPageStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<ProposalPageStoreData>) => void;
  setPageStatus: <K extends keyof ProposalPageStatuses>(
    key: K,
    value: ProposalPageStatuses[K]
  ) => void;
  changePageStatuses: (pageStatuses: Partial<ProposalPageStatuses>) => void;
  setIsGenerateReportPopupOpen: (isGenerateReportPopupOpen: boolean) => void;
  setIsGenerateNewPdfReportPopupOpen: (
    isGenerateNewPdfReportPopupOpen: boolean
  ) => void;
  setIsReadPdfReportPending: (isReadPdfReportPending: boolean) => void;
  setIsReadNewPdfReportPending: (isReadNewPdfReportPending: boolean) => void;
  setFinishedPortfolioData: (
    finishedPortfolioData: FinishedPortfolioData
  ) => void;
  setGoalsToUpdate: (key: keyof GoalsToUpdate, value: Goal[]) => void;
  setCachePDFStatus: (cachePDFStatus: PageStatus) => void;
};

export type ProposalPageStoreState = ProposalPageStoreData &
  ProposalPageStoreApi;

export const defaultData = {
  pageStatuses: {},
  isGenerateReportPopupOpen: false,
  isGenerateNewPdfReportPopupOpen: false,
  isReadPdfReportPending: false,
  isReadNewPdfReportPending: false,
  finishedPortfolioData: undefined,
  goalsToUpdate: {
    readCashflowData: [],
    readExpectedValueChartData: [],
    readExpectedPathChartData: [],
    readCostChartData: [],
    readProposalPageData: [],
    readOrderSummary: []
  },
  cachePDFStatus: PageStatuses.ready
};

export const usePageStore = create<ProposalPageStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  setPageStatus(key, value) {
    set(R.set(R.lensPath(['pageStatuses', key]), value), true);
  },
  changePageStatuses(pageStatuses) {
    set(R.over(R.lensProp('pageStatuses'), R.mergeLeft(pageStatuses)), true);
  },
  setIsGenerateReportPopupOpen(isGenerateReportPopupOpen) {
    set({ isGenerateReportPopupOpen });
  },
  setIsGenerateNewPdfReportPopupOpen(isGenerateNewPdfReportPopupOpen) {
    set({ isGenerateNewPdfReportPopupOpen });
  },
  setIsReadPdfReportPending(isReadPdfReportPending) {
    set({ isReadPdfReportPending });
  },
  setIsReadNewPdfReportPending(isReadNewPdfReportPending) {
    set({ isReadNewPdfReportPending });
  },
  setFinishedPortfolioData(finishedPortfolioData) {
    set({ finishedPortfolioData });
  },
  setGoalsToUpdate(key, value) {
    set(state => {
      return {
        goalsToUpdate: {
          ...state.goalsToUpdate,
          [key]:
            value.length && state.goalsToUpdate[key].length
              ? state.goalsToUpdate[key]
                  .concat(value)
                  .flat()
                  .reduce<Goal[]>((acc, curr) => {
                    const index = acc.findIndex(
                      ({ goalId }) => goalId === curr.goalId
                    );
                    if (index !== -1) {
                      return acc.map((item, i) => (i === index ? curr : item));
                    }
                    return [...acc, curr];
                  }, [])
              : value
        }
      };
    });
  },
  setCachePDFStatus(cachePDFStatus) {
    set({ cachePDFStatus });
  }
}));
