import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { BorderRadiusTypes } from '../../../../sharedModules/customerConfig/constants';
import InputErrorMessage from '../../../../sharedModules/errors/components/inputErrorMessage';
import RiskChangeAlert from '../../advisory/components/customPortfolio/riskChangeAlert';
import FinalFormCardsList from '../../form/components/finalFormCardsList';
import { useIsSessionReadOnly } from '../../main/services/selectors';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { creators } from '../../riskScore/services/actions';
import { useSessionStore } from '../../session/services/sessionStore';
import { Layouts as CardLayouts } from '../../shared/components/cradsList/card.js';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { Icons } from '../constants';
import GainLossChart from './gainLossChart';
import ConfigurableLabel from 'features/shared/components/configurableLabel/';
import { Spacing } from 'features/shared/constants/spacing';
import { processImgURL } from 'features/shared/utils/processImgURL.ts';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useErrorsStore } from 'features/sharedModules/errors/services/errorsStore';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles';

const borderRadius = {
  [BorderRadiusTypes.rounded]: '25px',
  [BorderRadiusTypes.sharp]: '15px'
};

const useStyles = createUseStyles(theme => ({
  cardsListContainer: {
    marginTop: Spacing.spacing04
  },
  riskImageContainer: {
    '& img': {
      height: '55px'
    }
  },
  hasError: {
    border: `2px solid ${theme.errorNegativeColor}`,
    padding: `${Spacing.spacing01}px ${Spacing.spacing01}px ${Spacing.spacing00}px `,
    borderRadius: borderRadius[theme.borderRadiusType],
    marginBottom: `${Spacing.spacing00}px`
  }
}));

export const Risk = ({ errors }) => {
  const {
    roboAdviceForm: {
      riskQuestions: { isRisk2Hidden },
      purposeAndRisk: { expectationOfRisk, riskStrategy }
    },
    riskScenarios
  } = useCustomerConfig();

  const isSessionReadOnly = useIsSessionReadOnly();
  const theme = useTheme();
  const classes = useStyles();
  const i18n = useI18n();
  const dispatch = useDispatch();
  const updateGoal = useUpdateGoal();
  const isErrorModeEnabled = useErrorsStore(state => state.isErrorModeEnabled);
  const sessionStore = useSessionStore();
  const { goals } = useGoalsStore();

  const expectationOfRiskCards = React.useMemo(
    () =>
      expectationOfRisk?.map(card => ({
        id: card.id,
        imageSrc: processImgURL(card?.iconUrl) || Icons[card.imageName],
        title: i18n(card.title),
        description: i18n(card.description)
      })),
    []
  );

  const riskMaxY = Math.max(...riskScenarios.map(el => el.max));
  const riskMinY = Math.min(...riskScenarios.map(el => el.min));
  const riskStrategyCards = React.useMemo(
    () =>
      riskStrategy?.map(scenario => ({
        id: scenario.id,
        imageComponent: (
          <GainLossChart
            scenarios={riskScenarios}
            maxY={riskMaxY}
            minY={riskMinY}
            scenarioId={scenario.id}
          />
        ),
        title: i18n(scenario.title),
        description: i18n(scenario.description)
      })),
    []
  );
  const riskStrategyLegend = React.useMemo(
    () => [
      {
        color: theme.chartPortfolioColors[0],
        text: i18n('roboAdvice.purposeAndRisk.riskStrategy.maximumGain')
      },
      {
        color: theme.chartPortfolioColors[3],
        text: i18n('roboAdvice.purposeAndRisk.riskStrategy.maximumLoss')
      }
    ],
    []
  );

  return (
    <div>
      <div
        className={classNames({
          [classes.hasError]: errors?.risk?.length && isErrorModeEnabled
        })}
      >
        {errors?.risk?.map(error => (
          <InputErrorMessage message={error.alertMessage} />
        ))}
        <FinalFormCardsList
          data-testid={'expectation-of-risk-cards-list'}
          name={'expectationOfRisk'}
          label={
            <ConfigurableLabel
              required
              label={i18n(
                'roboAdvice.purposeAndRisk.whatIsYourExpectationOfRisk'
              )}
            />
          }
          cards={expectationOfRiskCards}
          imageContainerClassName={classes.riskImageContainer}
          afterChange={() => {
            dispatch(creators.fieldChanged('expectationOfRisk', updateGoal));
          }}
          disabled={isSessionReadOnly}
          elementId={'expectation-of-risk-cards-list'}
          additionalComponent={
            <RiskChangeAlert
              in={
                goals.some(
                  ({ data }) =>
                    data.isPortfolioCustom && data.isCustomPortfolioDirty
                ) &&
                sessionStore.fieldsWhichChangedRiskScore.some(
                  field => field === 'expectationOfRisk'
                )
              }
              message={i18n('roboAdvice.advisory.riskScoreChangeAlertMessage')}
              onClick={() =>
                sessionStore.removeFieldWhichChangedRiskScore(
                  'expectationOfRisk'
                )
              }
            />
          }
        />
      </div>
      {!isRisk2Hidden && (
        <div
          className={classNames({
            [classes.hasError]: errors?.risk2?.length && isErrorModeEnabled
          })}
        >
          {errors?.risk2?.map(error => (
            <InputErrorMessage message={error.alertMessage} />
          ))}
          <FinalFormCardsList
            data-testid={'risk-strategy'}
            name={'riskStrategy'}
            label={
              <ConfigurableLabel
                required
                label={i18n(
                  'roboAdvice.purposeAndRisk.whereShouldYouPutYourMoney'
                )}
              />
            }
            cards={riskStrategyCards}
            cardLayout={CardLayouts.fullWidth}
            legend={riskStrategyLegend}
            containerClassName={classes.cardsListContainer}
            afterChange={() => {
              dispatch(creators.fieldChanged('riskStrategy', updateGoal));
            }}
            disabled={isSessionReadOnly}
            elementId={'risk-strategy'}
            additionalComponent={
              <RiskChangeAlert
                in={
                  goals.some(
                    ({ data }) =>
                      data.isPortfolioCustom && data.isCustomPortfolioDirty
                  ) &&
                  sessionStore.fieldsWhichChangedRiskScore.some(
                    field => field === 'riskStrategy'
                  )
                }
                message={i18n(
                  'roboAdvice.advisory.riskScoreChangeAlertMessage'
                )}
                onClick={() =>
                  sessionStore.removeFieldWhichChangedRiskScore('riskStrategy')
                }
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default Risk;
