import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { getTranslation } from '../../../../shared/utils/translations';
import { useCustomerConfig } from '../../../../sharedModules/customerConfig/components/useCustomerConfig';
import {
  RoboAdviceAdviceSessionPages,
  SessionStatuses,
  OrderExecutionType,
  RoboAdviceOrderExecutionPages
} from '../../../shared/constants';
import { useClientPageStore } from '../../main/services/clientPageStore';
import {
  getLastAddedSessionHighlight,
  removeLastAddedSessionHighlight
} from '../services/lastAddedSessionHighlightStorage';
import SessionRenamePopup from './sessionRenamePopup';
import { AdviceSessionView, useAdviceSessions } from './useAdviceSessions';
import { useCopyAdviceSession } from './useCopyAdviceSession';
import { useDeleteAdviceSession } from './useDeleteAdviceSession';
import { useTabs } from 'features/roboAdvice/adviceSession/main/services/selectors';
import { useTabs as useOrderExecutionTabs } from 'features/roboAdvice/adviceSession/orderExecution/services/selectors';
import CustomDownloadPopup from 'features/roboAdvice/shared/components/CustomDownloadPopup';
import { useDownloadReportDocuments } from 'features/roboAdvice/shared/components/useDownloadReportDocuments';
import { useReadReportDocuments } from 'features/roboAdvice/shared/components/useReadReportDocuments';
import { useCustomDownloadStore } from 'features/roboAdvice/shared/services/customDownloadStore';
import TableComponent from 'features/shared/components/table/table.js';
import TableBody from 'features/shared/components/table/tableBody.js';
import TableBodyRow from 'features/shared/components/table/tableBodyRow.js';
import TableRowMenu from 'features/shared/components/table/tableRowMenu.js';
import { NotificationTypes } from 'features/shared/constants/notification';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatDate } from 'features/shared/utils/dateTime.js';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { defaultConfirmation } from 'features/sharedInstances/defaultConfirmation';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

// Temporary solution until types added to TableComponent
const AnyTableComponent = TableComponent as any;

// Temporary solution until types added to TableBody
const AnyTableBody = TableBody as any;

// Temporary solution until types added to TableBodyRow
const AnyTableBodyRow = TableBodyRow as any;

const AnyTableRowMenu = TableRowMenu as any;

const useStyles = createUseStyles(theme => ({
  nameTextInput: {
    width: '340px'
  },
  cell: {
    verticalAlign: 'center'
  },
  completedStatus: {
    color: theme.successColor
  },
  actionsCell: {
    verticalAlign: 'middle',
    lineHeight: '1',
    textAlign: 'right',
    width: '80px'
  },
  lastAddedRow: {
    border: `1px solid ${theme.accentColor}`
  },
  headerCell: {
    minWidth: '150px'
  }
}));

const EmptyValuePlaceholder = () => (
  <span dangerouslySetInnerHTML={{ __html: '&mdash;' }} />
);

const Table = () => {
  const i18n = useI18n();
  const classes = useStyles();
  const history = useHistory();
  const { clientId, clientType } = useParams<any>();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const defaultConfirmationStoreState =
    defaultConfirmation.useConfirmationStore.getState();
  const clientPageStore = useClientPageStore();
  const adviceSessions = useAdviceSessions();
  const copyAdviceSession = useCopyAdviceSession();
  const deleteAdviceSession = useDeleteAdviceSession();
  const tableRef = React.useRef<any>();
  const tableContainerRef = React.useRef<any>();
  const [editedSession, setEditedSession] =
    React.useState<AdviceSessionView | null>(null);
  const dispatch = useDispatch();
  const [isCustomDownloadModalOpen, setIsCustomDownloadModalOpen] =
    React.useState(false);
  const readReportDocuments = useReadReportDocuments();
  const [selectedSessionId, setSelectedSessionId] = React.useState<
    string | undefined
  >();
  const downloadReportDocuments = useDownloadReportDocuments();
  const { availableDocuments } = useCustomDownloadStore();
  const {
    adviceFlows,
    roboAdvice: {
      adviceSessionList: { showAdviceOwner }
    }
  } = useCustomerConfig();
  const [idAdviceSessionMenuOpen, setIdAdviceSessionMenuOpen] = React.useState<
    string | null
  >(null);
  const tabs = useTabs();
  const orderExecutionTabs = useOrderExecutionTabs();

  const closeEditSessionPopup = () => setEditedSession(null);

  const onRowClick = (session: AdviceSessionView) => {
    if (session.id === getLastAddedSessionHighlight()) {
      removeLastAddedSessionHighlight();
    }

    if (session.originalStatus === SessionStatuses.complete) {
      dispatch(
        notificationActionCreators.showNotification({
          message: i18n('roboAdvice.clientList.sessionBlocked'),
          type: NotificationTypes.error
        })
      );
    } else if (session.adviceType === OrderExecutionType) {
      history.push(
        routeTemplates.roboAdviceOrderExecution.build(
          clientType,
          clientId,
          session.id,
          orderExecutionTabs?.[0]?.pageId ||
            RoboAdviceOrderExecutionPages.orderInformation
        ),
        { sessionName: session.name, clientName: clientPageStore.clientName }
      );
    } else {
      history.push(
        routeTemplates.roboAdviceAdviceSession.build(
          clientType,
          clientId,
          session.id,
          tabs.length > 0
            ? tabs[0].pageId
            : RoboAdviceAdviceSessionPages.adviceInformation
        ),
        { sessionName: session.name, clientName: clientPageStore.clientName }
      );
    }
  };

  const updateDocuments = async sessionId => {
    const oldDocuments = availableDocuments[sessionId];
    const docs = await readReportDocuments(sessionId);
    if (docs && !R.equals(docs, oldDocuments)) {
      useCustomDownloadStore.getState().addAvailableDocuments(sessionId, docs);
    }
  };

  const getLabelOfAdviceType = adviceType => {
    const flowType = adviceFlows.find(type => type.name === adviceType);
    return flowType ? getTranslation(flowType.label) : adviceType;
  };

  const headers = [
    {
      title: i18n('roboAdvice.client.adviceSessionsTable.name'),
      className: classes.headerCell
    },
    {
      title: i18n('roboAdvice.client.adviceSessionsTable.created'),
      className: classes.headerCell
    },
    {
      title: i18n('roboAdvice.client.adviceSessionsTable.lastActivityDate'),
      className: classes.headerCell
    },
    {
      title: i18n('roboAdvice.client.adviceSessionsTable.channel'),
      className: classes.headerCell
    },
    {
      title: i18n('roboAdvice.client.adviceSessionsTable.status'),
      className: classes.headerCell
    },
    {
      title: i18n('roboAdvice.client.adviceSessionsTable.adviceType'),
      className: classes.headerCell
    },
    showAdviceOwner && {
      title: i18n('roboAdvice.client.adviceSessionsTable.adviceOwner'),
      className: classes.headerCell
    },
    {
      title: '',
      className: classes.actionsCell
    }
  ].filter(Boolean);

  return (
    <>
      <AnyTableComponent
        forwardedTableRef={tableRef}
        forwardedTableContainerRef={tableContainerRef}
        header={headers}
        isLoading={clientPageStore.isReadAdviceSessionsPending}
      >
        <AnyTableBody
          noDataTitle={i18n(
            'roboAdvice.client.adviceSessionsTable.noAdviceSessions'
          )}
          cellsNumber={showAdviceOwner ? 8 : 7}
        >
          {adviceSessions.map((s, index) => {
            const createMenuItems = ({ toggleMenu }) => {
              if (idAdviceSessionMenuOpen && idAdviceSessionMenuOpen !== s.id) {
                toggleMenu(false);
              }

              let menuItems = [
                {
                  title: i18n('shared.copy'),
                  iconType: '',
                  onClick: e => {
                    e.preventDefault();

                    copyAdviceSession(s.id, v4());

                    toggleMenu(false);
                  }
                },
                {
                  title: i18n('shared.delete'),
                  iconType: '',
                  onClick: e => {
                    e.preventDefault();

                    defaultConfirmationStoreState.open(
                      i18n(
                        'roboAdvice.client.adviceSessionsTable.deleteAdviceSessionConfirmationMessage'
                      ).replace(
                        '{0}',
                        R.trim(s.name || '') ||
                          i18n(
                            'roboAdvice.client.adviceSessionsTable.defaultAdviceSessionName'
                          )
                      ),
                      () => {
                        deleteAdviceSession(s.id);
                      }
                    );

                    toggleMenu(false);
                  }
                }
              ];

              if (s.originalStatus !== SessionStatuses.complete) {
                menuItems = [
                  {
                    title: i18n('shared.rename'),
                    iconType: '',
                    onClick: e => {
                      e.preventDefault();
                      setEditedSession(s);
                      toggleMenu(false);
                    }
                  },
                  ...menuItems
                ];
              }

              const documents = availableDocuments[s.id];
              if (documents && documents.length) {
                menuItems = [
                  {
                    title: i18n(
                      'roboAdvice.client.adviceSessionsTable.downloadReport'
                    ),
                    iconType: '',
                    onClick: e => {
                      e.preventDefault();
                      downloadReportDocuments(
                        s.id,
                        availableDocuments[s.id]?.filter(
                          a => a.documentType === 'report'
                        )
                      );
                      toggleMenu(false);
                    }
                  },
                  {
                    title: i18n(
                      'roboAdvice.client.adviceSessionsTable.downloadReportWithAttachments'
                    ),
                    iconType: '',
                    onClick: e => {
                      e.preventDefault();
                      downloadReportDocuments(s.id, availableDocuments[s.id]);
                      toggleMenu(false);
                    }
                  },
                  {
                    title: i18n(
                      'roboAdvice.client.adviceSessionsTable.customDownload'
                    ),
                    iconType: '',
                    onClick: e => {
                      e.preventDefault();
                      setSelectedSessionId(s.id);
                      setIsCustomDownloadModalOpen(true);
                      toggleMenu(false);
                    }
                  },
                  ...menuItems
                ];
              } else {
                menuItems = [
                  {
                    title: i18n(
                      'roboAdvice.client.adviceSessionsTable.downloadReport'
                    ),
                    disabled: true
                  },
                  ...menuItems
                ] as any;
              }

              return menuItems;
            };

            return (
              <React.Fragment key={s.id}>
                <AnyTableBodyRow
                  hoverEffect
                  index={index}
                  onClick={() => onRowClick(s)}
                  className={
                    getLastAddedSessionHighlight() === s.id
                      ? classes.lastAddedRow
                      : null
                  }
                >
                  {({ bodyRowCellClassName }) => (
                    <>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.cell
                        )}
                      >
                        {s.name}
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.cell
                        )}
                      >
                        {formatDate(cultureCode, s.created)}
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.cell
                        )}
                      >
                        {formatDate(cultureCode, s.lastActivityDate)}
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.cell
                        )}
                      >
                        {s.channelKey
                          ? i18n(s.channelKey)
                          : s.channel || <EmptyValuePlaceholder />}
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.cell,
                          {
                            [classes.completedStatus]:
                              s.originalStatus === SessionStatuses.complete
                          }
                        )}
                      >
                        {s.status}
                      </td>
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.cell
                        )}
                      >
                        {getLabelOfAdviceType(s.adviceType)}
                      </td>
                      {showAdviceOwner && (
                        <td
                          className={classNames(
                            bodyRowCellClassName,
                            classes.cell
                          )}
                        >
                          {s.advisor?.name}
                        </td>
                      )}
                      <td
                        className={classNames(
                          bodyRowCellClassName,
                          classes.cell,
                          classes.actionsCell
                        )}
                      >
                        <AnyTableRowMenu
                          tableRef={tableRef}
                          tableContainerRef={tableContainerRef}
                          createMenuItems={({ toggleMenu }) => {
                            updateDocuments(s.id);

                            return createMenuItems({ toggleMenu });
                          }}
                          setIdOpenModal={setIdAdviceSessionMenuOpen}
                          currentId={s.id}
                        />
                      </td>
                    </>
                  )}
                </AnyTableBodyRow>
              </React.Fragment>
            );
          })}
        </AnyTableBody>
      </AnyTableComponent>

      <SessionRenamePopup
        session={editedSession}
        close={closeEditSessionPopup}
      ></SessionRenamePopup>
      <CustomDownloadPopup
        isModalOpen={isCustomDownloadModalOpen}
        setIsModalOpen={setIsCustomDownloadModalOpen}
        selectedSessionId={selectedSessionId}
      />
    </>
  );
};

export default Table;
