import { z } from 'zod';

export const historicalReturnStatsItemSchema = z.object({
  enabled: z.boolean(),
  id: z.string(),
  label: z.record(z.string()),
  order: z.number()
});

export const analyticsComponentsSchema = z.object({
  historicalReturnStatsItems: z.array(historicalReturnStatsItemSchema),
  isPortfolioHidden: z.boolean(),
  isExpectedPathHidden: z.boolean(),
  isExpectedValueHidden: z.boolean(),
  isCostHidden: z.boolean(),
  isEfficientFrontierHidden: z.boolean(),
  isHistoricalReturnHidden: z.boolean(),
  isOrderSummaryHidden: z.boolean(),
  isSustainabilityHidden: z.boolean(),
  isCashflowHidden: z.boolean(),
  order: z.object({
    portfolio: z.number(),
    historicalReturn: z.number(),
    expectedPath: z.number(),
    expectedValue: z.number(),
    efficientFrontier: z.number(),
    cost: z.number(),
    cashflow: z.number(),
    sustainability: z.number(),
    orderSummary: z.number(),
    transactionList: z.number().optional()
  }),
  showStatsInHistoricalReturn: z.boolean(),
  isTransactionListHidden: z.boolean(),
  transactionListColumns: z.object({
    isin: z.object({ enabled: z.boolean() }),
    existingPortfolio: z.object({ enabled: z.boolean() }),
    toAdvisory: z.object({ enabled: z.boolean() }),
    targetValue: z.object({ enabled: z.boolean() }),
    transactionValue: z.object({ enabled: z.boolean() }),
    action: z.object({ enabled: z.boolean() })
  }),
  expectedValue: z.object({
    compareWithBank: z.boolean(),
    bankReturn: z.number()
  })
});

export type HistoricalReturnStatsItem = z.infer<
  typeof historicalReturnStatsItemSchema
>;

export type AnalyticsComponents = z.infer<typeof analyticsComponentsSchema>;

export const defaultAnalyticsComponents: AnalyticsComponents = {
  historicalReturnStatsItems: [
    {
      enabled: true,
      id: 'totalReturn',
      label: {
        en: 'Total return',
        no: 'Total avkastning'
      },
      order: 1
    },
    {
      enabled: true,
      id: 'cagr',
      label: {
        en: 'Cagr',
        no: 'Annualisert avkastning'
      },
      order: 2
    },
    {
      enabled: true,
      id: 'maxDrawdown',
      label: {
        en: 'Max drawdown',
        no: 'Maksimal nedside'
      },
      order: 3
    },
    {
      enabled: true,
      id: 'bestYear',
      label: {
        en: 'Best year',
        no: 'Beste år'
      },
      order: 4
    },
    {
      enabled: true,
      id: 'worstYear',
      label: {
        en: 'Worst year',
        no: 'Verste år '
      },
      order: 5
    },
    {
      enabled: true,
      id: 'bestMonth',
      label: {
        en: 'Best month',
        no: 'Beste måned'
      },
      order: 6
    },
    {
      enabled: true,
      id: 'worstMonth',
      label: {
        en: 'Worst month',
        no: 'Verste måned'
      },
      order: 7
    },
    {
      enabled: true,
      id: 'monthlySharpe',
      label: {
        en: 'Monthly sharpe',
        no: 'Månedlig sharpe'
      },
      order: 8
    },
    {
      enabled: true,
      id: 'yearlySharpe',
      label: {
        en: 'Yearly sharpe',
        no: 'Årlig sharpe'
      },
      order: 9
    },
    {
      enabled: true,
      id: 'monthlySortino',
      label: {
        en: 'Monthly sortino',
        no: 'Månedlig sortino'
      },
      order: 10
    },
    {
      enabled: true,
      id: 'yearlySortino',
      label: {
        en: 'Yearly sortino',
        no: 'Årlig sortino'
      },
      order: 11
    },
    {
      enabled: true,
      id: 'monthlyMean',
      label: {
        en: 'Monthly mean',
        no: 'Månedlig snittavkastning'
      },
      order: 12
    },
    {
      enabled: true,
      id: 'yearlyMean',
      label: {
        en: 'Yearly mean',
        no: 'Årlig snittavkastning'
      },
      order: 13
    },
    {
      enabled: true,
      id: 'averageDrawdown',
      label: {
        en: 'Average drawdown',
        no: 'Gjennomsnittlig nedside'
      },
      order: 14
    },
    {
      enabled: true,
      id: 'averageDrawdownDays',
      label: {
        en: 'Average drawdown days',
        no: 'Gjennomsnittlig daglig nedside'
      },
      order: 15
    },
    {
      enabled: true,
      id: 'averageDownMonth',
      label: {
        en: 'Average down month',
        no: 'Gjennomsnittlig månedlig nedside '
      },
      order: 16
    },
    {
      enabled: true,
      id: 'averageUpMonth',
      label: {
        en: 'Average up month',
        no: 'Gjennomsnittlig månedlig oppside'
      },
      order: 17
    },
    {
      enabled: true,
      id: 'winYearPercent',
      label: {
        en: 'Win year percent',
        no: '%-andel positive år'
      },
      order: 18
    },
    {
      enabled: true,
      id: 'twelveMonthWinPercent',
      label: {
        en: '12 month win percent',
        no: '%-andel positiv årlig avkastning'
      },
      order: 19
    }
  ],
  isPortfolioHidden: false,
  isExpectedPathHidden: false,
  isExpectedValueHidden: false,
  isCostHidden: false,
  isEfficientFrontierHidden: false,
  isHistoricalReturnHidden: false,
  isOrderSummaryHidden: true,
  isSustainabilityHidden: true,
  isCashflowHidden: true,
  order: {
    portfolio: 1,
    historicalReturn: 2,
    expectedPath: 3,
    expectedValue: 4,
    efficientFrontier: 5,
    cost: 6,
    cashflow: 7,
    sustainability: 8,
    orderSummary: 9
  },
  showStatsInHistoricalReturn: false,
  expectedValue: {
    compareWithBank: true,
    bankReturn: 0.01
  },
  isTransactionListHidden: true,
  transactionListColumns: {
    isin: { enabled: true },
    existingPortfolio: { enabled: true },
    toAdvisory: { enabled: true },
    targetValue: { enabled: true },
    transactionValue: { enabled: true },
    action: { enabled: true }
  }
};
