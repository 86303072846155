import * as R from 'ramda';
import { all, call, select, takeLatest } from 'redux-saga/effects';

import { usePageStore as useAdvisoryPageStore } from '../../../advisory/services/pageStore';
import { form } from '../../../form/services/form';
import { getRisk } from '../../../shared/api';
import { getAvailablePortfoliosResult } from '../../../shared/services/availablePortfoliosSelectors';
import { useGoalsStore } from '../../../shared/services/goalsStore';
import { types } from '../actions.js';
import { usePageStore as useProposalPageStore } from 'features/roboAdvice/adviceSession/proposal/services/pageStore';
import { getRiskHorizonValue } from 'features/roboAdvice/adviceSession/proposal/services/selectors.js';
import { getIsReadRiskScoreRequired } from 'features/roboAdvice/adviceSession/riskScore/services/selectors.js';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { getI18n } from 'features/sharedModules/customerConfig/services/selectors.js';
import { runRestOperation } from 'features/sharedModules/rest/services/sagas.js';

export function* watchReadRiskScore() {
  yield takeLatest(
    data =>
      data.type === types.FIELD_CHANGED &&
      getIsReadRiskScoreRequired(data.meta.field) &&
      data.meta.updateGoal,
    function* (data) {
      yield call(readRiskScore, data);
    }
  );
}

export const mapPurposeAndRiskScoreClientDataToServer = (
  adviceSessionFormValues,
  timeHorizon,
  timeHorizonType,
  items
) => {
  return {
    horizon: getRiskHorizonValue(timeHorizon, timeHorizonType, items),
    risk1: adviceSessionFormValues.expectationOfRisk,
    risk2: adviceSessionFormValues.riskStrategy
  };
};

export const mapKnowledgeAndExperienceScoreClientDataToServer = (
  adviceSessionFormValues,
  timeHorizon,
  timeHorizonType,
  questions,
  items
) => {
  const questionsParams = {};
  questions.forEach(question => {
    if (question.riskApiParam) {
      questionsParams[question.riskApiParam] =
        adviceSessionFormValues[question.name];
    }
  });

  return {
    horizon: R.isNil(timeHorizon)
      ? null
      : getRiskHorizonValue(timeHorizon, timeHorizonType, items),
    risk1: adviceSessionFormValues.expectationOfRisk,
    risk2: adviceSessionFormValues.riskStrategy,
    ...questionsParams
  };
};

export const mapRiskScoreServerDataToClient = (
  purposeAndRiskData,
  knowledgeAndExperienceData
) => {
  return {
    data: {
      purposeAndRiskScore: R.isNil(purposeAndRiskData)
        ? null
        : purposeAndRiskData.risk,
      riskScore: R.isNil(knowledgeAndExperienceData)
        ? null
        : knowledgeAndExperienceData.risk
    }
  };
};

export function validateReadRiskScoreData() {
  const adviceSessionFormState = form.getState();
  const { goals } = useGoalsStore.getState();

  return (
    goals.length && R.isNil(adviceSessionFormState.errors.expectationOfRisk)
  );
}

export function* sendReadRiskScoreRequest(data) {
  const changedFieldName = data?.meta?.field;
  const adviceSessionFormState = form.getState();
  const updateGoal = data?.meta?.updateGoal;
  const { goals } = useGoalsStore.getState();
  const advisoryPageStore = useAdvisoryPageStore.getState();
  const proposalPageStore = useProposalPageStore.getState();
  const customerConfig = yield select(sessionSelectors.getCustomerConfig);
  const {
    riskEndpointType,
    timeHorizonConfig: { type: timeHorizonType, items },
    roboAdviceForm: {
      knowledgeAndExperienceQuestions: { questions }
    }
  } = customerConfig;

  const questionsNames = questions.map(({ name }) => name);

  const isReadKnowledgeAndExperienceScoreFormValid = questionsNames.some(name =>
    R.isNil(adviceSessionFormState.errors[name])
  );

  const auth0AccessToken = yield select(sessionSelectors.getAuth0AccessToken);
  const accessToken = yield call(getQAuthAccessToken, auth0AccessToken);

  for (const goal of goals) {
    const purposeAndRiskServerData = yield call(
      mapPurposeAndRiskScoreClientDataToServer,
      adviceSessionFormState.values,
      goal.data.timeHorizon,
      timeHorizonType,
      items
    );

    const knowledgeAndExperienceServerData =
      isReadKnowledgeAndExperienceScoreFormValid
        ? yield call(
            mapKnowledgeAndExperienceScoreClientDataToServer,
            adviceSessionFormState.values,
            goal.data.timeHorizon,
            timeHorizonType,
            questions,
            items
          )
        : null;

    const [purposeAndRiskResponse, knowledgeAndExperienceResponse] = yield all([
      call(getRisk, accessToken, purposeAndRiskServerData, riskEndpointType),
      isReadKnowledgeAndExperienceScoreFormValid
        ? call(
            getRisk,
            accessToken,
            knowledgeAndExperienceServerData,
            riskEndpointType
          )
        : null
    ]);

    const clientData = yield call(
      mapRiskScoreServerDataToClient,
      purposeAndRiskResponse.data,
      isReadKnowledgeAndExperienceScoreFormValid
        ? knowledgeAndExperienceResponse.data
        : null
    );

    if (
      clientData?.data?.riskScore &&
      clientData.data.riskScore !== goal.data.riskScore
    ) {
      const availablePortfolios = getAvailablePortfoliosResult({
        advisoryPageStore,
        customerConfig,
        riskScore: clientData.data.riskScore
      });
      const matchingPortfolio = availablePortfolios.find(
        p => p.key === clientData.data.riskScore
      );

      clientData.data = {
        ...clientData.data,
        portfolio: matchingPortfolio
          ? matchingPortfolio.key
          : availablePortfolios[0]?.key
      };

      const fieldNameWhichChangedRiskScore =
        changedFieldName === 'timeHorizon'
          ? `timeHorizon_${goal.goalId}`
          : changedFieldName;

      useSessionStore
        .getState()
        .addFieldWhichChangedRiskScore(fieldNameWhichChangedRiskScore);
      proposalPageStore.setPageStatus('readPortfolioChartData', undefined);
    }

    yield call(updateGoal, goal.goalId, clientData);
  }
}

export function* readRiskScore(data) {
  const i18n = yield select(getI18n);

  yield call(runRestOperation, {
    validateData: validateReadRiskScoreData,
    doDispatchFailedActionOnValidationError: true,
    sendRequest: () => sendReadRiskScoreRequest(data),
    errorNotificationMessage: i18n(
      'roboAdvice.notifications.readRiskScoreFailed'
    )
  });
}
