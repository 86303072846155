import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { usePageStore as useAdvisoryPageStore } from '../../advisory/services/pageStore';
import { riskScoreToNumber } from '../../riskScore/services/shared';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { AvailablePortfoliosModes } from '../constants';
import store from 'features/main/app/store.js';
import sessionSelectors from 'features/shared/services/session/selectors';

export function useAvailablePortfolios(goalId) {
  const advisoryPageStore = useAdvisoryPageStore();
  const customerConfig = useSelector(sessionSelectors.getCustomerConfig);
  const riskScore = useGoalsStore().getGoalRiskScore(goalId);

  return getAvailablePortfoliosResult({
    advisoryPageStore,
    customerConfig,
    riskScore
  });
}
export function getAvailablePortfolios(goalId) {
  const advisoryPageStore = useAdvisoryPageStore.getState();
  const reduxStoreState = store.getState();
  const customerConfig = sessionSelectors.getCustomerConfig(reduxStoreState);
  const riskScore = useGoalsStore.getState().getGoalRiskScore(goalId);

  return getAvailablePortfoliosResult({
    advisoryPageStore,
    customerConfig,
    riskScore
  });
}

export function usePortfolioRisk(goalId) {
  const advisoryPageStore = useAdvisoryPageStore();
  const riskScore = useGoalsStore().getGoalPortfolioRiskScore(goalId);

  return advisoryPageStore.portfolios
    .map(p => ({ key: p.id, title: p.title }))
    .find(
      portfolio =>
        riskScoreToNumber(portfolio.key) === riskScoreToNumber(riskScore)
    );
}
export function getAvailablePortfoliosResult({
  advisoryPageStore,
  customerConfig,
  riskScore
}) {
  const portfolioOptions = R.map(
    p => ({ key: p.id, title: p.title }),
    advisoryPageStore.portfolios
  );
  const { advisoryComponents } = customerConfig;
  const { availablePortfoliosMode, availablePortfoliosMapping } =
    advisoryComponents || {};

  switch (availablePortfoliosMode) {
    case AvailablePortfoliosModes.all:
      return portfolioOptions;
    case AvailablePortfoliosModes.linkedToFinalScore:
      return portfolioOptions.filter(
        portfolio =>
          riskScoreToNumber(portfolio.key) === riskScoreToNumber(riskScore)
      );
    case AvailablePortfoliosModes.equalOrLower:
      return portfolioOptions.filter(
        portfolio =>
          riskScoreToNumber(portfolio.key) <= riskScoreToNumber(riskScore)
      );
    case AvailablePortfoliosModes.custom:
      const portfoliosForRiskScore =
        availablePortfoliosMapping[riskScoreToNumber(riskScore)];
      if (portfoliosForRiskScore) {
        return portfolioOptions.filter(portfolio =>
          portfoliosForRiskScore.includes(riskScoreToNumber(portfolio.key))
        );
      } else {
        return portfolioOptions;
      }
    default:
      return portfolioOptions;
  }
}
