import { ReactNode, useState } from 'react';

import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { FundAllocationRow } from '../../types';
import useFundAllocationOverviewData from './useFundAllocationOverviewData';
import useFundAllocationPreferenceCriteriaData from './useFundAllocationPreferenceCriteriaData';
import useFundAllocationSustainabilityData from './useFundAllocationSustainabilityData';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import { ProductAttributeType } from 'features/roboAdvice/adviceSession/shared/constants';
import { Goal } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { FundAllocationTabs } from 'features/roboAdvice/shared/constants';
import { filterNil } from 'features/shared/utils/filters';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  numberCell: {
    textAlign: 'right'
  },
  center: {
    textAlign: 'center'
  }
});

type Arguments = {
  selectedGoal: Goal;
  fundAllocationTab: keyof typeof FundAllocationTabs;
};

export const useFundAllocationBaseData = ({
  selectedGoal,
  fundAllocationTab
}: Arguments) => {
  const classes = useStyles();
  const { assetClasses } = useCustomPortfolioStore();
  const [assetEdit, setAssetEdit] = useState<Record<string, boolean>>({});
  const {
    advisoryComponents: {
      customPortfolio: { fundAllocation }
    }
  } = useCustomerConfig();
  const sessionStoreState = useSessionStore.getState();

  const {
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5,
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5
  } = fundAllocation;

  const productAttributeBinaries = [
    productAttributeBinary1,
    productAttributeBinary2,
    productAttributeBinary3,
    productAttributeBinary4,
    productAttributeBinary5
  ]
    .map(
      (productAttribute, index) =>
        productAttribute && {
          name: `productAttributeBinary${index + 1}`,
          ...productAttribute
        }
    )
    .filter(filterNil)
    .filter(({ location }) => location === fundAllocationTab);

  const productAttributeTxts = [
    productAttributeTxt1,
    productAttributeTxt2,
    productAttributeTxt3,
    productAttributeTxt4,
    productAttributeTxt5
  ]
    .map(
      (productAttribute, index) =>
        productAttribute && {
          name: `productAttributeTxt${index + 1}`,
          ...productAttribute
        }
    )
    .filter(filterNil)
    .filter(({ location }) => location === fundAllocationTab);

  const productAttributes = [
    ...productAttributeTxts.map(attr => ({
      ...attr,
      className: classes.numberCell,
      type: ProductAttributeType.text
    })),
    ...productAttributeBinaries.map(attr => ({
      ...attr,
      className: classes.center,
      type: ProductAttributeType.binary
    }))
  ].sort((a, b) => a.order - b.order);

  const namespaceId =
    selectedGoal.data.productPlatformNamespace ||
    sessionStoreState.defaultConfigNamespaceId;

  const { overviewTableHeaders, createOverviewTableData } =
    useFundAllocationOverviewData({
      productAttributes,
      namespaceId: namespaceId!
    });
  const {
    sustainabilityTableHeaders,
    sustainabilityFirstRows,
    createSustainabilityTableData
  } = useFundAllocationSustainabilityData({
    productAttributes,
    namespaceId: namespaceId!
  });
  const { preferenceCriteriaTableHeaders, createPreferenceCriteriaTableData } =
    useFundAllocationPreferenceCriteriaData({
      productAttributes,
      namespaceId: namespaceId!
    });

  const baseFundAllocationRows: FundAllocationRow[] = assetClasses
    .filter(allAssetClass =>
      (selectedGoal.data.customPortfolio || []).find(
        goalAssetClass => goalAssetClass.CategoryId === allAssetClass.categoryId
      )
    )
    .map((asset, index) => {
      const goalInstruments =
        (selectedGoal.data.customPortfolio || []).find(
          goalAssetClass => goalAssetClass.CategoryId === asset.categoryId
        )?.instruments ?? [];
      const instruments = goalInstruments.map(({ title, weight, id }) => ({
        id,
        name: title,
        instrumentWeight: weight
      }));

      const allAvailableInstruments = (
        assetClasses.find(
          assetClass => assetClass.categoryId === asset.categoryId
        )?.availableInstruments ?? []
      )
        .filter(({ id }) => !instruments.find(i => i.id === id))
        .map(({ title, id }) => ({
          id,
          name: title,
          instrumentWeight: 0
        }));

      return {
        ...asset,
        weight: (selectedGoal.data.customPortfolio || []).find(
          goalAssetClass => goalAssetClass.CategoryId === asset.categoryId
        )?.weight,
        instruments: assetEdit[asset.categoryId]
          ? [...instruments, ...allAvailableInstruments].sort((a, b) =>
              a.name.localeCompare(b.name)
            )
          : instruments.sort((a, b) => a.name.localeCompare(b.name)),
        isEditing: !!assetEdit[asset.categoryId],
        allAvailableInstruments,
        index
      };
    })
    .filter(({ weight }) => weight && weight > 0);

  let fundAllocationRows: FundAllocationRow[] = baseFundAllocationRows;
  let additionalTableHeaders: {
    title?: string;
    component?: ReactNode;
    className?: string;
  }[] = [];
  let additionalFirstRows: ReactNode | undefined;

  switch (fundAllocationTab) {
    case FundAllocationTabs.overview:
      fundAllocationRows = createOverviewTableData(baseFundAllocationRows);
      additionalTableHeaders = overviewTableHeaders;
      break;
    case FundAllocationTabs.sustainability:
      fundAllocationRows = createSustainabilityTableData(
        baseFundAllocationRows
      );
      additionalTableHeaders = sustainabilityTableHeaders;
      additionalFirstRows = sustainabilityFirstRows;
      break;
    case FundAllocationTabs.preferenceCriteria:
      fundAllocationRows = createPreferenceCriteriaTableData(
        baseFundAllocationRows
      );
      additionalTableHeaders = preferenceCriteriaTableHeaders;
      break;
    default:
      const exhaustiveCheck: never = fundAllocationTab;
      throw new Error(exhaustiveCheck);
  }

  return {
    fundAllocationRows,
    additionalTableHeaders,
    additionalFirstRows,
    assetEdit,
    setAssetEdit
  };
};
