import create from 'zustand';

import {
  ReadAnalyzeAdvancedSuitabilityConfigResponse,
  ReadAnalyzeAdvancedSuitabilityStatusResponse
} from '../../shared/api/types';
import { PageStatus } from '../../shared/components/useReadDataListener';

type KnowledgeAndExperienceStoreData = {
  advancedSuitabilityConfig: ReadAnalyzeAdvancedSuitabilityConfigResponse | null;
  advancedSuitabilityStatus: Record<
    number,
    ReadAnalyzeAdvancedSuitabilityStatusResponse
  >;
  advancedSuitabilityStatusDataStatuses: Record<number, PageStatus>;
};

type KnowledgeAndExperienceStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<KnowledgeAndExperienceStoreData>) => void;
  addAdvancedSuitabilityConfigData: (
    data: ReadAnalyzeAdvancedSuitabilityConfigResponse
  ) => void;
  addAdvancedSuitabilityStatusData: (
    data: ReadAnalyzeAdvancedSuitabilityStatusResponse,
    namespaceId: number
  ) => void;
  changeAdvancedSuitabilityStatusDataStatus: (
    status: PageStatus,
    namespaceId: number
  ) => void;
  resetAdvancedSuitabilityStatusData: () => void;
};

type KnowledgeAndExperienceStoreState = KnowledgeAndExperienceStoreData &
  KnowledgeAndExperienceStoreApi;

const defaultData: KnowledgeAndExperienceStoreData = {
  advancedSuitabilityConfig: null,
  advancedSuitabilityStatus: {},
  advancedSuitabilityStatusDataStatuses: {}
};

export const useKnowledgeAndExperienceStore =
  create<KnowledgeAndExperienceStoreState>(set => {
    return {
      ...defaultData,
      reset: () => {
        set(defaultData);
      },
      initialize(initialData) {
        set(
          state => ({
            ...state,
            ...initialData
          }),
          true
        );
      },
      addAdvancedSuitabilityConfigData: data => {
        set(state => ({
          ...state,
          advancedSuitabilityConfig: data
        }));
      },
      addAdvancedSuitabilityStatusData: (data, namespaceId) => {
        set(state => ({
          ...state,
          advancedSuitabilityStatus: {
            ...state.advancedSuitabilityStatus,
            [namespaceId]: data
          }
        }));
      },
      changeAdvancedSuitabilityStatusDataStatus: (status, namespaceId) => {
        set(state => ({
          ...state,
          advancedSuitabilityStatusDataStatuses: {
            ...state.advancedSuitabilityStatusDataStatuses,
            [namespaceId]: status
          }
        }));
      },
      resetAdvancedSuitabilityStatusData: () => {
        set(state => ({
          ...state,
          advancedSuitabilityStatus: {},
          advancedSuitabilityStatusDataStatuses: {}
        }));
      }
    };
  });
