import { useEffect } from 'react';

import { useCostChartStore } from 'features/roboAdvice/adviceSession/costChart';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { selectors } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import { prepareCostChartData } from 'features/shared/analyticsComponents/costChart/mapping';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';

export const useInitPage = () => {
  const i18n = useI18n();
  const selectedGoalsIds = useGoalsStore<string[]>(
    selectors.getSelectedGoalsIds
  );
  const costChartStore = useCostChartStore();

  useEffect(() => {
    const selectedGoalsData = costChartStore.goalsData.filter(({ goalId }) =>
      selectedGoalsIds.includes(goalId)
    );

    const selectedOriginalGoalsData = costChartStore.originalGoalsData.filter(
      ({ goalId }) => selectedGoalsIds.includes(goalId)
    );

    if (selectedGoalsData.length > 0) {
      const {
        summary,
        chartData,
        firstYearMerged,
        lastYearMerged,
        fundDetails
      } = prepareCostChartData({ selectedGoalsData, i18n });

      costChartStore.setCostData({
        chartData,
        tableData: {
          firstYear: firstYearMerged,
          fundDetails,
          lastYear: lastYearMerged,
          summary
        }
      });
    }

    if (selectedOriginalGoalsData.length > 0) {
      const { summary, firstYearMerged, lastYearMerged, fundDetails } =
        prepareCostChartData({
          selectedGoalsData: selectedOriginalGoalsData,
          i18n
        });

      costChartStore.setOriginalTableData({
        originalTableData: {
          firstYear: firstYearMerged,
          fundDetails,
          lastYear: lastYearMerged,
          summary
        }
      });
    }
  }, [
    costChartStore.goalsData,
    costChartStore.originalGoalsData,
    selectedGoalsIds
  ]);
};

export default useInitPage;
