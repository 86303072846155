import { createForm } from 'features/sharedModules/finalForm/services/finalForm';
import { createUseFinalFormAdapter } from 'features/sharedModules/zustand/components/finalFormAdapter';

export const customDownloadForm = createForm({
  onSubmit: () => {}
});

export const useCustomDownloadForm = createUseFinalFormAdapter({
  form: customDownloadForm
});
