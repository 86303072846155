import axios, { CancelTokenSource } from 'axios';
import {
  isNil,
  isEmpty,
  pipe,
  reduce,
  pathOr,
  filter,
  map,
  includes,
  propOr,
  assoc,
  equals
} from 'ramda';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  getClientSessions,
  getInvestorInformation
} from '../../../client/main/api';
import {
  getClientName,
  mapServerInvestorInformationToClient
} from '../../../shared/services/mapping';
import { useReadAdvisoryGoalsData } from '../../advisory/components/useReadAdvisoryGoalsData';
import { usePageStore as useAdvisoryPageStore } from '../../advisory/services/pageStore';
import { Suitability, form as roboAdviceForm } from '../../form/services/form';
import { useReadAdvancedSuitabilityConfig } from '../../knowledgeAndExperience/components/useReadAdvancedSuitabilityConfig';
import { useReadAdvancedSuitabilityStatus } from '../../knowledgeAndExperience/components/useReadAdvancedSuitabilitysStatus';
import { advancedSuitabilityTables } from '../../knowledgeAndExperience/constants';
import { useKnowledgeAndExperienceStore } from '../../knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { costForm } from '../../proposal/services/costForm';
import { usePageStore as useProposalPageStore } from '../../proposal/services/pageStore';
import { useReadGoals } from '../../purposeAndRisk/components/useReadGoals';
import { useUpdateGoal } from '../../purposeAndRisk/components/useUpdateGoal';
import { creators as riskScoreCreators } from '../../riskScore/services/actions';
import {
  AdviceSession,
  useSessionStore
} from '../../session/services/sessionStore';
import {
  getCategoriesAll,
  getNamespaceList,
  getRoboPortfolioMeta,
  getRoboSelection,
  getSessionData
} from '../../shared/api';
import { SessionNamespaces } from '../../shared/constants';
import { useGoalsStore } from '../../shared/services/goalsStore';
import { PortfolioRoles } from './../../session/services/sessionStore';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { clientForm } from 'features/roboAdvice/client/main/services/clientForm';
import { GetClientSessionsResponse } from 'features/roboAdvice/client/main/types';
import {
  OrderExecutionType,
  RoboAdviceClientPages,
  SessionStatuses
} from 'features/roboAdvice/shared/constants';
import { getQAuthAccessToken } from 'features/shared/api';
import { NotificationTypes } from 'features/shared/constants/notification.js';
import {
  AdviceSessionParams,
  NamespaceType
} from 'features/shared/constants/session';
import { creators as notificationActionCreators } from 'features/shared/services/notification/actions.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import routeTemplates from 'features/shared/utils/routeTemplates';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';

export function useReadAdviceSession() {
  const {
    clientType: clientTypeParam,
    clientId,
    adviceSessionId
  } = useParams<AdviceSessionParams>();
  const i18n = useI18n();
  const auth0AccessToken = useSelector(sessionSelectors.getAuth0AccessToken);
  const {
    customRiskTitles,
    roboOrderFlow: { namespaceId: orderFlowNamespaceId },
    roboAdviceForm: {
      knowledgeAndExperience: { advancedSuitability }
    }
  } = useCustomerConfig();
  const readAdvancedSuitabilityStatus = useReadAdvancedSuitabilityStatus();

  const dispatch = useDispatch();
  const readGoals = useReadGoals();
  const readAdvisoryGoalsData = useReadAdvisoryGoalsData();
  const readAdvancedSuitabilityConfig = useReadAdvancedSuitabilityConfig();
  const history = useHistory();
  const updateGoal = useUpdateGoal();
  const { state }: { state: { isCrmRedirect?: boolean } } = useLocation();

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const readAdviceSession = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const sessionStoreState = useSessionStore.getState();
    const advisoryPageStoreState = useAdvisoryPageStore.getState();
    const proposalPageStore = useProposalPageStore.getState();

    try {
      sessionStoreState.setIsReadAdviceSessionPending(true);

      const accessToken = await getQAuthAccessToken(
        auth0AccessToken,
        cancelTokenSource.token
      );

      const getClientSessionsResponse = await getClientSessions(
        accessToken,
        cancelTokenSource.token,
        {
          investor_id: clientId
        }
      );
      const clientAdviceSessions = mapServerClientAdviceSessionsToClient(
        getClientSessionsResponse.data
      );
      const currentAdviceSession = clientAdviceSessions.find(
        s => s.id === adviceSessionId
      );
      if (currentAdviceSession?.status === SessionStatuses.complete) {
        history.replace(
          routeTemplates.roboAdviceClient.build(
            clientTypeParam,
            clientId,
            RoboAdviceClientPages.adviceSessions
          )
        );

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n('roboAdvice.clientList.sessionBlocked'),
            type: NotificationTypes.error
          })
        );

        return;
      }
      const namespaceListResponse = await getNamespaceList(
        accessToken,
        undefined,
        NamespaceType.config
      );
      const fetchedNamespaces = namespaceListResponse?.data?.namespaces || [];
      const defaultConfigNamespaceId =
        currentAdviceSession?.adviceType === OrderExecutionType &&
        orderFlowNamespaceId
          ? orderFlowNamespaceId
          : fetchedNamespaces.find(n => n.is_default)?.namespace_id;

      const [
        getCategoriesAllResponse,
        getRoboPortfolioMetaResponse,
        getRoboSelectionResponse,
        getInvestorInformationResponse,
        getSessionDataResponse
      ] = await Promise.all([
        getCategoriesAll(accessToken, cancelTokenSource.token, {
          namespace_id: defaultConfigNamespaceId
        }),
        getRoboPortfolioMeta(accessToken, cancelTokenSource.token, {
          namespace_id: defaultConfigNamespaceId
        }),
        getRoboSelection(accessToken, cancelTokenSource.token, {
          namespace_id: defaultConfigNamespaceId
        }),
        getInvestorInformation(accessToken, cancelTokenSource.token, clientId),
        getSessionData(accessToken, cancelTokenSource.token, adviceSessionId),
        readGoals(),
        readAdvisoryGoalsData(),
        advancedSuitability.enabled
          ? readAdvancedSuitabilityConfig()
          : Promise.resolve()
      ]);

      const allCategories = getCategoriesAllResponse.data.Categories;

      const themes = mapServerThemesToClient(
        {
          Categories: allCategories.filter(
            ({ PortfolioRole }) =>
              PortfolioRole === PortfolioRoles.optional ||
              PortfolioRole === PortfolioRoles.theme
          )
        },
        getRoboPortfolioMetaResponse.data
      );
      const portfolios = mapServerPortfoliosToClient(
        i18n,
        getRoboPortfolioMetaResponse.data,
        customRiskTitles
      );

      const categoriesSelection = mapServerCategoriesSelectionToClient(
        getRoboSelectionResponse.data
      );

      const clientInformation = mapServerInvestorInformationToClient(
        getInvestorInformationResponse.data
      );
      const kyc = getInvestorInformationResponse.data.kyc || {
        signatureRight: [{}],
        ubo: [{}]
      };
      const clientName = getClientName(getInvestorInformationResponse.data);
      const { values } = clientForm.getState();
      clientForm.initialize({
        ...values,
        clientInformation,
        kyc
      });
      const namespaces = getSessionDataResponse.data.data.map(n => n.namespace);
      const advisorInputNamespace = getSessionDataResponse.data.data.find(
        n => n.namespace === SessionNamespaces.advisorInput
      ) ?? {
        namespace: SessionNamespaces.advisorInput,
        payload: {}
      };
      const sessionDataNamespace = getSessionDataResponse.data.data.find(
        n => n.namespace === SessionNamespaces.sessionData
      ) ?? {
        namespace: SessionNamespaces.sessionData,
        payload: {}
      };
      const finishedSessionDataNamespace =
        getSessionDataResponse.data.data.find(
          n => n.namespace === SessionNamespaces.finishedSessionData
        ) ?? {
          namespace: SessionNamespaces.finishedSessionData,
          payload: {}
        };

      proposalPageStore.setFinishedPortfolioData(
        finishedSessionDataNamespace.payload
      );
      roboAdviceForm.initialize({
        ...advisorInputNamespace.payload,
        clientInformation: {
          ...advisorInputNamespace.payload.clientInformation,
          ...clientInformation
        }
      });

      costForm.initialize({
        overrideCost: { ...sessionDataNamespace.payload.overrideCost },
        overrideFundCosts: {
          ...sessionDataNamespace.payload.overrideFundCosts
        },
        overrideFundOngoingFeesAdvisorNotes:
          sessionDataNamespace.payload.overrideFundOngoingFeesAdvisorNotes,
        overrideFundOneTimeFeesAdvisorNotes:
          sessionDataNamespace.payload.overrideFundOneTimeFeesAdvisorNotes,
        overrideSummaryAnnualOngoingFeesAdvisorNotes:
          sessionDataNamespace.payload
            .overrideSummaryAnnualOngoingFeesAdvisorNotes,
        overrideSummaryCustodyFeesAdvisorNotes:
          sessionDataNamespace.payload.overrideSummaryCustodyFeesAdvisorNotes,
        overrideSummaryOneTimeFeesAdvisorNotes:
          sessionDataNamespace.payload.overrideSummaryOneTimeFeesAdvisorNotes
      });
      sessionStoreState.initialize({
        isAdvisoryPageInitialized:
          sessionDataNamespace.payload.isAdvisoryPageInitialized ?? false,
        isBasicDataInitialized:
          sessionDataNamespace.payload.isBasicDataInitialized ?? false,
        clientName,
        clientAdviceSessions,
        namespaces,
        status: currentAdviceSession?.status,
        defaultConfigNamespaceId,
        categories: allCategories
      });
      advisoryPageStoreState.initialize({
        themes,
        portfolios,
        categoriesSelection
      });

      sessionStoreState.setIsSessionInitialized(true, adviceSessionId);

      dispatch(riskScoreCreators.fieldChanged('expectationOfRisk', updateGoal));

      sessionStoreState.setIsReadAdviceSessionPending(false);
    } catch (error) {
      if (state?.isCrmRedirect) {
        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.integrationPlatform.CRMRedirectFailedDefaultingToHomeWarning'
            ),
            type: NotificationTypes.warning
          })
        );

        history.push(routeTemplates.home.build());
        return;
      }

      if (!axios.isCancel(error)) {
        sessionStoreState.setIsReadAdviceSessionPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: i18n(
              'roboAdvice.adviceSession.readAdviceSessionErrorMessage'
            ),
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (!isNil(cancelTokenSourceRef.current)) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  const { defaultConfigNamespaceId } = useSessionStore.getState();
  useEffect(() => {
    let oldSuitabilityValues: Suitability | undefined;
    let oldGoalNamespaceIds: number[] = [];

    const fetchAdvancedSuitabilityStatusData = async () => {
      if (isNil(defaultConfigNamespaceId)) {
        return;
      }

      const { advancedSuitabilityStatus, advancedSuitabilityConfig } =
        useKnowledgeAndExperienceStore.getState();
      const { goals } = useGoalsStore.getState();
      const {
        values: { suitability: suitabilityValues }
      } = useRoboAdviceForm.getState();

      const notFetchedNamespaceIds = Array.from(
        new Set(
          [
            ...goals.map(({ data }) => data?.productPlatformNamespace),
            defaultConfigNamespaceId
          ].filter(
            (namespaceId: number | null | undefined): namespaceId is number =>
              !isNil(namespaceId)
          )
        )
      ).filter(namespaceId => !advancedSuitabilityStatus[namespaceId]);

      if (
        advancedSuitability.enabled &&
        advancedSuitabilityConfig &&
        notFetchedNamespaceIds.length > 0
      ) {
        const enabledAdvancedSuitabilityTables =
          advancedSuitabilityTables.filter(
            ({ name }) => advancedSuitability[name].enabled
          );
        const areAllFieldsFilled = advancedSuitabilityConfig?.checks.every(
          key => {
            return enabledAdvancedSuitabilityTables.every(
              ({ name }) =>
                suitabilityValues?.[name] &&
                !isNil(suitabilityValues[name][key])
            );
          }
        );

        if (areAllFieldsFilled) {
          const data = advancedSuitabilityConfig?.checks?.map(check => {
            const checkData = { check };
            for (const { name, input } of enabledAdvancedSuitabilityTables) {
              checkData[input] = suitabilityValues![name][check];
            }
            return checkData;
          });

          await Promise.all(
            notFetchedNamespaceIds.map(namespaceId =>
              readAdvancedSuitabilityStatus(data, namespaceId)
            )
          );

          // Check if selected themes are still available, if not -> unselect them
          const { advancedSuitabilityStatus: newAdvancedSuitabilityStatus } =
            useKnowledgeAndExperienceStore.getState();
          const advisoryPageStoreState = useAdvisoryPageStore.getState();

          goals.forEach(({ goalId, data }) => {
            const pageStoreThemes =
              advisoryPageStoreState.goalThemes &&
              advisoryPageStoreState.goalThemes[goalId]
                ? advisoryPageStoreState.goalThemes[goalId]
                : [];

            const disabledThemes = pageStoreThemes
              .filter(
                theme =>
                  !newAdvancedSuitabilityStatus[
                    data.productPlatformNamespace || defaultConfigNamespaceId
                  ]?.find(
                    ({ id }) =>
                      id ===
                      advisoryPageStoreState?.goalCategoriesSelection?.[
                        goalId
                      ]?.[theme.id]
                  )?.productIsSuitable
              )
              .map(theme => theme.id);

            const filteredThemes = (data.themes || []).filter(
              id => !disabledThemes.includes(id)
            );
            updateGoal(goalId, { data: { themes: filteredThemes } });
          });
        }
      }
    };

    const unsubscribeRoboAdviceForm = useRoboAdviceForm.subscribe(
      ({ values }) => {
        const newSuitabilityValues = values.suitability;

        // Detects changes to Advanced Suitability data
        if (!equals(oldSuitabilityValues, newSuitabilityValues)) {
          useKnowledgeAndExperienceStore
            .getState()
            .resetAdvancedSuitabilityStatusData();

          fetchAdvancedSuitabilityStatusData();

          oldSuitabilityValues = newSuitabilityValues;
        }
      }
    );

    const unsubscribeGoalsStore = useGoalsStore.subscribe(({ goals }) => {
      const newGoalNamespaceIds = goals
        .map(({ data }) => data?.productPlatformNamespace)
        .filter(
          (namespaceId: number | null | undefined): namespaceId is number =>
            !isNil(namespaceId)
        );

      // Detects changes to Goals data
      if (!equals(oldGoalNamespaceIds, newGoalNamespaceIds)) {
        fetchAdvancedSuitabilityStatusData();

        oldGoalNamespaceIds = newGoalNamespaceIds;
      }
    });

    return () => {
      unsubscribeRoboAdviceForm();
      unsubscribeGoalsStore();
    };
  }, [defaultConfigNamespaceId]);

  return readAdviceSession;
}

export const mapServerThemesToClient = (
  categoriesOptionals,
  roboPortfolioMeta
) => {
  const allowedThemeIds: any[] = pathOr(
    [],
    ['allowed options', 'optionals'],
    roboPortfolioMeta
  );

  return pipe(
    pathOr<any[]>([], ['Categories']),
    filter<any>(t => includes(t.CategoryId, allowedThemeIds)),
    map(t => ({
      id: t.CategoryId,
      title: t.SubAssetClass
    }))
  )(categoriesOptionals);
};

export const mapServerPortfoliosToClient = (
  i18n,
  roboPortfolioMeta,
  customRiskTitles?
) => {
  const allowedPortfolios: any[] = pathOr(
    [],
    ['allowed options', 'risk'],
    roboPortfolioMeta
  );

  return allowedPortfolios.map(p => {
    const riskScore = Number(p.replace('R', ''));
    let riskTitle: string = propOr(false, p)(customRiskTitles);
    if (!riskTitle) {
      riskTitle = i18n('roboAdvice.advisory.portfolio.portfolioTitle').replace(
        '{0}',
        riskScore
      );
    }
    return {
      id: p,
      riskScore,
      title: riskTitle
    };
  });
};

export const mapServerCategoriesSelectionToClient = roboSelection => {
  return pipe(
    propOr<any[]>([], 'Selection'),
    reduce<any, any>((acc, c) => {
      const instruments = c.Instruments;

      if (isNil(instruments) || isEmpty(instruments)) {
        return acc;
      }

      return assoc(c.CategoryId, instruments[0].Ticker, acc);
    }, {})
  )(roboSelection);
};

const mapServerClientAdviceSessionsToClient = (
  clientAdviceSessions: GetClientSessionsResponse
): AdviceSession[] => {
  const { sessions } = clientAdviceSessions;

  return sessions.map(s => ({
    id: s.session_id,
    name: s.name,
    status: s.status,
    adviceType: s.advice_type
  }));
};
